import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
} from "@mui/material";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";
import { useState } from "react";
import { toast } from "react-toastify";
import config from "../../utils/config";

function EgmImportDialog(props) {
	const [eventKey, setEventKey] = useState("");
	const [loading, setLoading] = useState(false);

	const close = () => {
		setEventKey("");
		props.onClose();
	}

	const importData = useDebouncedCallback(() => {
		if (eventKey === "") {
			toast.warning("Event Key cannot be empty.");
			return;
		}

		setLoading(true);

		axios
			.post(`${config.API_ENDPOINT}/events/${props.organization}/${props.event}/egm-import`, { egmToken: eventKey }, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(() => {
				setLoading(false);
				toast.success("Success!");
				close();
			})
			.catch(err => {
				setLoading(false);
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred. Please verify your event key.");
						break;
				}
			});
	}, 500, { leading: true });

	return (
		<Dialog
			open={props.open}
			onClose={close}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Import from ERS</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Import participants from ERS
				</DialogContentText>
				<TextField
					variant="outlined"
					required
					fullWidth
					label="Event Key"
					name="egmEventKey"
					type="password"
					autoComplete="off"
					value={eventKey}
					onChange={(e) => setEventKey(e.target.value)}
					style={{ marginBottom: 20 }}
				/>
			</DialogContent>
			{loading ? (
				<CircularProgress />
			) : (
				<DialogActions>
					<Button onClick={importData} color="primary" autoFocus>
						Import
					</Button>
				</DialogActions>
			)
			}
		</Dialog >
	);
}

export default EgmImportDialog;