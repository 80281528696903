import {
	Card,
	CardContent,
	Typography,
	Button,
	TextField,
} from "@mui/material";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import { toast } from "react-toastify";
import config from "../../utils/config";
import { stateToHTML } from "draft-js-export-html";
import { Editor } from "react-draft-wysiwyg";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import axios from "axios";
import { stateFromHTML } from "draft-js-import-html";
import { EditorState } from "draft-js";

function EmailSender() {
	const location = useLocation();

	const [emails, setEmails] = useState([]);
	const [subject, setSubject] = useState("Default Subject");
	const [editorState, setEditorState] = useState(EditorState.createWithContent(stateFromHTML("<p>Default Content</p>")));
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [confirmationOnYes, setConfirmationOnYes] = useState(null);
	const [confirmationOnClose, setConfirmationOnClose] = useState(null);

	useEffect(() => {
		if (location.state != null) {
			setEmails(location.state.emails);
		}
	}, [location.state]); // eslint-disable-line

	const listToString = (list) => {
		let str = "";
		for (let i = 0; i < list.length; i++) {
			if (i !== 0) {
				str += ", ";
			}

			str += list[i];
		}
		return str;
	}

	const send = () => {
		if (subject == null || subject === "") {
			toast.warning("Subject cannot be empty");
			return;
		}

		setConfirmationMessage(`Are you sure you want to send this email to ${emails.length} addresses?`);
		setConfirmationOnClose(() => () => {
			setConfirmationOpen(false);
		});
		setConfirmationOnYes(() => () => {
			setConfirmationOpen(false);
			sendEmail();
		});
		setConfirmationOpen(true);
	}

	const sendEmail = useDebouncedCallback(() => {
		axios
			.post(`${config.API_ENDPOINT}/email-templates/send-mail`, { to: emails, subject, content: stateToHTML(editorState.getCurrentContent()) }, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(() => {
				toast.success("Success!");
				setEditorState(null);
				setSubject("");
			})
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}, 1000, { trailing: true });

	return (
		<div>
			<Card style={{ padding: 15 }}>
				<CardContent>
					<Typography>
						<strong>Send email to: </strong>
						<i>{listToString(emails)}</i>
					</Typography>
					<TextField
						variant="outlined"
						required
						fullWidth
						autoFocus
						label="Subject"
						name="subject"
						type="text"
						autoComplete="off"
						value={subject}
						onChange={(e) => setSubject(e.target.value)}
						style={{ marginBottom: 10, marginTop: 20 }}
					/>
					<Card style={{ paddingBottom: 50 }}>
						<CardContent>
							<Editor
								editorState={editorState}
								label="Email content..."
								onEditorStateChange={s => setEditorState(s)}
							/>
						</CardContent>
					</Card>
					<Button
						variant="contained"
						color="primary"
						style={{ marginTop: 20, color: "white" }}
						onClick={send}
					>
						Send
					</Button>
				</CardContent>
			</Card>
			<ConfirmationDialog
				open={confirmationOpen}
				message={confirmationMessage}
				onYes={confirmationOnYes}
				onClose={confirmationOnClose}
			/>
		</div>
	);
}

export default EmailSender;