import {
	Card,
	CardContent,
	OutlinedInput,
	IconButton,
	InputAdornment,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Menu,
	MenuItem,
	TablePagination,
	TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import config from "../../utils/config";
import { toast } from "react-toastify";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Link, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";

const numRows = 10;

function EventPurchases(props) {
	const { eventId } = useParams();
	const [searchParams] = useSearchParams();
	const esnCard = searchParams.get("esnCard");

	const [eventPurchases, setEventPurchases] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [searchDate, setSearchDate] = useState(null);
	const [menuAnchorEl, setMenuAnchorEl] = useState(null);
	const [menuPurchase, setMenuPurchase] = useState(null);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [confirmationOnYes, setConfirmationOnYes] = useState(null);
	const [confirmationOnClose, setConfirmationOnClose] = useState(null);
	const [page, setPage] = useState(0);
	const [totalRows, setTotalRows] = useState(0);
	const [lastPage, setLastPage] = useState(0);

	useEffect(() => {
		props.setTitle("Event Purchases");
	}, []); // eslint-disable-line

	useEffect(() => {
		setPage(0);
		setLastPage(0);
		fetchEventPurchases(0);
	}, [props.organization, eventId]); // eslint-disable-line

	useEffect(() => {
		onSearchTextDateChanged();
	}, [searchText, searchDate]); // eslint-disable-line

	const onSearchTextDateChanged = useDebouncedCallback(() => {
		setPage(0);
		setLastPage(0);
		fetchEventPurchases(0);
	}, 500);

	const handlePageChange = (_, newPage) => {
		if (newPage > lastPage) {
			fetchEventPurchases(newPage);
			setLastPage(newPage);
		}

		setPage(newPage);
	};

	const fetchNumEventPurchases = () => {
		let q = `${config.API_ENDPOINT}/event-purchases/${props.organization}/count`;

		if (searchText !== "") {
			q += `?search=${searchText}`;
		}

		if (searchDate) {
			if (q.includes("?")) {
				q += "&";
			} else {
				q += "?";
			}

			q += `searchDate=${searchDate.startOf("day").valueOf()}`;
		}

		if (eventId != null) {
			if (q.includes("?")) {
				q += "&";
			} else {
				q += "?";
			}

			q += `event=${eventId}`;
		}

		if (esnCard != null) {
			if (q.includes("?")) {
				q += "&";
			} else {
				q += "?";
			}

			q += `esnCard=${esnCard}`;
		}

		axios
			.get(q, {
				headers: { "x-access-token": localStorage.getItem("access-token") },
			})
			.then((res) => setTotalRows(res.data.count))
			.catch((err) => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	};

	const fetchEventPurchases = useDebouncedCallback((page) => {
		fetchNumEventPurchases();

		let q = `${config.API_ENDPOINT}/event-purchases/${props.organization}/all?page=${page}&numRows=${numRows}`;

		if (searchText !== "") {
			q += `&search=${searchText}`;
		}

		if (searchDate) {
			q += `&searchDate=${searchDate.startOf("day").valueOf()}`;
		}

		if (eventId != null) {
			q += `&event=${eventId}`;
		}

		if (esnCard != null) {
			q += `&esnCard=${esnCard}`;
		}

		axios
			.get(q, {
				headers: { "x-access-token": localStorage.getItem("access-token") },
			})
			.then((res) => {
				if (page === 0) {
					setEventPurchases(res.data);
				} else {
					setEventPurchases([...eventPurchases, ...res.data]);
				}
			})
			.catch((err) => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}, 500);

	const deletePurchase = useDebouncedCallback(
		(purchaseId) => {
			axios
				.delete(
					`${config.API_ENDPOINT}/event-purchases/${props.organization}/${purchaseId}`,
					{
						headers: { "x-access-token": localStorage.getItem("access-token") },
					}
				)
				.then(() => {
					toast.success("Success!");
					setMenuAnchorEl(null);
					setPage(0);
					setLastPage(0);
					setConfirmationOpen(false);
					fetchEventPurchases(0);
				})
				.catch((err) => {
					switch (err.response?.status) {
						case 401:
							localStorage.removeItem("access-token");
							window.location.replace("/login");
							break;
						case 404:
							toast.warn(
								"You don't have enough permissions to delete this purchase"
							);
							setConfirmationOpen(false);
							setMenuAnchorEl(null);
							break;
						default:
							toast.error("An unexpected error has occurred");
							break;
					}
				});
		},
		500,
		{ leading: true }
	);

	const sendReminder = useDebouncedCallback(
		(purchaseId) => {
			axios
				.post(
					`${config.API_ENDPOINT}/event-purchases/${props.organization}/${purchaseId}/reminder`,
					{ endpoint: config.WEB_ENDPOINT },
					{
						headers: { "x-access-token": localStorage.getItem("access-token") },
					}
				)
				.then(() => toast.success("Success!"))
				.catch((err) => {
					switch (err.response?.status) {
						case 401:
							localStorage.removeItem("access-token");
							window.location.replace("/login");
							break;
						default:
							toast.error("An unexpected error has occurred");
							break;
					}
				});
		},
		500,
		{ leading: true }
	);

	const resendTicket = useDebouncedCallback(
		(purchaseId) => {
			axios
				.post(
					`${config.API_ENDPOINT}/event-purchases/${props.organization}/${purchaseId}/resend`,
					{ endpoint: config.WEB_ENDPOINT },
					{
						headers: { "x-access-token": localStorage.getItem("access-token") },
					}
				)
				.then(() => toast.success("Success!"))
				.catch((err) => {
					switch (err.response?.status) {
						case 404:
							toast.error("Cannot resend ticket. Transaction not found.");
							break;
						case 401:
							localStorage.removeItem("access-token");
							window.location.replace("/login");
							break;
						default:
							toast.error("An unexpected error has occurred");
							break;
					}
				});
		},
		500,
		{ leading: true }
	);

	const showDeleteDialog = (purchaseId) => {
		setConfirmationMessage("Are you sure you want to delete this purchase?");
		setConfirmationOnClose(() => () => {
			setConfirmationOpen(false);
			setMenuAnchorEl(null);
		});
		setConfirmationOnYes(() => () => {
			setConfirmationOpen(false);
			setMenuAnchorEl(null);
			deletePurchase(purchaseId);
		});
		setConfirmationOpen(true);
	};

	return (
		<div>
			<Card style={{ padding: 15 }}>
				<CardContent>
					<DatePicker
						label="Search by date"
						inputVariant="outlined"
						required
						inputFormat="DD/MM/YYYY"
						value={searchDate}
						onChange={(date) => setSearchDate(date)}
						renderInput={(params) => <TextField {...params} sx={{ mb: 3 }} />}
					/>
					{searchDate ? (
						<IconButton
							style={{ marginLeft: 10 }}
							onClick={() => setSearchDate(null)}
						>
							<ClearIcon />
						</IconButton>
					) : null}
					<OutlinedInput
						required
						fullWidth
						name="search"
						type="text"
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						placeholder="Search by Name/Passport/ESNcard"
						endAdornment={
							<InputAdornment position="end">
								{searchText === "" ? null : (
									<IconButton onClick={() => setSearchText("")}>
										<ClearIcon />
									</IconButton>
								)}
							</InputAdornment>
						}
						style={{ marginBottom: 50 }}
					/>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Event</TableCell>
									<TableCell>Date/Time</TableCell>
									<TableCell>Name</TableCell>
									<TableCell>Email</TableCell>
									<TableCell>VAT</TableCell>
									<TableCell>Phone Number</TableCell>
									<TableCell>Country</TableCell>
									<TableCell>ESNcard</TableCell>
									<TableCell>Paid?</TableCell>
									<TableCell>Checked-in?</TableCell>
									<TableCell>Settings</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{eventPurchases
									.slice(page * numRows, page * numRows + numRows)
									.map((purchase) => {
										let eventName = purchase.shared
											? purchase.event?.parentEvent.name
											: purchase.event?.name;
										return (
											<TableRow key={purchase._id}>
												<TableCell>{eventName ?? "(Event deleted)"}</TableCell>
												<TableCell>
													{moment(purchase.datetime).format("HH:mm DD/MM/YYYY")}
												</TableCell>
												<TableCell>{purchase.name}</TableCell>
												<TableCell>{purchase.email}</TableCell>
												<TableCell>{purchase.vat}</TableCell>
												<TableCell>{purchase.phone}</TableCell>
												<TableCell>{purchase.country}</TableCell>
												<TableCell>{purchase.esnCard}</TableCell>
												<TableCell>{purchase.paid ? "Yes" : "No"}</TableCell>
												<TableCell>
													{purchase.checkedInBy != null ? "Yes" : "No"}
												</TableCell>
												<TableCell>
													<IconButton
														onClick={(ev) => {
															setMenuPurchase(purchase);
															setMenuAnchorEl(ev.currentTarget);
														}}
													>
														<MoreVertIcon />
													</IconButton>
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
						<TablePagination
							rowsPerPageOptions={[numRows]}
							rowsPerPage={numRows}
							component="div"
							count={totalRows}
							page={page}
							onPageChange={handlePageChange}
						/>
					</TableContainer>
					<Menu
						anchorEl={menuAnchorEl}
						open={menuAnchorEl != null}
						keepMounted
						onClose={() => setMenuAnchorEl(null)}
					>
						<Link
							to={`/event-purchase-details/${menuPurchase?._id}`}
							style={{ color: "inherit", textDecoration: "inherit" }}
						>
							<MenuItem>View Details</MenuItem>
						</Link>
						{!menuPurchase?.paid ? (
							<MenuItem onClick={() => sendReminder(menuPurchase?._id)}>
								Send Reminder
							</MenuItem>
						) : null}
						{menuPurchase?.paid ? (
							<MenuItem onClick={() => resendTicket(menuPurchase?._id)}>
								Re-send Ticket
							</MenuItem>
						) : null}
						{props.user.accessLevel <= 1 &&
							menuPurchase?.organization === props.organization ? (
							<MenuItem onClick={() => showDeleteDialog(menuPurchase?._id)}>
								Delete
							</MenuItem>
						) : null}
					</Menu>
				</CardContent>
			</Card>
			<ConfirmationDialog
				open={confirmationOpen}
				message={confirmationMessage}
				onYes={confirmationOnYes}
				onClose={confirmationOnClose}
			/>
		</div>
	);
}

export default EventPurchases;
