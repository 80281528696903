import {
	Dialog,
	DialogTitle,
	TextField,
	DialogContent,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	DialogActions,
	Button,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import { toast } from "react-toastify";
import { Autocomplete, Chip } from "@mui/material";
import { useEffect, useState } from "react";

function AddOption(props) {
	const [name, setName] = useState("");
	const [type, setType] = useState("");
	const [values, setValues] = useState([]);
	const [required, setRequired] = useState(true);

	useEffect(() => {
		if (props.editOption != null) {
			setName(props.editOption.name);
			setType(props.editOption.type);
			setValues(props.editOption.values ?? []);
			setRequired(props.editOption.required);
		}
	}, [props.editOption]);

	const resetState = () => {
		setName("");
		setType("");
		setValues([]);
		setRequired(true);
	}

	const close = () => {
		resetState();
		props.onClose();
	}

	const confirm = () => {
		if (name === "" || type === "") {
			toast.warning("You must fill the required fields");
			return;
		}

		if (type === "select" && values.length === 0) {
			toast.warning("The 'Selection' type requires at least one value");
			return;
		}

		let option = {
			name: name,
			type: type,
			required: required
		};

		if (type === "select" || type === "select-multi") {
			option.values = values;
		}

		resetState();

		props.onConfirm(option);
	}

	return (
		<Dialog
			open={props.open}
			onClose={close}
			aria-labelledby="alert-dialog-title"
		>
			<DialogTitle id="alert-dialog-title">Add Option</DialogTitle>
			<DialogContent>
				<TextField
					variant="outlined"
					required
					fullWidth
					label="Name"
					name="name"
					type="text"
					autoComplete="off"
					value={name}
					onChange={(e) => setName(e.target.value)}
					sx={{ mb: 2, mt: 2 }}
				/>
				<FormControlLabel control={<Checkbox checked={required} onChange={e => setRequired(e.target.checked)} />} label="Required option" />
				<FormControl fullWidth sx={{ mb: 2 }} required>
					<InputLabel id="type-label">Type</InputLabel>
					<Select
						labelId="type-label"
						label="Type"
						value={type}
						onChange={(e) => {
							setType(e.target.value);
							setValues([]);
						}}
					>
						<MenuItem value="select">Selection</MenuItem>
						<MenuItem value="select-multi">Multiple Choice</MenuItem>
						<MenuItem value="text">Text</MenuItem>
						<MenuItem value="datetime">Date & Time</MenuItem>
					</Select>
				</FormControl>
				{type === "select" || type === "select-multi" ? (
					<Autocomplete
						multiple
						options={[]}
						freeSolo
						onChange={(_, values) => setValues(values)}
						value={values}
						renderTags={(value, getTagProps) =>
							value.map((option, index) => (
								<Chip
									variant="outlined"
									label={option}
									{...getTagProps({ index })}
								/>
							))
						}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="filled"
								label="Selection options"
							/>
						)}
					/>
				) : null}
			</DialogContent>
			<DialogActions>
				<Button onClick={close} color="primary">
					Cancel
				</Button>
				<Button onClick={confirm} color="primary" autoFocus>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default AddOption;
