import {
	Container,
	Avatar,
	Typography,
	TextField,
	Button,
	Card,
	Checkbox,
	FormControlLabel,
	Grid,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { toast } from "react-toastify";
import config from "../../utils/config";
import logo from "../../img/logo_evensimpler.png";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import axios from "axios";
import { validateEmail } from "../../utils/email-validator";

function Login(props) {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [remember, setRemember] = useState(false);

	useEffect(() => {
		if (localStorage.getItem("access-token")) {
			window.location.replace("/");
		}
	}, []); // eslint-disable-line

	const submitLogin = useDebouncedCallback(() => {
		if (email === "" || password === "") {
			toast.warning("You must fill both email and password");
			return;
		}

		if (!validateEmail(email)) {
			toast.warning("Invalid email");
			return;
		}

		axios
			.post(`${config.API_ENDPOINT}/auth/login`, { email, password, remember })
			.then(res => {
				localStorage.setItem("access-token", res.data.token);
				window.location.replace("/");
			})
			.catch(err => {
				switch (err.response?.status) {
					case 400:
					case 401:
						toast.warning("Invalid email or password");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}, 500, { leading: true });

	return (
		<Container component="main" maxWidth="sm">
			<Card
				style={{
					marginTop: 64,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					padding: 64,
				}}
				elevation={8}
			>
				<Avatar sx={{ m: 1 }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Login
				</Typography>
				<div style={{ width: "100%", marginTop: 8 }}>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email"
						name="email"
						autoComplete="email"
						autoFocus
						type="email"
						value={email}
						onChange={e => setEmail(e.target.value)}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						value={password}
						onChange={e => setPassword(e.target.value)}
					/>
					<FormControlLabel
						label="Remember me"
						control={
							<Checkbox
								checked={remember}
								onChange={(e) => setRemember(e.target.checked)}
							/>
						}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						style={{ margin: "24px 0px 16px", color: "#fff" }}
						onClick={submitLogin}
					>
						Login
					</Button>

					<Typography style={{ fontSize: 10, marginTop: 40 }} align="center">
						Powered by
					</Typography>
					<Grid
						container
						spacing={0}
						direction="column"
						alignItems="center"
						justify="center"
					>
						<Grid item xs={3}>
							<a href="https://evensimpler.pt/">
								<img alt="evensimpler" height="50px" src={logo} />
							</a>
						</Grid>
					</Grid>
				</div>
			</Card>
		</Container>
	);
}

export default Login;