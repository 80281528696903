import { Box, Divider, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import parse from "html-react-parser";

function Notification({ notification, onClick }) {
    return (
        <Box sx={{ width: 250, backgroundColor: notification.read ? "#fafafa" : "#ffd7b6", ":hover": { opacity: 0.7 }, transitionDuration: "0.3s", cursor: "pointer" }} onClick={onClick}>
            <Box sx={{ p: 2 }}>
                <Typography sx={{ fontSize: 12, textAlign: "right" }} color="text.secondary">
                    {moment(notification.datetime).format("DD/MM/YYYY HH:mm")}
                </Typography>
                <Typography sx={{ mt: 1, fontWeight: "bold" }}>
                    {notification.title}
                </Typography>
                <Typography className='ellipsis-text' sx={{ mt: 1, fontWeight: "light", maxHeight: 60 }}>
                    {parse(notification.message, { htmlparser2: { decodeEntities: false } })}
                </Typography>
            </Box>

            <Divider sx={{ mt: 2 }} />
        </Box>
    )
}

export default Notification