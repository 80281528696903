import {
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip,
} from "@mui/material";
import config from "../../utils/config";
import { toast } from "react-toastify";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import parse from "html-react-parser";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddOption from "./AddOption";
import ClearIcon from "@mui/icons-material/Clear";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import moment from "moment";
import { useState, useEffect } from "react";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate, useParams } from "react-router-dom";

const stateToHtmlOptions = {
  entityStyleFn: (entity) => {
    if (entity.get("type").toLowerCase() === "link") {
      const data = entity.getData();
      return {
        element: "a",
        attributes: {
          href: data.url,
          target: "_blank",
        },
      };
    }
  },
};

function AddEvent(props) {
  const navigate = useNavigate();

  const { editEventId, cloneEventId } = useParams();

  const [organizationList, setOrganizationList] = useState([]);
  const [name, setName] = useState("");
  const [datetime, setDatetime] = useState(null);
  const [datetimeEnd, setDatetimeEnd] = useState(null);
  const [datetimeLimit, setDatetimeLimit] = useState(null);
  const [briefDescription, setBriefDescription] = useState("");
  const [location, setLocation] = useState("");
  const [regularPrice, setRegularPrice] = useState(0.0);
  const [esnCardPrice, setEsnCardPrice] = useState(null);
  const [esnCardRequired, setEsnCardRequired] = useState(false);
  const [esnCardBuyPrice, setEsnCardBuyPrice] = useState(null);
  const [file, setFile] = useState(null);
  const [initialPicture, setInitialPicture] = useState(null);
  const [imageInputValue, setImageInputValue] = useState("");
  const [seats, setSeats] = useState(null);
  const [sharedSeats, setSharedSeats] = useState({});
  const [useRegularSeats, setUseRegularSeats] = useState(false);
  const [options, setOptions] = useState([]);
  const [externalPayLink, setExternalPayLink] = useState("");
  const [externalPayLabel, setExternalPayLabel] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorState2, setEditorState2] = useState(EditorState.createEmpty());
  const [editorState3, setEditorState3] = useState(EditorState.createEmpty());
  const [addingOption, setAddingOption] = useState(false);
  const [editOptionIndex, setEditOptionIndex] = useState(null);
  const [active, setActive] = useState(true);
  const [shared, setShared] = useState(false);
  const [hostInvoicing, setHostInvoicing] = useState(false);
  const [wishlistEnabled, setWishlistEnabled] = useState(false);

  useEffect(() => {
    if (editEventId) {
      props.setTitle("Edit Event");
    } else if (cloneEventId) {
      props.setTitle("Clone Event");
    } else {
      props.setTitle("Create Event");
    }
  }, [editEventId, cloneEventId]); //eslint-disable-line

  useEffect(() => {
    getOrganizationList();
    if (editEventId || cloneEventId) {
      fetchEventInfo();
    } else {
      resetState();
    }
  }, [props.organization, editEventId, cloneEventId]); //eslint-disable-line

  const resetState = () => {
    setName("");
    setDatetime(null);
    setDatetimeEnd(null);
    setDatetimeLimit(null);
    setBriefDescription("");
    setLocation("");
    setRegularPrice(0.0);
    setEsnCardPrice(null);
    setEsnCardRequired(false);
    setEsnCardBuyPrice(null);
    setFile(null);
    setInitialPicture(null);
    setSeats(null);
    setSharedSeats({});
    setUseRegularSeats(false);
    setOptions([]);
    setExternalPayLink("");
    setExternalPayLabel("");
    setEditorState(EditorState.createEmpty());
    setEditorState2(EditorState.createEmpty());
    setEditorState3(EditorState.createEmpty());
    setAddingOption(false);
    setEditOptionIndex(null);
    setActive(true);
    setShared(false);
    setHostInvoicing(false);
    setWishlistEnabled(false);
  };

  const setStateFromEvent = (event) => {
    setName(event.name);
    setDatetime(moment(event.datetime));
    setDatetimeEnd(event.datetimeEnd ? moment(event.datetimeEnd) : null);
    setDatetimeLimit(event.datetimeLimit ? moment(event.datetimeLimit) : null);
    setBriefDescription(event.briefDescription);
    setLocation(event.location);
    setRegularPrice(event.regularPrice);
    setEsnCardPrice(event.esnCardPrice);
    setEsnCardRequired(event.esnCardRequired);
    setEsnCardBuyPrice(event.esnCardBuyPrice);
    setInitialPicture(
      event.imageURL && editEventId ? `${config.IMG_ENDPOINT}/uploads/${event.imageURL}` : null
    );
    setSeats(event.seats);
    setSharedSeats(event.sharedSeats ?? {});
    setUseRegularSeats(event.useRegularSeats ?? false);
    setOptions(event.options ?? []);
    setEditorState(
      event.description !== ""
        ? EditorState.createWithContent(stateFromHTML(event.description))
        : EditorState.createEmpty()
    );
    setEditorState2(
      event.customEmailInfo !== ""
        ? EditorState.createWithContent(stateFromHTML(event.customEmailInfo))
        : EditorState.createEmpty()
    );
    setEditorState3(
      event.customPurchaseEmailInfo !== ""
        ? EditorState.createWithContent(stateFromHTML(event.customPurchaseEmailInfo))
        : EditorState.createEmpty()
    );
    setActive(event.active);
    setShared(event.shared);
    setHostInvoicing(event.hostInvoicing);
    setWishlistEnabled(event.wishlistEnabled);
    setExternalPayLink(event.externalPayLink ?? "");
    setExternalPayLabel(event.externalPayLabel ?? "");
  };

  const handleClearPhoto = () => {
    setFile(null);
    setInitialPicture(null);
    setImageInputValue("");
  };

  const getOrganizationList = () => {
    axios
      .get(`${config.API_ENDPOINT}/organizations/all`, {
        headers: { "x-access-token": localStorage.getItem("access-token") },
      })
      .then((res) => {
        let list = [];
        for (let org of res.data) {
          list.push({
            name: org.name,
            id: org._id,
          });
        }

        setOrganizationList(list);
      })
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  };

  const fetchEventInfo = () => {
    var id = editEventId ?? cloneEventId;

    axios
      .get(`${config.API_ENDPOINT}/events/${props.organization}/${id}`)
      .then((res) => setStateFromEvent(res.data))
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  };

  const submit = useDebouncedCallback(
    () => {
      if (name === "" || datetime == null || location === "") {
        toast.warning("You must fill the required fields.");
        return;
      }

      if (
        (seats != null && seats < 0) ||
        (regularPrice != null && regularPrice < 0) ||
        (esnCardPrice != null && esnCardPrice < 0) ||
        (esnCardBuyPrice != null && esnCardBuyPrice < 0) ||
        (sharedSeats != null && Object.values(sharedSeats).some((val) => val < 0))
      ) {
        toast.warning("No value can be less than 0.");
        return;
      }

      const data = new FormData();
      data.append("name", name);
      data.append("datetime", datetime.valueOf());
      data.append("briefDescription", briefDescription);
      data.append("description", stateToHTML(editorState.getCurrentContent(), stateToHtmlOptions));
      data.append("location", location);
      data.append("regularPrice", regularPrice ?? 0.0);
      data.append("esnCardRequired", esnCardRequired);
      data.append("organization", props.organization);
      data.append("createdBy", props.user.name);
      data.append("options", JSON.stringify(options));
      data.append(
        "customEmailInfo",
        stateToHTML(editorState2.getCurrentContent(), stateToHtmlOptions)
      );
      data.append(
        "customPurchaseEmailInfo",
        stateToHTML(editorState3.getCurrentContent(), stateToHtmlOptions)
      );
      data.append("sharedSeats", JSON.stringify(sharedSeats));
      data.append("useRegularSeats", useRegularSeats);
      data.append("active", active);
      data.append("shared", shared);

      if (shared) {
        data.append("hostInvoicing", hostInvoicing);
      }

      data.append("wishlistEnabled", wishlistEnabled);

      if (esnCardPrice != null || editEventId != null) {
        data.append("esnCardPrice", esnCardPrice);
      }

      if (datetimeEnd != null || editEventId != null) {
        data.append("datetimeEnd", datetimeEnd != null ? datetimeEnd.valueOf() : null);
      }

      if (seats != null || editEventId != null) {
        data.append("seats", seats);
      }

      if (datetimeLimit != null || editEventId != null) {
        data.append("datetimeLimit", datetimeLimit != null ? datetimeLimit.valueOf() : null);
      }

      if (esnCardBuyPrice != null || editEventId != null) {
        data.append("esnCardBuyPrice", esnCardBuyPrice);
      }

      if (externalPayLink !== "") {
        data.append("externalPayLink", externalPayLink);
      } else if (editEventId !== null) {
        data.append("externalPayLink", null);
      }

      if (externalPayLabel !== "") {
        data.append("externalPayLabel", externalPayLabel);
      } else if (editEventId !== null) {
        data.append("externalPayLabel", null);
      }

      if (file != null) {
        data.append("image", file);
      }

      let q = `${config.API_ENDPOINT}/events/create`;
      if (editEventId != null) {
        q = `${config.API_ENDPOINT}/events/${props.organization}/${editEventId}`;

        if (initialPicture == null && file == null) {
          data.append("deleteImage", true);
        }
      }

      axios
        .post(q, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-access-token": localStorage.getItem("access-token"),
          },
        })
        .then((res) => {
          let created = res.data;

          toast.success("Success!");

          if (editEventId == null) {
            navigate(`/edit-event/${created._id}`);
          }
        })
        .catch((err) => {
          switch (err.response?.status) {
            case 401:
              localStorage.removeItem("access-token");
              window.location.replace("/login");
              break;
            case 413:
              toast.warning("The image you sent is too big.");
              break;
            default:
              toast.error("An unexpected error has occurred");
              break;
          }
        });
    },
    500,
    { leading: true }
  );

  const copyIframe = async () => {
    let text = `<iframe frameborder="0" scrolling="yes" src="${config.WEB_ENDPOINT}/iframes/event-details/${props.organization}/${editEventId}" style=" min-height: 700px; overflow-y: scroll; width: 100%; "></iframe>`;
    await navigator.clipboard.writeText(text);
    toast.success("Copied!");
  };

  const getSeatsInputUI = () => {
    if (shared && !useRegularSeats) {
      return (
        <div>
          {organizationList.map((org) => (
            <Grid container alignItems="center" sx={{ mb: 2 }} key={org.id}>
              <Grid item xs={6} style={{ textAlign: "center" }} key={org.name}>
                <Typography>{org.name}</Typography>
              </Grid>
              <Grid item xs={6} key={org.id}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Seats"
                  name="seats"
                  type="number"
                  value={sharedSeats[org.id] ?? ""}
                  onChange={(e) => {
                    let ns = { ...sharedSeats };
                    ns[org.id] = e.target.value !== "" ? parseInt(e.target.value) : null;

                    setSharedSeats(ns);
                  }}
                  sx={{ mb: 1 }}
                />
              </Grid>
            </Grid>
          ))}
        </div>
      );
    } else {
      return (
        <TextField
          variant="outlined"
          fullWidth
          label="Seats"
          name="seats"
          type="number"
          value={seats ?? ""}
          onChange={(e) => setSeats(e.target.value !== "" ? parseInt(e.target.value) : null)}
          sx={{ mb: 2 }}
        />
      );
    }
  };

  const previewUI = () => {
    return (
      <Grid item xs={12} md={6}>
        {editEventId ? (
          <div>
            <Button onClick={copyIframe} style={{ marginBottom: 5, float: "right" }}>
              Copy iframe
            </Button>
            <Button
              onClick={() => {
                window.open(
                  `${config.WEB_ENDPOINT}/iframes/event-details/${props.organization}/${editEventId}/internal`,
                  "_blank"
                );
              }}
              style={{ marginBottom: 20, float: "right" }}
            >
              Open Internal Sale
            </Button>
          </div>
        ) : null}
        <Typography>
          <strong>Preview</strong>
        </Typography>
        {file || initialPicture ? (
          <img
            alt=""
            height={150}
            width={"100%"}
            style={{ objectFit: "cover" }}
            src={file ? URL.createObjectURL(file) : initialPicture}
          />
        ) : null}
        <Typography style={{ marginTop: 40, textAlign: "center", fontSize: 35 }}>{name}</Typography>
        <Typography style={{ marginTop: 30 }}>
          <strong>{datetimeEnd ? "From: " : "Date: "}</strong>
          {datetime ? datetime.format("dddd, DD/MM/YYYY HH:mm") : ""}
        </Typography>
        {datetimeEnd ? (
          <Typography style={{ marginTop: 20 }}>
            <strong>To: </strong>
            {datetimeEnd.format("dddd, DD/MM/YYYY HH:mm")}
          </Typography>
        ) : null}
        <Typography style={{ marginTop: 20 }}>
          <strong>Location: </strong>
          {location}
        </Typography>
        {parse(stateToHTML(editorState.getCurrentContent(), stateToHtmlOptions))}
        <Typography style={{ marginTop: 20, textAlign: "end", fontSize: 30 }}>
          {(regularPrice ?? 0.0).toFixed(2) + "€"}
        </Typography>
        <br />
        <Typography style={{ marginTop: 0, textAlign: "end" }}>
          {!esnCardRequired && esnCardPrice != null
            ? esnCardPrice.toFixed(2) + "€ with ESNcard"
            : "Only for students with ESNcard"}
        </Typography>
        <Card sx={{ mb: 1 }}>
          <CardContent>
            <Typography>
              <strong>Custom Ticket Email Section:</strong>
            </Typography>
            {parse(stateToHTML(editorState2.getCurrentContent(), stateToHtmlOptions))}
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography>
              <strong>Custom Purchase Request Email Section:</strong>
            </Typography>
            {parse(stateToHTML(editorState3.getCurrentContent(), stateToHtmlOptions))}
          </CardContent>
        </Card>
        <FormControlLabel
          style={{ marginTop: 20 }}
          value="start"
          control={
            <Checkbox
              color="primary"
              checked={active}
              onChange={(e) => {
                setActive(e.target.checked);
              }}
            />
          }
          label="Active?"
          labelPlacement="start"
        />
        <br />
        <FormControlLabel
          style={{ marginTop: 20 }}
          value="start"
          control={
            <Checkbox
              color="primary"
              checked={shared}
              onChange={(e) => setShared(e.target.checked)}
            />
          }
          label="Shared Event?"
          labelPlacement="start"
        />
        <br />
        {shared ? (
          <>
            <Tooltip title="If enabled, invoicing will be emitted from the host organization, rather than the individual joiners.">
              <FormControlLabel
                style={{ marginTop: 20 }}
                value="start"
                control={
                  <Checkbox
                    color="primary"
                    checked={hostInvoicing}
                    onChange={(e) => setHostInvoicing(e.target.checked)}
                  />
                }
                label="Host Invoicing?"
                labelPlacement="start"
              />
            </Tooltip>
            <br />
          </>
        ) : null}
        <FormControlLabel
          style={{ marginTop: 20 }}
          value="start"
          control={
            <Checkbox
              color="primary"
              checked={wishlistEnabled}
              onChange={(e) => setWishlistEnabled(e.target.checked)}
            />
          }
          label="Wishlist Enabled?"
          labelPlacement="start"
        />
        <br />
        <Button
          style={{ marginTop: 50, color: "white" }}
          variant="contained"
          color="primary"
          onClick={submit}
        >
          {editEventId ? "UPDATE" : "CREATE EVENT"}
        </Button>
      </Grid>
    );
  };

  const addFormUI = () => {
    return (
      <Grid item xs={12} md={6}>
        <TextField
          variant="outlined"
          required
          fullWidth
          autoFocus
          label="Name"
          name="name"
          type="text"
          autoComplete="off"
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          variant="outlined"
          fullWidth
          label="Brief Description"
          name="description"
          type="text"
          autoComplete="off"
          value={briefDescription}
          onChange={(e) => setBriefDescription(e.target.value)}
          sx={{ mb: 2 }}
        />
        <input
          accept="image/jpg, image/jpeg"
          id="icon-button-file"
          type="file"
          hidden
          value={imageInputValue}
          onChange={(e) => {
            setFile(e.target.files[0]);
            setImageInputValue(e.target.value);
          }}
        />
        <label htmlFor="icon-button-file">
          Event Picture
          <IconButton color="primary" aria-label="upload picture" component="span">
            <PhotoCamera />
          </IconButton>
        </label>

        {file || initialPicture ? (
          <IconButton onClick={handleClearPhoto}>
            <ClearIcon />
          </IconButton>
        ) : null}
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Editor
              editorStyle={{ minHeight: 200 }}
              editorState={editorState}
              label="Description..."
              onEditorStateChange={(s) => setEditorState(s)}
            />
          </CardContent>
        </Card>
        <DateTimePicker
          label="Date and Time of the Event *"
          inputVariant="outlined"
          required
          inputFormat="DD/MM/YYYY HH:mm"
          value={datetime}
          ampm={false}
          onChange={(date) => setDatetime(date)}
          renderInput={(params) => <TextField {...params} sx={{ mb: 2 }} fullWidth />}
        />
        <DateTimePicker
          label="End Date and Time of the Event"
          inputVariant="outlined"
          fullWidth
          inputFormat="DD/MM/YYYY HH:mm"
          value={datetimeEnd}
          ampm={false}
          onChange={(date) => setDatetimeEnd(date)}
          renderInput={(params) => <TextField {...params} sx={{ mb: 2 }} fullWidth />}
        />
        <DateTimePicker
          label="Limit Date and Time for Purchase"
          inputVariant="outlined"
          fullWidth
          inputFormat="DD/MM/YYYY HH:mm"
          value={datetimeLimit}
          ampm={false}
          onChange={(date) => setDatetimeLimit(date)}
          renderInput={(params) => <TextField {...params} sx={{ mb: 2 }} fullWidth />}
        />
        <TextField
          variant="outlined"
          fullWidth
          multiline
          label="Location"
          name="location"
          type="text"
          autoComplete="off"
          value={location}
          required
          onChange={(e) => setLocation(e.target.value)}
          sx={{ mb: 2 }}
        />
        {getSeatsInputUI()}
        {shared && (
          <FormControlLabel
            sx={{ mb: 2 }}
            value="start"
            control={
              <Checkbox
                color="primary"
                checked={useRegularSeats}
                onChange={(e) => {
                  setSharedSeats({});
                  setUseRegularSeats(e.target.checked);
                }}
              />
            }
            label="Use total seats"
            labelPlacement="start"
          />
        )}
        <TextField
          variant="outlined"
          fullWidth
          label={!esnCardRequired ? "Regular Price" : "Price"}
          name="regularPrice"
          type="number"
          value={regularPrice ?? ""}
          required
          onChange={(e) =>
            setRegularPrice(e.target.value !== "" ? parseFloat(e.target.value) : null)
          }
          inputProps={{ step: "0.01" }}
          sx={esnCardRequired ? { mb: 2 } : {}}
        />
        {!esnCardRequired ? (
          <Typography sx={{ mb: 2, fontSize: 10 }}>
            <strong>The regular price must include VAT</strong>
          </Typography>
        ) : null}
        {!esnCardRequired ? (
          <TextField
            variant="outlined"
            fullWidth
            label="Price with ESNcard"
            name="esnCardPrice"
            type="number"
            value={esnCardPrice ?? ""}
            required
            onChange={(e) =>
              setEsnCardPrice(e.target.value !== "" ? parseFloat(e.target.value) : null)
            }
            inputProps={{ step: "0.01" }}
            sx={{ mb: 2 }}
          />
        ) : null}
        <TextField
          variant="outlined"
          fullWidth
          label="Special Price (24h)"
          name="discount"
          type="number"
          value={esnCardBuyPrice ?? ""}
          onChange={(e) =>
            setEsnCardBuyPrice(e.target.value !== "" ? parseFloat(e.target.value) : null)
          }
          inputProps={{ step: "0.01" }}
          sx={{ mb: 2 }}
        />
        <FormControlLabel
          sx={{ mb: 2 }}
          value="start"
          control={
            <Checkbox
              color="primary"
              checked={esnCardRequired}
              onChange={(e) => {
                setEsnCardPrice(null);
                setEsnCardRequired(e.target.checked);
              }}
            />
          }
          label="ESNcard required?"
          labelPlacement="start"
        />
        <br />
        <Typography style={{ display: "inline", marginRight: 5 }}>Options</Typography>
        <IconButton onClick={() => setAddingOption(true)}>
          <AddIcon />
        </IconButton>
        <br />
        {options.map((option, index) => (
          <div key={index}>
            <Typography style={{ display: "inline", marginRight: 5 }}>{option.name}</Typography>
            <IconButton onClick={() => setEditOptionIndex(index)}>
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                let opts = [...options];
                opts.splice(index, 1);
                setOptions(opts);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ))}

        <TextField
          variant="outlined"
          fullWidth
          multiline
          label="External Payment Link"
          name="externalPayLink"
          type="text"
          autoComplete="off"
          value={externalPayLink}
          onChange={(e) => setExternalPayLink(e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          variant="outlined"
          fullWidth
          multiline
          label="External Payment Label (e.g Pay with Verse)"
          name="externalPayLabel"
          type="text"
          autoComplete="off"
          value={externalPayLabel}
          onChange={(e) => setExternalPayLabel(e.target.value)}
          sx={{ mt: 2 }}
        />

        <Typography color="gray" sx={{ mt: 2 }}>
          Custom Ticket Email Section
        </Typography>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Editor
              editorStyle={{ minHeight: 200 }}
              editorState={editorState2}
              onEditorStateChange={(s) => setEditorState2(s)}
            />
          </CardContent>
        </Card>
        <Typography color="gray">Custom Purchase Request Email Section</Typography>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Editor
              editorStyle={{ minHeight: 200 }}
              editorState={editorState3}
              onEditorStateChange={(s) => setEditorState3(s)}
            />
          </CardContent>
        </Card>
      </Grid>
    );
  };

  if (props.user.accessLevel > 3) return null;

  return (
    <div>
      <Card style={{ padding: 15 }}>
        <CardContent>
          <Grid container spacing={10}>
            {addFormUI()}
            {previewUI()}
          </Grid>
        </CardContent>
      </Card>

      <AddOption
        open={addingOption}
        onClose={() => setAddingOption(false)}
        onConfirm={(val) => {
          setOptions([...options, val]);
          setAddingOption(false);
        }}
      />

      <AddOption
        open={editOptionIndex != null}
        onClose={() => setEditOptionIndex(null)}
        editOption={editOptionIndex != null ? options[editOptionIndex] : null}
        onConfirm={(val) => {
          let newOptions = [...options];
          newOptions[editOptionIndex] = val;
          setOptions(newOptions);
          setEditOptionIndex(null);
        }}
      />
    </div>
  );
}

export default AddEvent;
