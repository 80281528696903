import { CircularProgress, Grid, Typography } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import config from "../../utils/config";

function PayPurchase(props) {
  const { organizationId, purchaseId } = useParams();

  useEffect(() => {
    pay();
  }, []); // eslint-disable-line

  const pay = () => {
    if (props.cancel) return;

    axios
      .post(`${config.API_ENDPOINT}/checkout/purchase`, {
        organization: organizationId,
        cancel: `${config.WEB_ENDPOINT}/purchase/${organizationId}/${purchaseId}/cancel`,
        success: `${config.WEB_ENDPOINT}/iframes/purchase/event/${organizationId}`,
        metadata: {
          type: "purchase",
          data: {
            id: purchaseId,
            organization: organizationId,
          },
        },
      })
      .then(async (res) => {
        const result = res.data;

        switch (result.type) {
          case "stripe":
            const stripe = await loadStripe(result.publicKey);

            const redirect = await stripe.redirectToCheckout({
              sessionId: result.session,
            });

            if (redirect.error) {
              toast.error("Error opening the checkout");
              console.error(redirect.error);
            }
            break;
          case "redsys":
            // Create a hidden form element programatically and submit it
            const form = document.createElement("form");
            form.method = "POST";
            form.action = result.url;
            form.style = "display: none";

            for (let key in result.body) {
              const input = document.createElement("input");
              input.type = "hidden";
              input.name = key;
              input.value = result.body[key];
              form.appendChild(input);
            }

            document.body.appendChild(form);
            form.submit();
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      })
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            toast.warning("This organization does not support online payments");
            break;
          case 409:
            toast.warning("You've already bought this event");
            break;
          case 403:
            toast.warning("This event has sold out!");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        {props.cancel ? null : <CircularProgress />}
      </Grid>
      <Grid item xs={3}>
        <Typography style={{ textAlign: "center" }}>
          {props.cancel ? "Payment Cancelled" : "Generating payment session..."}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PayPurchase;
