import {
  Drawer,
  List,
  ListItem,
  Avatar,
  Typography,
  Divider,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  AppBar,
  Toolbar,
  Container,
  IconButton,
  Box,
  ListItemButton,
} from "@mui/material";
import { toast } from "react-toastify";
import config from "../../utils/config";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddIcon from "@mui/icons-material/Add";
import FlagIcon from "@mui/icons-material/Flag";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { Route, Link, Routes } from "react-router-dom";
import AddUser from "../AddUser/AddUser";
import Users from "../Users/Users";
import Events from "../Events/Events";
import AddEvent from "../AddEvent/AddEvent";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import AddStudent from "../AddStudent/AddStudent";
import SchoolIcon from "@mui/icons-material/School";
import SettingsIcon from "@mui/icons-material/Settings";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import Students from "../Students/Students";
import StudentRequests from "../StudentRequests/StudentRequests";
import OrgSettings from "../OrgSettings/OrgSettings";
import EmailSettings from "../EmailSettings/EmailSettings";
import Transactions from "../Transactions/Transactions";
import TransactionDetails from "../Transactions/TransactionDetails";
import EmailSender from "../EmailSender/EmailSender";
import EventPurchases from "../EventPurchases/EventPurchases";
import EventPurchaseDetails from "../EventPurchases/EventPurchaseDetails";
import Dashboard from "../Dashboard/Dashboard";
import logo from "../../img/logo_evensimpler.png";
import AddSharedEvent from "../AddSharedEvent/AddSharedEvent";
import SharedEvents from "../SharedEvents/SharedEvents";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useState } from "react";
import axios from "axios";
import Finance from "../Finance/Finance";
import NotificationIcon from "../Notifications/NotificationIcon";
import CheckIn from "../CheckIn/CheckIn";
import StudentProfile from "../StudentProfile/StudentProfile";
import HelpIcon from "../Help/HelpIcon";
import Wishlist from "../Wishlist/Wishlist";

function Main() {
  const [user, setUser] = useState({});
  const [organization, setOrganization] = useState("");
  const [organizationList, setOrganizationList] = useState([]);
  const [title, setTitle] = useState("EventUpp - Portal");
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("access-token")) {
      window.location.replace("/login");
    }

    fetchUser();
  }, []); // eslint-disable-line

  useEffect(() => {
    fetchOrganizationList();
  }, [user]); // eslint-disable-line

  const fetchOrganizationList = () => {
    axios
      .get(`${config.API_ENDPOINT}/organizations/mine`, {
        headers: { "x-access-token": localStorage.getItem("access-token") },
      })
      .then((res) => {
        if (res.data.length === 0) {
          toast.error("Your account does not have access to any organization");
          return;
        }

        setOrganizationList(res.data);
        let lastOrg = localStorage.getItem("last-org");

        let found = false;
        for (let org of res.data) {
          if (org._id === lastOrg) {
            setOrganization(lastOrg);
            found = true;
            break;
          }
        }

        if (!found) setOrganization(res.data[0]._id);
      })
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  };

  const updateTitle = (title) => {
    setTitle(`EventUpp - ${title}`);
  };

  const fetchUser = () => {
    axios
      .get(`${config.API_ENDPOINT}/users/me`, {
        headers: { "x-access-token": localStorage.getItem("access-token") },
      })
      .then((res) => setUser(res.data))
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  };

  const logout = () => {
    localStorage.removeItem("access-token");
    window.location.replace("/login");
  };

  const organizationDropdownUI = () => {
    return (
      <FormControl fullWidth style={{ marginTop: 20 }}>
        <InputLabel id="org-label">Organization</InputLabel>
        <Select
          labelId="org-label"
          label="Organization"
          value={organization}
          onChange={(e) => {
            setOrganization(e.target.value);
            localStorage.setItem("last-org", e.target.value);
          }}
        >
          {organizationList.map((val) => (
            <MenuItem key={val._id} value={val._id}>
              {val.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const drawerUI = () => {
    return (
      <Drawer
        open={drawerOpen}
        anchor="left"
        sx={{
          width: 250,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 250,
            boxSizing: "border-box",
          },
        }}
        onClose={() => setDrawerOpen(false)}
      >
        <PerfectScrollbar>
          <div style={{ padding: 10 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              <span style={{ fontSize: 10, marginRight: 5 }}>Powered by</span>
              <a href="https://evensimpler.pt/">
                <img alt="evensimpler" width="100px" src={logo} />
              </a>
            </div>

            <Avatar
              src={
                user.avatarURL != null && user.avatarURL !== ""
                  ? `${config.IMG_ENDPOINT}/uploads/${user.avatarURL}`
                  : ""
              }
              style={{
                marginTop: 30,
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 10,
                height: 50,
                width: 50,
              }}
            ></Avatar>
            <Typography
              style={{
                fontWeight: "bold",
                margin: "0 auto",
                maxWidth: "150px",
              }}
              align="center"
            >
              {user.name}
            </Typography>

            {organizationDropdownUI()}

            <Divider style={{ marginTop: 20 }} />
          </div>
          <List>
            <ListSubheader>Management</ListSubheader>
            <Link to="/" style={{ color: "inherit", textDecoration: "inherit" }}>
              <ListItem button>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText>Dashboard</ListItemText>
              </ListItem>
            </Link>
            {user.accessLevel != null && user.accessLevel <= 2 ? (
              <Link to="/transactions" style={{ color: "inherit", textDecoration: "inherit" }}>
                <ListItem button>
                  <ListItemIcon>
                    <ImportExportIcon />
                  </ListItemIcon>
                  <ListItemText>Transactions</ListItemText>
                </ListItem>
              </Link>
            ) : null}
            {user.accessLevel <= 1 ? (
              <Link
                to="/org-settings"
                style={{
                  color: "inherit",
                  textDecoration: "inherit",
                }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText>Organization</ListItemText>
                </ListItem>
              </Link>
            ) : null}
            {user.accessLevel <= 1 ? (
              <Link
                to="/finance"
                style={{
                  color: "inherit",
                  textDecoration: "inherit",
                }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <AttachMoneyIcon />
                  </ListItemIcon>
                  <ListItemText>Finance</ListItemText>
                </ListItem>
              </Link>
            ) : null}
            {user.accessLevel <= 1 ? (
              <Link
                to="/email-settings"
                style={{
                  color: "inherit",
                  textDecoration: "inherit",
                }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <ListItemText>Edit Emails</ListItemText>
                </ListItem>
              </Link>
            ) : null}
          </List>
          <List>
            <ListSubheader>ESNcard</ListSubheader>
            <Link to="/student-requests" style={{ color: "inherit", textDecoration: "inherit" }}>
              <ListItem button>
                <ListItemIcon>
                  <MarkunreadMailboxIcon />
                </ListItemIcon>
                <ListItemText>Requests</ListItemText>
              </ListItem>
            </Link>
            <Link to="/add-student" style={{ color: "inherit", textDecoration: "inherit" }}>
              <ListItem button>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText>Register Student</ListItemText>
              </ListItem>
            </Link>
            <Link to="/students" style={{ color: "inherit", textDecoration: "inherit" }}>
              <ListItem button>
                <ListItemIcon>
                  <SchoolIcon />
                </ListItemIcon>
                <ListItemText>View Students</ListItemText>
              </ListItem>
            </Link>
          </List>
          <List>
            <ListSubheader>Events</ListSubheader>
            <Link to="/event-purchases" style={{ color: "inherit", textDecoration: "inherit" }}>
              <ListItem button>
                <ListItemIcon>
                  <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText>Purchases</ListItemText>
              </ListItem>
            </Link>
            {user.accessLevel != null && user.accessLevel <= 3 ? (
              <Link to="/add-event" style={{ color: "inherit", textDecoration: "inherit" }}>
                <ListItem button>
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText>Create Event</ListItemText>
                </ListItem>
              </Link>
            ) : null}
            {user.accessLevel != null && user.accessLevel <= 3 ? (
              <Link to="/events" style={{ color: "inherit", textDecoration: "inherit" }}>
                <ListItem button>
                  <ListItemIcon>
                    <FlagIcon />
                  </ListItemIcon>
                  <ListItemText>View Events</ListItemText>
                </ListItem>
              </Link>
            ) : null}
            <Link to="/checkin" style={{ color: "inherit", textDecoration: "inherit" }}>
              <ListItemButton>
                <ListItemIcon>
                  <QrCodeIcon />
                </ListItemIcon>
                <ListItemText>Check-in</ListItemText>
              </ListItemButton>
            </Link>
          </List>
          <List>
            <ListSubheader>Shared Events</ListSubheader>
            {user.accessLevel != null && user.accessLevel <= 3 ? (
              <Link to="/add-shared-event" style={{ color: "inherit", textDecoration: "inherit" }}>
                <ListItem button>
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText>Join Event</ListItemText>
                </ListItem>
              </Link>
            ) : null}
            {user.accessLevel != null && user.accessLevel <= 3 ? (
              <Link to="/shared-events" style={{ color: "inherit", textDecoration: "inherit" }}>
                <ListItem button>
                  <ListItemIcon>
                    <FlagIcon />
                  </ListItemIcon>
                  <ListItemText>View Events</ListItemText>
                </ListItem>
              </Link>
            ) : null}
          </List>
          {user.accessLevel != null && user.accessLevel <= 1 ? (
            <List>
              <ListSubheader>Users</ListSubheader>
              <Link to="/add-user" style={{ color: "inherit", textDecoration: "inherit" }}>
                <ListItem button>
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText>Create User</ListItemText>
                </ListItem>
              </Link>
              <Link to="/users" style={{ color: "inherit", textDecoration: "inherit" }}>
                <ListItem button>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText>View Users</ListItemText>
                </ListItem>
              </Link>
            </List>
          ) : null}
          <List>
            <ListSubheader>Account</ListSubheader>
            <Link
              to={`/edit-user/${user._id}`}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <ListItem button>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText>Account Settings</ListItemText>
              </ListItem>
            </Link>
            <ListItem button onClick={logout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </ListItem>
          </List>
        </PerfectScrollbar>
      </Drawer>
    );
  };

  return (
    <div>
      {drawerUI()}
      <AppBar position="fixed">
        <Toolbar>
          <IconButton sx={{ mr: 3, color: "#ffffff" }} onClick={() => setDrawerOpen(!drawerOpen)}>
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6" noWrap sx={{ color: "white" }}>
              {title}
            </Typography>
          </Box>
          <HelpIcon organization={organization} />
          <NotificationIcon />
        </Toolbar>
      </AppBar>
      <Container style={{ flexGrow: 1, paddingTop: 80 }}>
        {user.accessLevel != null && organization !== "" ? (
          <Routes>
            <Route exact path="/send-email" element={<EmailSender />} />
            <Route
              exact
              path="/transactions"
              element={
                <Transactions user={user} organization={organization} setTitle={updateTitle} />
              }
            />
            <Route
              exact
              path="/transaction-details/:transactionId"
              element={
                <TransactionDetails
                  user={user}
                  organization={organization}
                  setTitle={updateTitle}
                />
              }
            />
            <Route
              exact
              path="/student-requests"
              element={
                <StudentRequests user={user} organization={organization} setTitle={updateTitle} />
              }
            />
            <Route
              exact
              path="/add-student"
              element={
                <AddStudent user={user} organization={organization} setTitle={updateTitle} />
              }
            />
            <Route
              exact
              path="/student-profile/:studentId"
              element={
                <StudentProfile user={user} organization={organization} setTitle={updateTitle} />
              }
            />
            <Route
              exact
              path="/students"
              element={<Students user={user} organization={organization} setTitle={updateTitle} />}
            />
            <Route
              exact
              path="/edit-student/:editCardId"
              element={
                <AddStudent user={user} organization={organization} setTitle={updateTitle} />
              }
            />
            <Route
              exact
              path="/finish-request/:requestId"
              element={
                <AddStudent user={user} organization={organization} setTitle={updateTitle} />
              }
            />
            <Route
              exact
              path="/event-purchases"
              element={
                <EventPurchases user={user} organization={organization} setTitle={updateTitle} />
              }
            />
            <Route
              exact
              path="/event-purchases/:eventId"
              element={
                <EventPurchases user={user} organization={organization} setTitle={updateTitle} />
              }
            />
            <Route
              exact
              path="/event-purchase-details/:purchaseId"
              element={
                <EventPurchaseDetails
                  user={user}
                  organization={organization}
                  setTitle={updateTitle}
                />
              }
            />
            <Route
              exact
              path="/wishlist/:eventId"
              element={<Wishlist user={user} organization={organization} setTitle={updateTitle} />}
            />
            <Route
              exact
              path="/add-event"
              element={<AddEvent user={user} organization={organization} setTitle={updateTitle} />}
            />
            <Route
              exact
              path="/events"
              element={<Events user={user} organization={organization} setTitle={updateTitle} />}
            />
            <Route
              exact
              path="/edit-event/:editEventId"
              element={<AddEvent user={user} organization={organization} setTitle={updateTitle} />}
            />
            <Route
              exact
              path="/clone-event/:cloneEventId"
              element={<AddEvent user={user} organization={organization} setTitle={updateTitle} />}
            />
            <Route
              exact
              path="/add-shared-event"
              element={
                <AddSharedEvent user={user} organization={organization} setTitle={updateTitle} />
              }
            />
            <Route
              exact
              path="/shared-events"
              element={
                <SharedEvents user={user} organization={organization} setTitle={updateTitle} />
              }
            />
            <Route
              exact
              path="/edit-shared-event/:editEventId"
              element={
                <AddSharedEvent user={user} organization={organization} setTitle={updateTitle} />
              }
            />
            <Route
              exact
              path="/add-user"
              element={
                <AddUser
                  user={user}
                  organization={organization}
                  organizations={organizationList}
                  setTitle={updateTitle}
                />
              }
            />
            <Route
              exact
              path="/edit-user/:editUserId"
              element={
                <AddUser
                  user={user}
                  organization={organization}
                  organizations={organizationList}
                  setTitle={updateTitle}
                />
              }
            />
            <Route
              exact
              path="/users"
              element={<Users user={user} organization={organization} setTitle={updateTitle} />}
            />
            <Route
              exact
              path="/org-settings"
              element={
                <OrgSettings user={user} organization={organization} setTitle={updateTitle} />
              }
            />
            <Route
              exact
              path="/email-settings"
              element={
                <EmailSettings user={user} organization={organization} setTitle={updateTitle} />
              }
            />
            <Route
              exact
              path="/finance"
              element={<Finance user={user} organization={organization} setTitle={updateTitle} />}
            />
            <Route
              exact
              path="/checkin"
              element={<CheckIn user={user} organization={organization} setTitle={updateTitle} />}
            />
            <Route
              exact
              path="*"
              element={<Dashboard user={user} organization={organization} setTitle={updateTitle} />}
            />
          </Routes>
        ) : null}
      </Container>
    </div>
  );
}

export default Main;
