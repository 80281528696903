const universities = {
  portugal: {
    "5f1ebf862fd6333295e8e9a4": {
      Almada: [
        "Universidade Nova de Lisboa (UNL) - Faculdade de Ciências e Tecnologias (FCT)",
        "Instituto Superior de Ciências da Saúde Egas Moniz",
        "Escola Superior de Educação Jean Piaget de Almada",
        "ESN Intern",
        "Other",
      ],
    },
    "5f1ebf8a2fd6333295e8e9a5": {
      Aveiro: [
        "Universidade de Aveiro (UA) - Departamento de Ambiente e Ordenamento (DAO)",
        "Universidade de Aveiro (UA) - Departamento de Biologia (DBio)",
        "Universidade de Aveiro (UA) - Departamento de Ciências Médicas (DCM)",
        "Universidade de Aveiro (UA) - Departamento de Ciências Sociais, Políticas e do Território (DCSPT)",
        "Universidade de Aveiro (UA) - Departamento de Comunicação e Arte (DeCA)",
        "Universidade de Aveiro (UA) - Departamento de Economia, Gestão, Engenharia Industrial e Turismo (DEGEIT)",
        "Universidade de Aveiro (UA) - Departamento de Educação e Psicologia (DEP)",
        "Universidade de Aveiro (UA) - Departamento de Eletrónica, Telecomunicações e Informática (DETI)",
        "Universidade de Aveiro (UA) - Departamento de Engenharia de Materiais e Cerâmica (DEMaC)",
        "Universidade de Aveiro (UA) - Departamento de Engenharia Civil (DECivil)",
        "Universidade de Aveiro (UA) - Departamento de Engenharia Mecânica (DEM)",
        "Universidade de Aveiro (UA) - Departamento de Física (DFis)",
        "Universidade de Aveiro (UA) - Departamento de Geociências (DGeo)",
        "Universidade de Aveiro (UA) - Departamento de Línguas e Culturas (DLC)",
        "Universidade de Aveiro (UA) - Departamento de Matemática (DMat)",
        "Universidade de Aveiro (UA) - Departamento de Química (DQ)",
        "Universidade de Aveiro (UA) - Escola Superior de Design, Gestão e Tecnologias da Produção Aveiro Norte (ESAN)",
        "Universidade de Aveiro (UA) - Escola Superior de Saúde da Universidade de Aveiro (ESSUA)",
        "Universidade de Aveiro (UA) - Escola Superior de Tecnologia e Gestão de Águeda (ESTGA)",
        "Universidade de Aveiro (UA) - Instituto Superior de Contabilidade e Administração da Universidade de Aveiro (ISCA-UA)",
        "Instituto Superior de Ciências da Informação e da Administração (ISCIA)",
        "Instituto Superior de Entre Douro e Vouga (ISVOUGA)",
        "Instituto Superior de Espinho (ISESP)",
        "Escola Superior de Saúde Norre da Cruz Vermelha Portuguesa (Oliveira de Azeméis)",
        "Instituto Português de Administração de Marketing de Matosinhos (IPAM) - Aveiro",
        "Escola Superior de Tecnologia e Gestão de Águeda",
        "Escola Superior Aveiro Norte (Oliveira de Azeméis)",
        "ESN Intern",
        "Other",
      ],
    },
    "5f1ebf7e2fd6333295e8e9a3": {
      Algarve: [
        "Universidade do Algarve (UAlg) - Escola Superior de Educação e Comunicação (ESEC)",
        "Universidade do Algarve (UAlg) - Escola Superior de Gestão, Hotelaria e Turismo (ESGHT)",
        "Universidade do Algarve (UAlg) - Escola Superior de Saúde (ESS)",
        "Universidade do Algarve (UAlg) - Instituto Superior de Engenharia (ISE)",
        "Universidade do Algarve (UAlg) - Faculdade de Ciências Humanas e Sociais (FCHS)",
        "Universidade do Algarve (UAlg) - Faculdade de Ciências e Tecnologia (FCT)",
        "Universidade do Algarve (UAlg) - Faculdade de Economia (FE)",
        "Universidade do Algarve (UAlg) - Departamento de Ciências Biomédias e Medicina (DCBM)",
        "Instituto Superior Manuel Teixeira Gomes (ISMAT)",
        "Escola Superior de Saúde Jean Piaget - Algarve",
        "ESN Intern",
        "Other",
      ],
    },
    "5f1ebf952fd6333295e8e9a7": {
      "Castelo Branco": [
        "Instituto Politécnico de Castelo Branco (IPCB) - Escola Superior Agrária (ESA)",
        "Instituto Politécnico de Castelo Branco (IPCB) - Escola Superior de Artes Aplicadas (ESART)",
        "Instituto Politécnico de Castelo Branco (IPCB) - Escola Superior de Educação (ESE)",
        "Instituto Politécnico de Castelo Branco (IPCB) - Escola Superior de Gestão de Idanha-a-Nova (ESGIN)",
        "Instituto Politécnico de Castelo Branco (IPCB) - Escola Superior de Saúde Dr. Lopes Dias (ESALD)",
        "Instituto Politécnico de Castelo Branco (IPCB) - Escola Superior de Tecnologia (EST)",
        "ESN Intern",
        "Other",
      ],
    },
    "5f1ebf9b2fd6333295e8e9a8": {
      Coimbra: [
        "Universidade de Coimbra (UC) - FMUC - Faculdade de Medicina",
        "Universidade de Coimbra (UC) - FDUC - Faculdade de Direito",
        "Universidade de Coimbra (UC) - FLUC - Faculdade de Letras",
        "Universidade de Coimbra (UC) - FCTUC - Faculdade de Ciências e Tecnologia",
        "Universidade de Coimbra (UC) - FFUC - Faculdade de Farmácia",
        "Universidade de Coimbra (UC) - FEUC - Faculdade de Economia",
        "Universidade de Coimbra (UC) - FPCE - Faculdade de Psicologia e de Ciências da Educação",
        "Universidade de Coimbra (UC) - FCDEF - Faculdade de ciências do Desporto e Educação Física",
        "Instituto Politécnico de Coimbra (IPC) - Escola Superior Agrária de Coimbra (ESAC)",
        "Instituto Politécnico de Coimbra (IPC) - Escola Superior de Contabilidade e Administração de Coimbra (ISCAC)",
        "Instituto Politécnico de Coimbra (IPC) - Escola Superior de Educação de Coimbra (ESEC)",
        "Instituto Politécnico de Coimbra (IPC) - Instituto Superior de Engenharia de Coimbra (ISEC)",
        "Instituto Politécnico de Coimbra (IPC) - Escola Superior de Tecnologia da Saúde (ESTeSC)",
        "Instituto Politécnico de Coimbra (IPC) - Escola Superior de Tecnologia e Gestão de Oliveira do Hospital (ESTGOH)",
        "Escola Superior de Enfermagem de Coimbra (ESEnfC)",
        "Instituto Superior Miguel Torga (ISMT)",
        "Escola Universitária Vasco da Gama (EUVG)",
        "ESN Intern",
        "Other",
      ],
    },
    "5f1ebfa12fd6333295e8e9a9": {
      Covilhã: [
        "Universidade da Beira Interior (UBI) - Faculdade de Ciências",
        "Universidade da Beira Interior (UBI) - Faculdade de Engenharia",
        "Universidade da Beira Interior (UBI) - Faculdade de Ciências Sociais e Humanas",
        "Universidade da Beira Interior (UBI) - Faculdade de Artes e Letras",
        "Universidade da Beira Interior (UBI) - Faculdade de Ciências da Saúde",
        "ESN Intern",
        "Other",
      ],
    },
    "5f1ebfa72fd6333295e8e9aa": {
      Évora: [
        "Universidade de Évora (UEv) - Escola de Artes",
        "Universidade de Évora (UEv) - Escola de Ciências e Tecnologia",
        "Universidade de Évora (UEv) - Escola de Ciências Sociais",
        "Universidade de Évora (UEv) - Escola Superior de Enfermagem S. João de Deus",
        "Universidade de Évora (UEv) - Instituto de Invertigação e Formação Avançada",
        "ESN Intern",
        "Other",
      ],
    },
    "5f1ebfaf2fd6333295e8e9ac": {
      Lisboa: [
        "Universidade de Lisboa - Faculdade de Arquitectura",
        "Universidade de Lisboa - Faculdade de Belas Artes",
        "Universidade de Lisboa - Faculdade de Ciências",
        "Universidade de Lisboa - Faculdade de Direito",
        "Universidade de Lisboa - Faculdade de Letras",
        "Universidade de Lisboa - Faculdade de Medicina",
        "Universidade de Lisboa - Faculdade de Medicina Veterinária",
        "Universidade de Lisboa - Faculdade de Motrocidade Humana",
        "Universidade de Lisboa - Faculdade de Psicologia",
        "Universidade de Lisboa - Instituto de Ciências Sociais",
        "Universidade de Lisboa - Instituto de Educação",
        "Universidade de Lisboa - Instituto de Geografia e Ordenamento do Território",
        "Universidade de Lisboa - Insituto Superior de Agronomia",
        "Universidade de Lisboa - Instituto Superior de Ciências Sociais e Políticas",
        "Universidade de Lisboa - Insituto Superior de Economia e Gestão",
        "Universidade de Lisboa - Instituto Superior Técnico",
        "Universidade de Lisboa - ISCTE- Instituto Universitário de Lisboa",
        "Universidade Nova de Lisboa - Faculdade de Ciências Sociais e Humanas",
        "Universidade Nova de Lisboa - Nova School of Business and Economics",
        "Universidade Nova de Lisboa - Nova Medical School / Faculdade de Ciências Médicas",
        "Universidade Nova de Lisboa - Faculdade de Direito",
        "Universidade Nova de Lisboa - Instituto de Higiene e Medicina Tropical",
        "Universidade Nova de Lisboa - Nova Informatic Management School",
        "Universidade Nova de Lisboa - Instituto de Tecnologia Quimica e Biológica António Xavier",
        "Universidade Nova de Lisboa - Escola Nacional de Sáude Pública",
        "Escola Superior de Enfermagem de Lisboa",
        "Escola Superior de Hotelaria e Turismo do Estoril",
        "Escola Superior Nautica Infante D. Henrique",
        "Instituto Politécnico de Lisboa - Escola Superior de Comunicação Social",
        "Instituto Politécnico de Lisboa - Escola Superior de Dança",
        "Instituto Politécnico de Lisboa - Escola Superior de Educação",
        "Instituto Politécnico de Lisboa - Escola Superior de Música",
        "Instituto Politécnico de Lisboa - Escola Superior de Teatro e Cinema",
        "Instituto Politécnico de Lisboa - Escola Superior de Tecnologia e Saúde de Lisboa",
        "Instituto Politécnico de Lisboa - Instituto Superior de Contabilidade e Admininstração de Lisboa",
        "Instituto Politécnico de Lisboa - Instituto Superior de Engenharia de Lisboa",
        "Instituto Superior de Ciências Policiais e Segurança Interna",
        "Instituto Universitário Militar - Academia da Força Aérea",
        "Instituto Universitário Militar - Academia Militar",
        "Instituto Superior de Gestão",
        "ISPA - Instituto Universitário de Ciências Psicológicas Económicas e Empresariais",
        "Universidade Católica - Faculdade de Ciências Económicas e Empresariais",
        "Universidade Católica - Faculdade de Ciências Humanas",
        "Universidade Católica - Faculdade de Direito",
        "Universidade Católica - Faculdade de Teologia",
        "Universidade Católica - Instituto de Estudo Políticos",
        "Universidade Europeia",
        "IADE",
        "LISPOLIS",
        "Universidade Lusíada",
        "Universidade Lusófona de Humanidades e Tecnologias",
        "Others - Academia Nacional Superior de Orquestra",
        "Others - Atlântica - Esc. Univ. de Ciências Empresariais, Saúde, Tecnologias e Engenharia",
        "Others - Escola Superior de Atividades Imobiliárias",
        "Others - Escola Superior de Educação de Almeida Garrett",
        "Others - Escola Superior de Educação de João de Deus",
        "Others - Escola Superior de Educadores de Infância Maria Ulrich",
        "Others - Escola Superior de Enfermagem São Francisco das Misericórdias",
        "Others - Escola Superior de Saúde do Alcoitão",
        "Others - Escola Superior de Saúde Atlântica",
        "Others - Escola Superior de Saúde da Cruz Vermelha Portuguesa",
        "Others - Escola Superior de Saúde Ribeiro Sanches",
        "Others - Instituto Português de Administração de Marketing de Lisboa",
        "Others - Instituto Superior de Ciências da Administração",
        "Others - Instituto Superior de Ciências Educativas",
        "Others - Instituto Superior de Comunicação Empresarial",
        "Others - Instituto Superior de Novas Profissões",
        "Others - Instituto Superior de Tecnologias Avançadas de Lisboa",
        "Others - ISEC Lisboa - Instituto Superior de Educação e Ciências",
        "Others - Universidade Católica Portuguesa - Escola Superior Politécnica de Saúde (Lisboa)",
        "ESN Intern",
        "Other",
      ],
    },
    "5f1ebfb52fd6333295e8e9ad": {
      Madeira: [
        "Universidade da Madeira (UMa) - Faculdade de Artes e Humanidades",
        "Universidade da Madeira (UMa) - Faculdade de Ciências Exactas e da Engenharia",
        "Universidade da Madeira (UMa) - Faculdade de Ciências Sociais",
        "Universidade da Madeira (UMa) - Faculdade de Ciências da Vida",
        "Universidade da Madeira (UMa) - Escola Superior de Saúde",
        "Universidade da Madeira (UMa) - Escola Superior de Tecnologias e Gestão",
        "Escola Superior de Enfermagem de São José de Cluny",
        "Instituto Superior de Administração e Línguas da Madeira (ISAL)",
        "ESN Intern",
        "Other",
      ],
    },
    "5f1ebfb82fd6333295e8e9ae": {
      Minho: [
        "Universidade do Minho (UM) - Escola de Arquitectura",
        "Universidade do Minho (UM) - Escola de Ciências",
        "Universidade do Minho (UM) - Escola de Engenharia",
        "Universidade do Minho (UM) - Instituto de Design",
        "Universidade do Minho (UM) - Instituto de Educação",
        "Universidade do Minho (UM) - Instituto de Letras e Ciências Humanas",
        "Universidade do Minho (UM) - Instituto de Ciências Sociais",
        "Universidade do Minho (UM) - Escola de Economia e Gestão",
        "Universidade do Minho (UM) - Escola de Direito",
        "Universidade do Minho (UM) - Escola de Ciências da Saúde",
        "Universidade do Minho (UM) - Escola de Psicologia",
        "Universidade do Minho (UM) - Escola de Superior de Enfermagem",
        "Universidade Católica Portuguesa (UCP) - Centro Regional de Braga - Faculdade de Filosofia e de Ciências Sociais",
        "Universidade Católica Portuguesa (UCP) - Centro Regional de Braga - Faculdade de Teologia",
        "Universidade Lusíada de Vila Nova de Famalicão",
        "Instituto Politécnico do Cávado e do Ave (IPCA) - ESG - Escola Superior de Gestão de Barcelos",
        "Instituto Politécnico do Cávado e do Ave (IPCA) - EST - Escola Superior de Tecnologia de Barcelos",
        "Instituto Politécnico do Cávado e do Ave (IPCA) - ESD - Escola Superior de Design de Barcelos",
        "Instituto Politécnico do Cávado e do Ave (IPCA) - ESHT - Escola Superior de Hotelaria e Turismo",
        "Instituto de Estudos Superiores de Fafe (IESF)",
        "Escola Superior Artística do Porto (ESAP) - Guimarães",
        "Instituto Politécnico de Saúde do Norte",
        "Instituto Superior de Saúde do Alto Ave",
        "ESN Intern",
        "Other",
      ],
    },
    "5f1ebfbc2fd6333295e8e9af": {
      Porto: [
        "Universidade do Porto (UP) - Faculdade Arquitectura da Universidade do Porto",
        "Universidade do Porto (UP) - Faculdade de Belas Artes da Universidade do Porto",
        "Universidade do Porto (UP) - Faculdade de Ciências da Nutrição da Universidade do Porto",
        "Universidade do Porto (UP) - Faculdade de Ciências da Universidade do Porto",
        "Universidade do Porto (UP) - Faculdade de Desporto da Universidade do Porto",
        "Universidade do Porto (UP) - Faculdade de Direito da Universidade do Porto",
        "Universidade do Porto (UP) - Faculdade de Economia da Universidade do Porto",
        "Universidade do Porto (UP) - Faculdade de Engenharia da Universidade do Porto",
        "Universidade do Porto (UP) - Faculdade de Farmácia da Universidade do Porto",
        "Universidade do Porto (UP) - Faculdade de Letras da Universidade do Porto",
        "Universidade do Porto (UP) - Faculdade de Medicina da Universidade do Porto",
        "Universidade do Porto (UP) - Faculdade de Medicina Dentária da Universidade do Porto",
        "Universidade do Porto (UP) - Faculdade de Psicologia e de Ciências da Educação da Universidade do Porto",
        "Universidade do Porto (UP) - Instituto de Ciências Biomédicas Abel Salazar",
        "Universidade do Porto (UP) - Porto Business School",
        "Instituto Politécnico do Porto - Instituto Superior de Engenharia do Porto",
        "Instituto Politécnico do Porto - Instituto Superior de Contabilidade e Administração do Porto",
        "Instituto Politécnico do Porto - Escola Superior de Educação ",
        "Instituto Politécnico do Porto - Escola Superior de Música e Artes do Espectáculo",
        "Instituto Politécnico do Porto - Escola Superior de Tecnologia e Gestão de Felgueiras",
        "Instituto Politécnico do Porto - Escola Superior de Saúde",
        "Instituto Politécnico do Porto - Escola Superior de Hotelaria e Turismo",
        "Instituto Politécnico do Porto - Escola Superior de Media Artes e Design",
        "Instituto Politécnico do Porto - Escola Superior de Estudos Industriais e de Gestão",
        "Conservatório Superior de Música de Gaia",
        "Cooperativa de Ensino Superior Politécnico e Universitário",
        "Escola Superior Artística do Porto (ESAP)",
        "Escola Superior de Artes e Design de Matosinhos",
        "Escola Superior de Artística do Porto",
        "Escola Superior de Educação de Paula Frassinetti",
        "Escola Superior de Educação de Santa Maria",
        "Escola Superior de Educação Jean Piaget de Arcozelo",
        "Escola Superior de Enfermagem de Santa Maria",
        "Escola Superior de Enfermagem do Porto",
        "Escola Superior de Saúde Jean Piaget de Vila Nova de Gaia",
        "European Voluntary Service (EVS)",
        "Instituto de Estudos Superiores Financeiros e Fiscais - Porto",
        "Instituto Politécnico da Maia - IPMAIA",
        "Instituto Politécnico de Gestão e Tecnologia",
        "Instituto Politécnico de Saúde do Norte - Escola Superior de Saúde do Vale do Sousa",
        "Instituto Português de Administração e Marketing de Matosinhos",
        "Instituto Superior de Administração e Gestão",
        "Instituto Superior de Ciências da Saúde - Norte",
        "Instituto Superior de Ciências Educativas de Felgueiras",
        "Instituto Superior de Ciências Empresariais e do Turismo",
        "Instituto Superior de Educação e Trabalho",
        "Instituto Superior de Línguas e Administração de Vila Nova de Gaia",
        "Instituto Superior de Serviço Social do Porto",
        "Instituto Superior de Tecnologias Avançadas de Lisboa - Porto",
        "Instituto Superior Politécnico Gaya (ISPGaya)",
        "Instituto Universitário da Maia - ISMAI",
        "Universidade Católica Portuguesa",
        "Universidade Fernando Pessoa",
        "Universidade Lusíada do Porto",
        "Universidade Lusófona",
        "Universidade Portucalense Infante D. Henrique (UPT)",
        "ESN Intern",
        "Other",
      ],
    },
    "5f1ebfbf2fd6333295e8e9b0": {
      Tomar: ["Instituto Politécnico de Tomar", "ESN Intern", "Other"],
    },
    "5f1ebfc52fd6333295e8e9b1": {
      UTAD: [
        "Universidade de Trás-os-Montes e Alto Douro (UTAD) - Escola de Ciências Agrárias e Veterinárias (ECAV)",
        "Universidade de Trás-os-Montes e Alto Douro (UTAD) - Escola de Ciências Humanas e Sociais (ECHS)",
        "Universidade de Trás-os-Montes e Alto Douro (UTAD) - Escola de Ciências e Tecnologia (ECT)",
        "Universidade de Trás-os-Montes e Alto Douro (UTAD) - Escola de Ciências da Vida e do Ambiente (ECVA)",
        "Universidade de Trás-os-Montes e Alto Douro (UTAD) - Escola Superior de Saúde (ESS)",
        "Escola Superior de Enfermagem Dr. José Timóteo Montalvão Machado",
        "ESN Intern",
        "Other",
      ],
    },
    "5f2bd933b977bbb4a9224be8": {
      Beja: ["Instituto Politécnico de Beja (IPBeja)", "ESN Intern", "Other"],
      Guarda: ["Instituto Politécnico da Guarda (IPG)", "ESN Intern", "Other"],
      Portalegre: ["Instituto Politécnico de Portalegre", "ESN Intern", "Other"],
      Açores: ["Universidade dos Açores (UAç)", "ESN Intern", "Other"],
      Santarém: [
        "Instituto Politécnico de Santarém (IPS)",
        "Instituto Superior de Línguas e Administração de Santarém (ISLA)",
        "Escola Superior de Educação de Torres Novas",
        "ESN Intern",
        "Other",
      ],
      "Viana do Castelo": [
        "Instituto Politécnico de Viana do Castelo",
        "Escola Superior Gallaecia",
        "Universidade Fernando Pessoa (UFP) - Ponte de Lima",
        "ESN Intern",
        "Other",
      ],
      Viseu: [
        "Universidade Católica Portuguesa (UCP) - Centro Regional das Beiras",
        "Instituto Politécnico de Viseu (IPV)",
        "Instituto Superior de Estudos Interculturais e Transdisciplinares - Viseu",
        "Escola Superior de Educação Jean Piaget de Arcozelo - Viseu",
        "Escola Superior de Saúde Jean Piaget",
        "ESN Intern",
        "Other",
      ],
      Setúbal: [
        "Escola Naval",
        "Instituto Superior de Estudos Interculturais e Transdisciplinares - Santo André",
        "Instituto Superior de Estudos Interculturais e Transdisciplinares - Almada",
        "Instituto Politécnico de Setúbal",
        "ESN Intern",
        "Other",
      ],
      Leiria: [
        "Instituto Politécnico de Leiria (IPL) - ESECS - Escola Superior de Educação e Ciências Sociais de Leiria",
        "Instituto Politécnico de Leiria (IPL) - ESTG - Escola Superior de Tecnologia e Gestão de Leiria",
        "Instituto Politécnico de Leiria (IPL) - ESAD.CR - Escola Superior de Artes e Design de Caldas da Rainha",
        "Instituto Politécnico de Leiria (IPL) - ESTM - Escola Superior de Turismo e Tecnologia do Mar de Peniche",
        "Instituto Politécnico de Leiria (IPL) - ESSLei - Escola Superior de Saúde de Leiria",
        "Universidade Católica Portuguesa (UCP) - Caldas da Rainha",
        "Instituto Superior de Línguas e Administração de Leiria",
        "Instituto Superior D. Dinis",
        "ESN Intern",
        "Other",
      ],
      Bragança: [
        "Instituto Politécnico de Bragança (IPB) - ESA - Escola Superior Agrária",
        "Instituto Politécnico de Bragança (IPB) - ESE - Escola Superior de Educação",
        "Instituto Politécnico de Bragança (IPB) - ESTiG - Escola Superior de Tecnologia e Gestão",
        "Instituto Politécnico de Bragança (IPB) - ESSa - Escola Superior de Saúde",
        "Instituto Politécnico de Bragança (IPB) - EsACT - Escola Superior de Comunicação, Administração e Turismo ",
        "Instituto Superior de Estudos Interculturais e Transdisciplinares - Mirandela",
        "Instituto Superior de Línguas e Administração de Bragança",
        "Instituto Jean Piaget",
        "ESN Intern",
        "Other",
      ],
    },
  },
  spain: {
    "64c4b3ebba28f8d70d3a07b0": {
      "Alcalá de Henares": ["Universidad de Alcalá de Henares", "ESN Intern", "Other"],
    },
    "64c4b4789c31f95b06526d59": {
      Alicante: ["Universidad de Alicante", "ESN Intern", "Other"],
    },
    "64c4b532a15717c5c92e11ac": {
      Almería: ["Universidad de Almería", "ESN Intern", "Other"],
    },
    "64c4b57e7bd65f40c94e952a": {
      Palma: ["Universidad de las Islas Baleares", "ESN Intern", "Other"],
    },
    "64c4b5bca4ed072ded3d37d3": {
      Barcelona: ["Universidad de Barcelona", "ESN Intern", "Other"],
    },
    "64c4b69a7abc3b8a21bad467": {
      Barcelona: ["Universidad Pompeu Fabra", "ESN Intern", "Other"],
    },
    "64c4ba2191d7b92e4446ac58": {
      Barcelona: ["Universidad Autónoma de Barcelona", "ESN Intern", "Other"],
    },
    "64c4bbd593b37322488369da": {
      Barcelona: ["Universidad Politécnica de Cataluña", "ESN Intern", "Other"],
    },
    "64c4b6bd2fb81ff02afd9600": {
      Bilbao: ["Universidad del País Vasco", "ESN Intern", "Other"],
    },
    "64c4b710c50b92b6f55317ac": {
      Burgos: ["Universidad de Burgos", "ESN Intern", "Other"],
    },
    "64c4b735e59da6b4e2b34676": {
      Cádiz: ["Universidad de Cádiz", "ESN Intern", "Other"],
    },
    "64c4b753c851f8f383306ea3": {
      Cartagena: ["Universidad Politécnica de Cartagena", "ESN Intern", "Other"],
    },
    "64c4b78c3ddd0855931ab264": {
      Castellón: ["Universidad Jaume I", "ESN Intern", "Other"],
    },
    "64c4b7c78b5e27ea30a2b4b1": {
      Córdoba: ["Universidad de Córdoba", "ESN Intern", "Other"],
    },
    "64c4b7ee69213d2d835a2de2": {
      Coruña: ["Universidad de Coruña", "ESN Intern", "Other"],
    },
    "64c4b8280d04c6770c9a93b9": {
      València: ["Universidad de Valencia", "ESN Intern", "Other"],
    },
    "64c4bb707e7aa7b8c0aa6635": {
      València: ["Universidad Católica de Valencia", "ESN Intern", "Other"],
    },
    "64c4bc811fa01c45575b6231": {
      València: ["Universidad Politécnica de Valencia", "ESN Intern", "Other"],
    },
    "64c4b84e5d8d2f97d0059c94": {
      Granada: ["Universidad de Granada", "ESN Intern", "Other"],
    },
    "64c4b87812fa469401bf1a59": {
      Jaén: ["Universidad de Jaén", "ESN Intern", "Other"],
    },
    "64c4b8b766d3ec988e26b49c": {
      "Las Palmas de Gran Canaria": ["Universidad de Las Palmas", "ESN Intern", "Other"],
    },
    "64c4b8e844350f74728a4899": {
      Málaga: ["Universidad de Málaga", "ESN Intern", "Other"],
    },
    "64c4b9196d81186c97d3acd5": {
      Murcia: ["Universidad de Murcia", "ESN Intern", "Other"],
    },
    "64c4b93e02a2e202c9e88392": {
      Oviedo: ["Universidad de Oviedo", "ESN Intern", "Other"],
    },
    "64c4b95e8e9cb807eb479d60": {
      Salamanca: ["Universidad de Salamanca", "ESN Intern", "Other"],
    },
    "64c4b98b69efe0f7a3f5fae5": {
      Santander: [
        "Universidad de Santander",
        "University of Cantabria",
        "UNEAT",
        "Cesine",
        "ESN Intern",
        "Other",
      ],
    },
    "64c4b9b00e17face17719e00": {
      "Santiago de Compostela": ["Universidade de Santiago de Compostela", "ESN Intern", "Other"],
    },
    "64c4b9d88e3caecff4fcea72": {
      Sevilla: ["Universidad de Sevilla", "ESN Intern", "Other"],
    },
    "64c4bc2be7d659bd5c3d0a6c": {
      Sevilla: ["Universidad Pablo Olavide", "ESN Intern", "Other"],
    },
    "64c4ba6c1fd951c06337c5fd": {
      Madrid: ["Universidad Autónoma de Madrid", "ESN Intern", "Other"],
    },
    "64c4bb00b0036c98262c07c1": {
      Madrid: ["Universidad Complutense de Madrid", "ESN Intern", "Other"],
    },
    "64c4bbff814ca42aed5e846b": {
      Madrid: ["Universidad Politécnica de Madrid", "ESN Intern", "Other"],
    },
    "64c4ba9aabb4da93dbc53fb0": {
      Madrid: ["Universidad Carlos III de Madrid", "ESN Intern", "Other"],
    },
    "64c4bacbe45d44c6840564c3": {
      "Ciudad Real": ["Universidad de Castilla la Mancha", "ESN Intern", "Other"],
    },
    "64c4bb921ee6f4a2860c8e16": {
      Badajoz: ["Universidad de Extremadura", "ESN Intern", "Other"],
    },
    "64c4bc5176a53cec5e3c33f2": {
      Madrid: ["Universidad de Rey Juan Carlos de Madrid", "ESN Intern", "Other"],
    },
    "64c4bca1a3290cc2d07a8c0a": {
      Valladolid: ["Universidad de Valladolid", "ESN Intern", "Other"],
    },
    "64c4bcb72e324440f406328e": {
      Vigo: ["Universidad de Vigo", "ESN Intern", "Other"],
    },
  },
  test: {
    "62e659701be55c3bd4fdc4d9": {
      TestRegion: ["Test University", "ESN Intern", "Other"],
    },
    "63caf77e9b2c3d5237f82239": {
      TestRegion: ["Test University 2", "ESN Intern", "Other"],
    },
    "6635ff4332fba3b320c874e6": {
      TestRegion: ["Test University 3", "ESN Intern", "Other"],
    },
  },
};

export default universities;
