import {
	Card,
	CardContent,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	IconButton,
	TablePagination,
	Button,
	Menu,
	MenuItem,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	FormControl,
	Select,
	InputLabel,
	Avatar,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { toast } from "react-toastify";
import config from "../../utils/config";
import AddTransaction from "./AddTransaction";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";

const numRows = 10;

function Transactions(props) {
	const [transactions, setTransactions] = useState([]);
	const [adding, setAdding] = useState(false);
	const [menuAnchorEl, setMenuAnchorEl] = useState(null);
	const [menuTransaction, setMenuTransaction] = useState(null);
	const [page, setPage] = useState(0);
	const [totalRows, setTotalRows] = useState(0);
	const [lastPage, setLastPage] = useState(0);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [type, setType] = useState("");

	useEffect(() => {
		props.setTitle("Transactions");
	}, []); //eslint-disable-line

	useEffect(() => {
		fetchTransactions(0);
		setPage(0);
		setLastPage(0);
	}, [props.organization, startDate, endDate, type]); //eslint-disable-line

	const handlePageChange = (_, newPage) => {
		if (newPage > lastPage) {
			fetchTransactions(newPage);
			setLastPage(newPage);
		}

		setPage(newPage);
	}

	const fetchNumTransactions = () => {
		let q = `${config.API_ENDPOINT}/transactions/${props.organization}/count`;

		if (startDate && endDate) {
			q +=
				"?startDate=" +
				startDate.getTime() +
				"&endDate=" +
				endDate.getTime();
		} else if (startDate || endDate) {
			return;
		}

		if (type !== "") {
			if (q.includes("?")) {
				q += '&';
			} else {
				q += '?';
			}
			q += `transactionType=${type}`;
		}

		axios
			.get(q, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(res => {
				setTotalRows(res.data.count);
			})
			.catch(() => {
				toast.error("An unexpected error has occurred");
			});
	};

	const fetchTransactions = useDebouncedCallback((page) => {
		fetchNumTransactions();

		let q = `${config.API_ENDPOINT}/transactions/${props.organization}/all?page=${page}&numRows=${numRows}&populateUser=true`;

		if (startDate && endDate) {
			q +=
				"&startDate=" +
				startDate.getTime() +
				"&endDate=" +
				endDate.getTime();
		} else if (startDate || endDate) {
			return;
		}

		if (type !== "") {
			q += `&transactionType=${type}`;
		}

		axios
			.get(q, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(res => {
				if (page === 0) {
					setTransactions(res.data);
				} else {
					setTransactions([...transactions, ...res.data]);
				}
			})
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}, 500);

	const exportTransactions = useDebouncedCallback(() => {
		axios
			.post(
				`${config.API_ENDPOINT}/transactions/${props.organization}/export`,
				{ startDate: startDate.getTime(), endDate: endDate.getTime() },
				{ headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(() => toast.success("Success! You will receive an email with your file shortly"))
			.catch((err) => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}, 500, { leading: true });

	const getTypeName = (type) => {
		switch (type) {
			case "manual":
				return "Manual";
			case "esncard":
				return "ESNcard";
			case "event":
				return "Event";
			case "reimbursement":
				return "Reimbursement";
			default:
				return "";
		}
	}

	const handleDateChange = (item) => {
		setStartDate(item?.selection.startDate);
		setEndDate(item?.selection.endDate);
	}

	const filtersUI = () => {
		return (
			<Accordion style={{ marginBottom: 20 }}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>View Filters</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<div>
						<Card>
							<CardContent>
								<Typography style={{ fontWeight: "bold", marginBottom: 10 }}>
									By Date
								</Typography>
								{startDate && endDate ? (
									<Button
										style={{ marginBottom: 10 }}
										onClick={() => handleDateChange(null)}
									>
										Clear Selection
									</Button>
								) : null}
								<br />
								<DateRange
									editableDateInputs={false}
									moveRangeOnFirstSelection={false}
									ranges={[
										{
											startDate: startDate,
											endDate: endDate,
											key: "selection",
										},
									]}
									onChange={handleDateChange}
								/>
								<br />
								{startDate && endDate ? (
									<Button variant="contained" onClick={exportTransactions}>
										Export Data
									</Button>
								) : null}
							</CardContent>
						</Card>
					</div>
					<div>
						<Card sx={{ mt: 3 }}>
							<CardContent>
								<Typography style={{ fontWeight: "bold", marginBottom: 10 }}>
									By Type
								</Typography>
								<FormControl style={{ width: 200 }}>
									<InputLabel shrink id="type-label">Type</InputLabel>
									<Select
										label="Type"
										labelId="type-label"
										displayEmpty
										value={type}
										onChange={(e) => setType(e.target.value)}
									>
										<MenuItem value={""}>All</MenuItem>
										<MenuItem value={"manual"}>Manual</MenuItem>
										<MenuItem value={"esncard"}>ESNcard</MenuItem>
										<MenuItem value={"event"}>Event</MenuItem>
										<MenuItem value={"reimbursement"}>Reimbursement</MenuItem>
									</Select>
								</FormControl>
							</CardContent>
						</Card>
					</div>
				</AccordionDetails>
			</Accordion>
		);
	}

	if (props.user.accessLevel > 2) return null;

	return (
		<div>
			<Card style={{ padding: 15 }}>
				<CardContent>
					{filtersUI()}
					<Button
						style={{ marginBottom: 20, marginTop: 10 }}
						variant="contained"
						startIcon={<AddIcon />}
						onClick={() => setAdding(true)}
					>
						Create Transaction
					</Button>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Date/Time</TableCell>
									<TableCell>Type</TableCell>
									<TableCell>Value</TableCell>
									<TableCell>Description</TableCell>
									<TableCell>Sponsor</TableCell>
									<TableCell>Settings</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{transactions
									.slice(
										page * numRows,
										page * numRows + numRows
									)
									.map((transaction) => (
										<TableRow key={transaction._id}>
											<TableCell>
												{moment(transaction.datetime).format(
													"DD/MM/YYYY HH:mm"
												)}
											</TableCell>
											<TableCell>
												{getTypeName(transaction.type)}
											</TableCell>
											<TableCell>
												{`${transaction.value.toFixed(2)}€`}
											</TableCell>
											<TableCell>{transaction.description}</TableCell>
											<TableCell>
												{transaction.user && (
													<div
														style={{
															marginTop: 5,
															display: "flex",
															alignItems: "center",
														}}
													>
														<Avatar
															src={transaction.user.avatarURL != null ? `${config.IMG_ENDPOINT}/uploads/${transaction.user.avatarURL}` : null}
															style={{ marginRight: 10 }}
														/>
														<p>
															{transaction.user.name} ({transaction.user.email})
														</p>
													</div>
												)}
											</TableCell>
											<TableCell>
												<IconButton
													onClick={(ev) => {
														setMenuAnchorEl(ev.currentTarget);
														setMenuTransaction(transaction._id);
													}}
												>
													<MoreVertIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
						<TablePagination
							rowsPerPageOptions={[numRows]}
							rowsPerPage={numRows}
							component="div"
							count={totalRows}
							page={page}
							onPageChange={handlePageChange}
						/>
					</TableContainer>
					<Menu
						anchorEl={menuAnchorEl}
						open={menuAnchorEl != null}
						keepMounted
						onClose={() => setMenuAnchorEl(null)}
					>
						<Link
							to={`/transaction-details/${menuTransaction}`}
							style={{ color: "inherit", textDecoration: "inherit" }}
						>
							<MenuItem>View Details</MenuItem>
						</Link>
					</Menu>
				</CardContent>
			</Card>
			{props.organization ? (
				<AddTransaction
					open={adding}
					organization={props.organization}
					user={props.user._id}
					onClose={() => setAdding(false)}
				/>
			) : null}
		</div>
	);
}

export default Transactions;