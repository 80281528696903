import {
	Card,
	CardContent,
	Grid,
	TextField,
	MenuItem,
	Select,
	InputLabel,
	FormControl,
	Typography,
	Button,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { toast } from "react-toastify";
import config from "../../utils/config";
import moment from "moment";
import universities from "../../utils/universities";
import mobilityPrograms from "../../utils/mobility-programs";
import countries from "../../utils/countries";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";
import { useParams } from "react-router-dom";
import { DateTimePicker } from "@mui/x-date-pickers";
import { validateEmail } from "../../utils/email-validator";

function AddStudent(props) {
	const { editCardId, requestId } = useParams();

	const [organization, setOrganization] = useState(null);
	const [name, setName] = useState("");
	const [gender, setGender] = useState("");
	const [passport, setPassport] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [birthdate, setBirthdate] = useState(null);
	const [address, setAddress] = useState("");
	const [country, setCountry] = useState("");
	const [esnCard, setEsnCard] = useState("");
	const [university, setUniversity] = useState("");
	const [region, setRegion] = useState("");
	const [vat, setVat] = useState("");
	const [customQuestions, setCustomQuestions] = useState({});
	const [registerDate, setRegisterDate] = useState(moment());
	const [program, setProgram] = useState("");
	const [programDuration, setProgramDuration] = useState("");
	const [paid, setPaid] = useState(false);

	useEffect(() => {
		if (editCardId) {
			props.setTitle("Edit Student");
		} else {
			props.setTitle("Add Student");
		}
	}, [editCardId]); //eslint-disable-line

	useEffect(() => {
		loadOrganization();
	}, [props.organization]); //eslint-disable-line

	const loadOrganization = () => {
		axios
			.get(`${config.API_ENDPOINT}/organizations/${props.organization}`, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(res => {
				setOrganization(res.data);
			})
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}

	useEffect(() => {
		if (editCardId) {
			fetchStudentInfo();
		} else if (requestId) {
			fetchRequestInfo();
		} else {
			resetState();
		}
	}, [organization, editCardId, requestId]); //eslint-disable-line

	const loadDefaultAnswers = () => {
		if (organization == null || organization.customEsnCardQuestions == null) return {};

		let result = {};

		for (let q of organization.customEsnCardQuestions) {
			if (result[q.id] != null) continue;

			if (q.type === "select-multi") {
				result[q.id] = [];
			} else {
				result[q.id] = "";
			}
		}

		setCustomQuestions(result);
	}

	const resetState = () => {
		setName("");
		setGender("");
		setPassport("");
		setEmail("");
		setPhone("");
		setBirthdate(null);
		setAddress("");
		setCountry("");
		setEsnCard("");
		setVat("");
		setRegisterDate(moment());
		setRegion("");
		setUniversity("");
		setProgram("");
		setProgramDuration("");
		setPaid(false);
		loadDefaultAnswers();
	};

	const setStateFromStudent = (student) => {
		setName(student.name);
		setGender(student.gender);
		setPassport(student.passport);
		setEmail(student.email);
		setPhone(student.phone);
		setBirthdate(moment(student.birthdate));
		setAddress(student.address);
		setCountry(student.country);
		setVat(student.vat);
		setRegion(findRegion(student.university));
		setUniversity(student.university);
		setProgram(student.program);
		setProgramDuration(student.programDuration);
		setPaid(student.paid);

		if (student.customQuestions != null) {
			setCustomQuestions(student.customQuestions);
		} else {
			loadDefaultAnswers();
		}

		if (student.registerDate != null) {
			setRegisterDate(moment(student.registerDate));
		} else {
			setRegisterDate(moment());
		}
	};

	const fetchRequestInfo = () => {
		axios
			.get(`${config.API_ENDPOINT}/student-requests/${props.organization}/${requestId}`, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(res => {
				let student = res.data;
				setStateFromStudent(student);
			})
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}

	const fetchStudentInfo = () => {
		axios
			.get(`${config.API_ENDPOINT}/students/${props.organization}/${editCardId}`, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(res => {
				let student = res.data;
				setStateFromStudent(student);
				setEsnCard(student.esnCard);
			})
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}

	const findEsnCountry = (organizationId) => {
		for (let esnCountry of Object.keys(universities)) {
			if (Object.keys(universities[esnCountry]).includes(organizationId)) {
				return esnCountry;
			}
		}

		return null;
	}

	const findRegion = (university) => {
		for (let esnCountry of Object.keys(universities)) {
			if (universities[esnCountry][props.organization] != null) {
				for (let [region, unis] of Object.entries(universities[esnCountry][props.organization])) {
					if (unis.includes(university)) {
						return region;
					}
				}
			}
		}

		return null;
	}

	const validateFields = () => {
		if (
			name === "" ||
			gender === "" ||
			passport === "" ||
			email === "" ||
			phone === "" ||
			birthdate == null ||
			!birthdate.isValid() ||
			address === "" ||
			country === "" ||
			esnCard === "" ||
			registerDate == null ||
			program === "" ||
			programDuration === "" ||
			!customQuestionsFilled()
		) {
			toast.warning("You must fill all required fields");
			return false;
		}

		if (!validateEmail(email)) {
			toast.warning("Invalid email");
			return false;
		}

		return true;
	}

	const customQuestionsFilled = () => {
		if (organization.customEsnCardQuestions == null) return true;

		for (let q of organization.customEsnCardQuestions) {
			if (!q.required) continue;

			if (
				customQuestions[q.id] == null ||
				customQuestions[q.id] === "" ||
				(Array.isArray(customQuestions[q.id]) && customQuestions[q.id].length === 0)
			) {
				return false;
			}
		}

		return true;
	}

	const submit = useDebouncedCallback(() => {
		if (!validateFields()) return;

		let student = {
			name,
			gender,
			passport,
			email,
			phone,
			birthdate: birthdate.valueOf(),
			address,
			country,
			organization: props.organization,
			esnCard,
			university,
			registerDate: registerDate.valueOf(),
			program,
			programDuration,
			vat,
			online: paid,
			requestId: requestId,
			customQuestions
		};

		axios
			.post(`${config.API_ENDPOINT}/students/create`, student, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(() => {
				toast.success("Success!");
				resetState();
			})
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					case 409:
						toast.warning("Email, passport or ESNcard in use");
						break;
					case 406:
						toast.error("Error creating invoice. Please confirm VAT and Country");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}, 500, { leading: true });

	const submitEdit = useDebouncedCallback(() => {
		if (!validateFields()) return;

		let student = {
			name,
			gender,
			passport,
			email,
			phone,
			birthdate: birthdate.valueOf(),
			address,
			country,
			organization: props.organization,
			esnCard,
			university,
			registerDate: registerDate.valueOf(),
			program,
			programDuration,
			customQuestions
		};

		axios
			.post(`${config.API_ENDPOINT}/students/${props.organization}/${editCardId}`, student, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(() => {
				toast.success("Success!");
			})
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					case 409:
						toast.warning("Email or passport in use");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}, 500, { leading: true });

	const previewCustomQuestions = () => {
		if (organization == null) return null;
		if (organization.customEsnCardQuestions == null) return null;

		return <>
			{organization.customEsnCardQuestions.map(q => <Typography key={q.id} style={{ marginTop: 20 }}>
				<strong>{q.question} </strong>
				{getQuestionValue(customQuestions[q.id], q.type)}
			</Typography>)}
		</>;
	};

	const customQuestionsForm = () => {
		if (organization == null) return null;
		if (organization.customEsnCardQuestions == null) return null;
		if (organization.customEsnCardQuestions.length !== Object.entries(customQuestions).length) return null;

		return <>{organization.customEsnCardQuestions.map((q) => {
			switch (q.type) {
				case "select-multi":
				case "select":
					return (
						<FormControl
							key={q.id}
							fullWidth
							sx={{ mb: 2 }}
							variant="outlined"
							required={q.required}
						>
							<InputLabel style={{ backgroundColor: "#fff" }}>
								{q.question}
							</InputLabel>
							<Select
								value={customQuestions[q.id]}
								multiple={q.type === "select-multi"}
								onChange={(e) => {
									let questions = { ...customQuestions };
									questions[q.id] = e.target.value;
									setCustomQuestions(questions);
								}}
							>
								{q.values.map((value) => (
									<MenuItem key={value} value={value}>{value}</MenuItem>
								))}
							</Select>
						</FormControl>
					);
				case "text":
					return (
						<TextField
							key={q.id}
							variant="outlined"
							required={q.required}
							fullWidth
							label={q.question}
							name={q.id}
							type="text"
							value={customQuestions[q.id]}
							onChange={(e) => {
								let questions = { ...customQuestions };
								questions[q.id] = e.target.value;
								setCustomQuestions(questions);
							}}
							sx={{ mb: 2 }}
						/>
					);
				case "datetime":
					return (
						<DateTimePicker
							key={q.id}
							variant="outlined"
							required={q.required}
							fullWidth
							label={q.question}
							name={q.id}
							value={moment(customQuestions[q.id])}
							onChange={val => {
								let questions = { ...customQuestions };
								questions[q.id] = moment(val).valueOf();
								setCustomQuestions(questions);
							}}
							ampm={false}
							renderInput={params => <TextField {...params} sx={{ mb: 2 }} fullWidth />}
							sx={{ mb: 2 }}
						/>
					)
				default:
					return null;
			}
		})}</>
	};

	const getQuestionValue = (value, type) => {
		if (value == null || value === "") return "";

		switch (type) {
			case "select-multi":
				return value.join(', ');
			case "datetime":
				return moment(value).format("DD-MM-YYYY HH:mm");
			default:
				return value;
		}
	}

	const previewUI = () => {
		return (
			<Grid item xs={12} md={6}>
				<Typography style={{ fontSize: 20 }}>
					<strong>Preview</strong>
				</Typography>
				<Typography style={{ marginTop: 20 }}>
					<strong>Full Name: </strong>
					{name}
				</Typography>
				<Typography style={{ marginTop: 20 }}>
					<strong>Gender: </strong>
					{gender === "male"
						? "Male"
						: gender === "female"
							? "Female"
							: gender === "other"
								? "Other"
								: ""}
				</Typography>
				<Typography style={{ marginTop: 20 }}>
					<strong>ID/Passport: </strong>
					{passport}
				</Typography>
				<Typography style={{ marginTop: 20 }}>
					<strong>Email: </strong>
					{email}
				</Typography>
				<Typography style={{ marginTop: 20 }}>
					<strong>Phone Number: </strong>
					{phone}
				</Typography>
				<Typography style={{ marginTop: 20 }}>
					<strong>Birthdate: </strong>
					{birthdate
						? birthdate.format("DD/MM/YYYY")
						: ""}
				</Typography>
				<Typography style={{ marginTop: 20 }}>
					<strong>Address: </strong>
					{address}
				</Typography>
				<Typography style={{ marginTop: 20 }}>
					<strong>Nationality: </strong>
					{country}
				</Typography>
				<Typography style={{ marginTop: 20 }}>
					<strong>ESNcard: </strong>
					{esnCard}
				</Typography>
				<Typography style={{ marginTop: 20 }}>
					<strong>University: </strong>
					{university}
				</Typography>
				{previewCustomQuestions()}
				<Button
					style={{ marginTop: 50, color: "white" }}
					variant="contained"
					color="primary"
					onClick={() => {
						if (editCardId) {
							submitEdit();
						} else {
							submit();
						}
					}}
				>
					{editCardId ? "UPDATE" : "CREATE STUDENT"}
				</Button>
			</Grid>
		);
	}

	const addFormUI = () => {
		let esnCountry = props.organization != null ? findEsnCountry(props.organization) : null
		let regions = esnCountry != null ? universities[esnCountry][props.organization] : null;
		return (
			<Grid item xs={12} md={6}>
				<TextField
					variant="outlined"
					required
					fullWidth
					autoFocus
					label="Full Name"
					name="name"
					type="text"
					autoComplete="off"
					value={name}
					onChange={(e) => setName(e.target.value)}
					sx={{ mb: 2 }}
				/>
				<FormControl
					fullWidth
					sx={{ mb: 2 }}
					variant="outlined"
					required
				>
					<InputLabel style={{ backgroundColor: "#fff" }}>Gender</InputLabel>
					<Select
						value={gender}
						onChange={(e) => setGender(e.target.value)}
					>
						<MenuItem value="male">Male</MenuItem>
						<MenuItem value="female">Female</MenuItem>
						<MenuItem value="other">Other</MenuItem>
					</Select>
				</FormControl>
				<TextField
					variant="outlined"
					required
					fullWidth
					label="ID/Passport"
					name="passport"
					type="text"
					autoComplete="off"
					value={passport}
					onChange={(e) => setPassport(e.target.value)}
					sx={{ mb: 2 }}
				/>
				<TextField
					variant="outlined"
					required
					fullWidth
					label="Email"
					name="email"
					type="email"
					autoComplete="off"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					sx={{ mb: 2 }}
				/>
				<PhoneInput
					placeholder="Phone Number*"
					value={phone}
					onChange={(phone) => setPhone(`+${phone}`)}
					style={{ marginBottom: 16 }}
				/>
				<DatePicker
					label="Birthdate"
					inputVariant="outlined"
					required
					inputFormat="DD/MM/YYYY"
					value={birthdate}
					onChange={(date) => setBirthdate(date)}
					renderInput={params => <TextField {...params} sx={{ mb: 2 }} fullWidth />}
				/>
				<TextField
					variant="outlined"
					required
					fullWidth
					label="Address"
					name="address"
					type="text"
					autoComplete="off"
					value={address}
					onChange={(e) => setAddress(e.target.value)}
					sx={{ mb: 2 }}
				/>
				<FormControl
					fullWidth
					sx={{ mb: 2 }}
					variant="outlined"
					required
				>
					<InputLabel style={{ backgroundColor: "#fff" }}>Nationality</InputLabel>
					<Select
						value={country}
						onChange={(e) => setCountry(e.target.value)}
					>
						{countries.map((country) => (
							<MenuItem key={country.name} value={country.name}>{country.name}</MenuItem>
						))}
					</Select>
				</FormControl>
				{!editCardId ? (
					<TextField
						variant="outlined"
						fullWidth
						label="VAT"
						name="vat"
						type="text"
						autoComplete="off"
						helperText="Only used to create invoice"
						value={vat}
						onChange={(e) => setVat(e.target.value)}
						sx={{ mb: 2 }}
					/>
				) : null}
				<DatePicker
					label="Registration Date"
					inputVariant="outlined"
					required
					inputFormat="DD/MM/YYYY"
					value={registerDate}
					onChange={(date) => setRegisterDate(date)}
					renderInput={params => <TextField {...params} sx={{ mb: 2 }} fullWidth />}
				/>
				<TextField
					variant="outlined"
					required
					fullWidth
					label="ESNcard"
					name="esnCard"
					type="text"
					autoComplete="off"
					value={esnCard}
					onChange={(e) => setEsnCard(e.target.value.trim())}
					sx={{ mb: 2 }}
				/>
				<FormControl
					fullWidth
					sx={{ mb: 2 }}
					variant="outlined"
					required
				>
					<InputLabel style={{ backgroundColor: "#fff" }}>
						Mobility Program
					</InputLabel>
					<Select
						value={program}
						onChange={(e) => setProgram(e.target.value)}
					>
						{mobilityPrograms.map((program) => (
							<MenuItem key={program} value={program}>{program}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl
					fullWidth
					sx={{ mb: 2 }}
					variant="outlined"
					required
				>
					<InputLabel style={{ backgroundColor: "#fff" }}>
						Program Duration
					</InputLabel>
					<Select
						value={programDuration}
						onChange={(e) => setProgramDuration(e.target.value)}
					>
						<MenuItem value={"<1 month"}>{"<1 month"}</MenuItem>
						<MenuItem value={"1-3 months"}>{"1-3 months"}</MenuItem>
						<MenuItem value={"4-6 months (1 Semester)"}>
							{"4-6 months (1 Semester)"}
						</MenuItem>
						<MenuItem value={"7-12 months (1 Year)"}>
							{"7-12 months (1 Year)"}
						</MenuItem>
						<MenuItem value={"1-2 years"}>{"1-2 years"}</MenuItem>
						<MenuItem value={">2 years"}>{">2 years"}</MenuItem>
					</Select>
				</FormControl>
				{regions ? (
					<FormControl
						fullWidth
						sx={{ mb: 2 }}
						variant="outlined"
						required
					>
						<InputLabel style={{ backgroundColor: "#fff" }}>Region</InputLabel>
						<Select
							value={region ?? ""}
							onChange={(e) => {
								setRegion(e.target.value);
								setUniversity("");
							}}
						>
							{Object.keys(regions).map(
								(region) => (
									<MenuItem key={region} value={region}>{region}</MenuItem>
								)
							)}
						</Select>
					</FormControl>
				) : null}
				{props.organization &&
					region != null &&
					regions != null &&
					regions[region] != null &&
					region !== "" ? (
					<FormControl
						fullWidth
						sx={{ mb: 2 }}
						variant="outlined"
						required
					>
						<InputLabel style={{ backgroundColor: "#fff" }}>
							University
						</InputLabel>
						<Select
							value={university}
							onChange={(e) => setUniversity(e.target.value)}
						>
							{regions[region].map(
								(uni) => (
									<MenuItem key={uni} value={uni}>{uni}</MenuItem>
								)
							)}
						</Select>
					</FormControl>
				) : null}
				{customQuestionsForm()}
			</Grid>
		);
	}

	return (
		<Card style={{ padding: 15 }}>
			<CardContent>
				<Grid container spacing={10}>
					{addFormUI()}
					{previewUI()}
				</Grid>
			</CardContent>
		</Card>
	);
}

export default AddStudent;