import {
  Card,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { toast } from "react-toastify";
import config from "../../utils/config";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";

function SharedEvents(props) {
  const [events, setEvents] = useState([]);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuEvent, setMenuEvent] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [confirmationOnYes, setConfirmationOnYes] = useState(null);
  const [confirmationOnClose, setConfirmationOnClose] = useState(null);

  useEffect(() => {
    props.setTitle("Shared Events");
  }, []); // eslint-disable-line

  useEffect(() => {
    fetchEvents();
  }, [props.organization]); // eslint-disable-line

  const fetchEvents = () => {
    axios
      .get(`${config.API_ENDPOINT}/events/shared/${props.organization}/all`)
      .then((res) => setEvents(res.data))
      .catch(() => toast.error("An unexpected error has occurred"));
  };

  const exportEvent = useDebouncedCallback(
    () => {
      axios
        .post(
          `${config.API_ENDPOINT}/events/${props.organization}/${menuEvent?._id}/export`,
          {},
          {
            headers: { "x-access-token": localStorage.getItem("access-token") },
          }
        )
        .then(() => {
          setMenuAnchorEl(null);
          toast.success("You will receive an email with your file soon");
        })
        .catch((err) => {
          switch (err.response?.status) {
            case 401:
              localStorage.removeItem("access-token");
              window.location.replace("/login");
              break;
            default:
              toast.error("An unexpected error has occurred");
              break;
          }
        });
    },
    500,
    { leading: true }
  );

  const showDeleteDialog = (eventId) => {
    setConfirmationMessage("Are you sure you want to delete this event?");
    setConfirmationOnClose(() => () => {
      setConfirmationOpen(false);
      setMenuAnchorEl(null);
    });
    setConfirmationOnYes(() => () => {
      setConfirmationOpen(false);
      setMenuAnchorEl(null);
      deleteEvent(eventId);
    });
    setConfirmationOpen(true);
  };

  const deleteEvent = useDebouncedCallback(
    (eventId) => {
      axios
        .delete(`${config.API_ENDPOINT}/events/shared/${props.organization}/${eventId}`, {
          headers: { "x-access-token": localStorage.getItem("access-token") },
        })
        .then(() => {
          toast.success("Success!");
          fetchEvents();
        })
        .catch((err) => {
          switch (err.response?.status) {
            case 401:
              localStorage.removeItem("access-token");
              window.location.replace("/login");
              break;
            case 404:
              toast.warn("You don't have enough permissions to delete this event");
              setConfirmationOpen(false);
              setMenuAnchorEl(null);
              break;
            default:
              toast.error("An unexpected error has occurred");
              break;
          }
        });
    },
    500,
    { leading: true }
  );

  if (props.user.accessLevel > 3) return null;

  return (
    <div>
      <Card style={{ padding: 15 }}>
        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Picture</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Seats Remaining</TableCell>
                  <TableCell>Active?</TableCell>
                  <TableCell>Settings</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map((event) => (
                  <TableRow key={event._id}>
                    <TableCell>
                      {event.parentEvent.imageURL ? (
                        <img
                          alt=""
                          height={50}
                          width={100}
                          style={{ objectFit: "cover" }}
                          src={`${config.IMG_ENDPOINT}/uploads/${event.parentEvent.imageURL}`}
                        />
                      ) : null}
                    </TableCell>
                    <TableCell>{event.parentEvent.name}</TableCell>
                    <TableCell>{event.seatsRemaining ?? ""}</TableCell>
                    <TableCell>{event.parentEvent.active ? "Yes" : "No"}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(ev) => {
                          setMenuAnchorEl(ev.currentTarget);
                          setMenuEvent(event);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Menu
            anchorEl={menuAnchorEl}
            open={menuAnchorEl != null}
            keepMounted
            onClose={() => setMenuAnchorEl(null)}
          >
            <Link
              to={`/edit-shared-event/${menuEvent?._id}`}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <MenuItem>View/Edit</MenuItem>
            </Link>

            <Link
              to={`/event-purchases/${menuEvent?._id}`}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <MenuItem>View Purchases</MenuItem>
            </Link>

            {menuEvent?.parentEvent.wishlistEnabled && (
              <Link
                to={`/wishlist/${menuEvent?._id}`}
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                <MenuItem>View Wishlist</MenuItem>
              </Link>
            )}

            <MenuItem onClick={exportEvent}>Export Participants</MenuItem>

            <MenuItem onClick={() => showDeleteDialog(menuEvent?._id)}>Delete</MenuItem>
          </Menu>
        </CardContent>
      </Card>
      <ConfirmationDialog
        open={confirmationOpen}
        message={confirmationMessage}
        onYes={confirmationOnYes}
        onClose={confirmationOnClose}
      />
    </div>
  );
}

export default SharedEvents;
