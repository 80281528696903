import { Card } from "@mui/material";
import parse from "html-react-parser";
import config from "../../utils/config";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

function TermsConditions(props) {
	const { organizationId } = useParams();

	const [terms, setTerms] = useState("");

	useEffect(() => {
		getOrganization(organizationId);
	}, []); //eslint-disable-line

	const getOrganization = (orgId) => {
		axios
			.get(`${config.API_ENDPOINT}/organizations/${orgId}`)
			.then((res) => {
				let org = res.data;
				setTerms(org.termsConditions);
			})
			.catch(() => {
				toast.error("An unexpected error has occurred");
			});
	};

	return <Card style={{ margin: 20, padding: 20 }}>{parse(terms)}</Card>;
}

export default TermsConditions;
