import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Button,
} from "@mui/material";
import config from "../../utils/config";
import { toast } from "react-toastify";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";
import { useState } from "react";

function ChangePasswordDialog(props) {
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");

	const close = () => {
		setCurrentPassword("");
		setNewPassword("");
		props.onClose();
	}

	const onConfirm = useDebouncedCallback(() => {
		if (newPassword === "" || currentPassword === "") {
			toast.warning("You must fill both fields");
			return;
		}

		axios
			.post(`${config.API_ENDPOINT}/users/me/change-password`, { currentPassword, newPassword }, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(() => {
				toast.success("Success!");
				close();
			})
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					case 403:
						toast.warning("Invalid current password");
						break;
					case 400:
						toast.warning("New password is too short");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}, 500, { leading: true });

	return (
		<Dialog
			open={props.open}
			onClose={close}
			aria-labelledby="alert-dialog-title"
		>
			<DialogTitle id="alert-dialog-title">Change Password</DialogTitle>
			<DialogContent>
				<TextField
					variant="outlined"
					required
					fullWidth
					label="Current Password"
					name="currentPassword"
					type="password"
					autoComplete="off"
					value={currentPassword}
					onChange={(e) => setCurrentPassword(e.target.value)}
					sx={{ mb: 2, mt: 1 }}
				/>
				<TextField
					variant="outlined"
					required
					fullWidth
					label="New Password"
					name="newPassword"
					type="password"
					autoComplete="off"
					value={newPassword}
					onChange={(e) => setNewPassword(e.target.value)}
					sx={{ mb: 2 }}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={close} color="primary">
					Cancel
				</Button>
				<Button onClick={onConfirm} color="primary" autoFocus>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ChangePasswordDialog;