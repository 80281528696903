import { Box, Button, Card, CardContent, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import config from '../../utils/config';
import { useNavigate, useParams } from 'react-router-dom';
import headers from '../../utils/headers';
import { toast } from 'react-toastify';
import moment from 'moment';

export default function StudentProfile(props) {
    const { studentId } = useParams();
    const navigate = useNavigate();

    const [studentProfile, setStudentProfile] = useState(null);
    const [organization, setOrganization] = useState(null);

    useEffect(() => {
        props.setTitle("Student Profile");
        fetchStudent();
    }, []); // eslint-disable-line

    useEffect(() => {
        fetchOrganization();
    }, [props.organization]); //eslint-disable-line

    const fetchOrganization = () => {
        axios
            .get(`${config.API_ENDPOINT}/organizations/${props.organization}`, { headers: { "x-access-token": localStorage.getItem("access-token") } })
            .then(res => {
                setOrganization(res.data);
            })
            .catch(err => {
                switch (err.response?.status) {
                    case 401:
                        localStorage.removeItem("access-token");
                        window.location.replace("/login");
                        break;
                    default:
                        toast.error("An unexpected error has occurred");
                        break;
                }
            });
    }

    const fetchStudent = () => {
        axios
            .get(`${config.API_ENDPOINT}/students/${props.organization}/${studentId}/profile`, headers())
            .then((res) => setStudentProfile(res.data))
            .catch((err) => {
                switch (err.response?.status) {
                    case 401:
                        localStorage.removeItem("access-token");
                        window.location.replace("/login");
                        break;
                    default:
                        toast.error("An unexpected error has occurred");
                        break;
                }
            });
    };

    const getStudentInfo = () => {
        return <Box sx={{ mb: 2 }}>
            <Typography sx={{ mb: 2, fontWeight: "bold", fontSize: 38 }}>{studentProfile.student.name}</Typography>
            <Typography sx={{ mt: 1 }}>
                <strong>Gender: </strong>
                {studentProfile.student.gender === "male"
                    ? "Male"
                    : studentProfile.student.gender === "female"
                        ? "Female"
                        : studentProfile.student.gender === "other"
                            ? "Other"
                            : ""}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                <strong>ID/Passport: </strong>
                {studentProfile.student.passport}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                <strong>Email: </strong>
                {studentProfile.student.email}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                <strong>Phone Number: </strong>
                {studentProfile.student.phone}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                <strong>Birthdate: </strong>
                {studentProfile.student.birthdate
                    ? moment(studentProfile.student.birthdate).format("DD/MM/YYYY")
                    : ""}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                <strong>Address: </strong>
                {studentProfile.student.address}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                <strong>Nationality: </strong>
                {studentProfile.student.country}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                <strong>ESNcard: </strong>
                {studentProfile.student.esnCard}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                <strong>University: </strong>
                {studentProfile.student.university}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                <strong>Erasmus Program: </strong>
                {studentProfile.student.program}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                <strong>Erasmus Program Duration: </strong>
                {studentProfile.student.programDuration}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                <strong>Registration Date: </strong>
                {studentProfile.student.registerDate
                    ? moment(studentProfile.student.registerDate).format("DD/MM/YYYY")
                    : ""}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                <strong>Photos: </strong>
                {studentProfile.student.photos.toString()}
            </Typography>
            <Typography sx={{ mt: 1 }}>
                <strong>Newsletter: </strong>
                {studentProfile.student.newsletter.toString()}
            </Typography>
            {getCustomQuestions()}
        </Box>;
    };

    const getCustomQuestions = () => {
        if (organization == null) return null;
        if (organization.customEsnCardQuestions == null) return null;
        const cq = studentProfile.student.customQuestions ?? {};

        return <>
            {organization.customEsnCardQuestions.map(q => <Typography key={q.id} sx={{ mt: 1 }}>
                <strong>{q.question} </strong>
                {getQuestionValue(cq[q.id], q.type)}
            </Typography>)}
        </>;
    };

    const getQuestionValue = (value, type) => {
        if (value == null || value === "") return "";

        switch (type) {
            case "select-multi":
                return value.join(', ');
            case "datetime":
                return moment(value).format("DD-MM-YYYY HH:mm");
            default:
                return value;
        }
    }

    const getEventsInfo = () => {
        return <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Card sx={{ p: 3 }}>
                    <CardContent>
                        <Typography sx={{ fontWeight: "bold", fontSize: 20, textAlign: "center" }}>
                            Events Purchased
                        </Typography>
                        <Typography
                            sx={{ fontWeight: "bold", fontSize: 50 }}
                            color="primary"
                            align="center"
                        >
                            {studentProfile.events.purchased}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card sx={{ p: 3 }}>
                    <CardContent>
                        <Typography sx={{ fontWeight: "bold", fontSize: 20, textAlign: "center" }}>
                            Events Pending
                        </Typography>
                        <Typography
                            sx={{ fontWeight: "bold", fontSize: 50 }}
                            color="primary"
                            align="center"
                        >
                            {studentProfile.events.pending}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" sx={{ display: "block", m: "0 auto", mt: 2 }} onClick={() => navigate(`/event-purchases?esnCard=${studentProfile.student.esnCard}`)}>
                    View All
                </Button>
            </Grid>
        </Grid>;
    };

    if (studentProfile == null) {
        return <Box textAlign="center">
            <CircularProgress />
        </Box>;
    }

    return (
        <Card style={{ padding: 15 }}>
            <CardContent>
                {getStudentInfo()}
                <Divider sx={{ mb: 2 }} />
                {getEventsInfo()}
            </CardContent>
        </Card>
    )
}
