import {
	Card,
	CardContent,
	OutlinedInput,
	IconButton,
	InputAdornment,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Menu,
	MenuItem,
	Button,
	TablePagination,
	FormControlLabel,
	Switch,
	TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import config from "../../utils/config";
import { toast } from "react-toastify";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import universities from "../../utils/universities";
import axios from "axios";

const numRows = 10;

function StudentRequests(props) {
	const [studentRequests, setStudentRequests] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [searchPaid, setSearchPaid] = useState(false);
	const [searchDate, setSearchDate] = useState(null);
	const [menuAnchorEl, setMenuAnchorEl] = useState(null);
	const [menuRequest, setMenuRequest] = useState(null);
	const [menuPaid, setMenuPaid] = useState(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [confirmationOnYes, setConfirmationOnYes] = useState(null);
	const [confirmationOnClose, setConfirmationOnClose] = useState(null);
	const [page, setPage] = useState(0);
	const [totalRows, setTotalRows] = useState(0);
	const [lastPage, setLastPage] = useState(0);

	useEffect(() => {
		props.setTitle("Student Requests");
	}, []); // eslint-disable-line

	useEffect(() => {
		setStudentRequests([]);
		setPage(0);
		setLastPage(0);
		setSearchText("");
		fetchStudentRequests(0);
	}, [props.organization]); // eslint-disable-line

	useEffect(() => {
		onSearchTextPaidDateChanged();
	}, [searchText, searchPaid, searchDate]); // eslint-disable-line

	const onSearchTextPaidDateChanged = useDebouncedCallback(() => { // eslint-disable-line
		setPage(0);
		setLastPage(0);
		fetchStudentRequests(0);
	}, 500);

	const handlePageChange = (_, newPage) => {
		if (newPage > lastPage) {
			fetchStudentRequests(newPage);
			setLastPage(newPage);
		}

		setPage(newPage);
	}

	const exportStudentRequests = useDebouncedCallback(() => {
		axios
			.post(
				`${config.API_ENDPOINT}/student-requests/${props.organization}/export`,
				{},
				{ headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(() => toast.success("You will receive an email with your file soon"))
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}, 500, { leading: true });

	const findEsnCountry = (organizationId) => {
		for (let esnCountry of Object.keys(universities)) {
			if (Object.keys(universities[esnCountry]).includes(organizationId)) {
				return esnCountry;
			}
		}

		return null;
	}

	const copyIframe = async () => {
		let text = `<iframe frameborder="0" scrolling="yes" src="${config.WEB_ENDPOINT}/iframes/student-request/${findEsnCountry(props.organization)}" style=" min-height: 700px; overflow-y: scroll; width: 100%; "></iframe>`;
		await navigator.clipboard.writeText(text);
		toast.success("Copied!");
	}

	const fetchNumStudentRequests = () => {
		var q = `${config.API_ENDPOINT}/student-requests/${props.organization}/count`;

		if (searchText !== "") {
			q += `?search=${searchText}`;
		}

		if (searchDate) {
			if (q.includes("?")) {
				q += '&';
			} else {
				q += '?';
			}

			q += `searchDate=${searchDate.startOf("day").valueOf()}`;
		}

		if (searchPaid) {
			if (q.includes("?")) {
				q += '&';
			} else {
				q += '?';
			}

			q += "paid=true";
		}

		axios
			.get(q, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(res => setTotalRows(res.data.count))
			.catch(() => toast.error("An unexpected error has occurred"));
	};

	const fetchStudentRequests = useDebouncedCallback((page) => {
		fetchNumStudentRequests();

		let q = `${config.API_ENDPOINT}/student-requests/${props.organization}/all?page=${page}&numRows=${numRows}`;

		if (searchPaid) {
			q += "&paid=true";
		}

		if (searchText !== "") {
			q += "&search=" + searchText;
		}

		if (searchDate) {
			q += "&searchDate=" + searchDate.startOf("day").valueOf();
		}

		axios
			.get(q, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(res => {
				if (page === 0) {
					setStudentRequests(res.data);
				} else {
					setStudentRequests([...studentRequests, ...res.data]);
				}
			})
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}, 500);

	const deleteRequest = useDebouncedCallback((requestId) => {
		axios
			.delete(`${config.API_ENDPOINT}/student-requests/${props.organization}/${requestId}`, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(() => {
				toast.success("Success!");
				setConfirmationOpen(false);
				setMenuAnchorEl(null);
				setPage(0);
				setLastPage(0);
				fetchStudentRequests(0);
			})
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					case 404:
						toast.warn("You don't have enough permissions to delete this request");
						setConfirmationOpen(false);
						setMenuAnchorEl(null);
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}, 500, { leading: true });

	const showDeleteDialog = (requestId) => {
		setConfirmationMessage("Are you sure you want to delete this student request?");
		setConfirmationOnClose(() => () => {
			setConfirmationOpen(false);
			setMenuAnchorEl(null);
		});
		setConfirmationOnYes(() => () => {
			setConfirmationOpen(false);
			setMenuAnchorEl(null);
			deleteRequest(requestId);
		});
		setConfirmationOpen(true);
	}

	return (
		<div>
			<Card style={{ padding: 15 }}>
				<Button
					onClick={copyIframe}
					style={{ marginBottom: 20, float: "right" }}
				>
					Copy iframe
				</Button>
				<Button
					onClick={exportStudentRequests}
					style={{ marginBottom: 5, float: "right" }}
				>
					Export Requests
				</Button>
				<CardContent>
					<DatePicker
						label="Search by date"
						inputVariant="outlined"
						required
						inputFormat="DD/MM/YYYY"
						value={searchDate}
						onChange={(date) => setSearchDate(date)}
						renderInput={(params) => <TextField {...params} sx={{ mb: 3 }} />}
					/>
					{searchDate ? (
						<IconButton
							style={{ marginLeft: 10 }}
							onClick={() => setSearchDate(null)}
						>
							<ClearIcon />
						</IconButton>
					) : null}
					<FormControlLabel
						sx={{ display: "block", mb: 2 }}
						control={
							<Switch
								checked={searchPaid}
								onChange={ev => setSearchPaid(ev.target.checked)}
							/>
						}
						label="Paid only"
					/>
					<OutlinedInput
						required
						fullWidth
						name="search"
						type="text"
						value={searchText}
						onChange={(ev) => setSearchText(ev.target.value)}
						placeholder="Search by Name/Passport"
						endAdornment={
							<InputAdornment position="end">
								{searchText === "" ? null : (
									<IconButton
										onClick={() => setSearchText("")}
									>
										<ClearIcon />
									</IconButton>
								)}
							</InputAdornment>
						}
						style={{ marginBottom: 50 }}
					/>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Date/Time</TableCell>
									<TableCell>Full Name</TableCell>
									<TableCell>Email</TableCell>
									<TableCell>VAT</TableCell>
									<TableCell>Nationality</TableCell>
									<TableCell>University</TableCell>
									<TableCell>Paid?</TableCell>
									<TableCell>Settings</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{studentRequests
									.slice(
										page * numRows,
										page * numRows + numRows
									)
									.map((request) => (
										<TableRow key={request._id}>
											<TableCell>
												{moment(request.timestamp).format("HH:mm DD/MM/YYYY")}
											</TableCell>
											<TableCell>{request.name}</TableCell>
											<TableCell>{request.email}</TableCell>
											<TableCell>{request.vat}</TableCell>
											<TableCell>{request.country}</TableCell>
											<TableCell>{request.university}</TableCell>
											<TableCell>{request.paid ? "Yes" : "No"}</TableCell>
											<TableCell>
												<IconButton
													onClick={(ev) => {
														setMenuAnchorEl(ev.currentTarget);
														setMenuRequest(request._id);
														setMenuPaid(request.paid);
													}}
												>
													<MoreVertIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
						<TablePagination
							rowsPerPageOptions={[numRows]}
							rowsPerPage={numRows}
							component="div"
							count={totalRows}
							page={page}
							onPageChange={handlePageChange}
						/>
					</TableContainer>
					<Menu
						anchorEl={menuAnchorEl}
						open={menuAnchorEl != null}
						keepMounted
						onClose={() => setMenuAnchorEl(null)}
					>
						<Link
							to={`/finish-request/${menuRequest}`}
							style={{ color: "inherit", textDecoration: "inherit" }}
						>
							<MenuItem>Complete Request</MenuItem>
						</Link>
						{!menuPaid ? (
							<MenuItem
								onClick={() => showDeleteDialog(menuRequest)}
							>
								Delete
							</MenuItem>
						) : null}
					</Menu>
				</CardContent>
			</Card>
			<ConfirmationDialog
				open={confirmationOpen}
				message={confirmationMessage}
				onYes={confirmationOnYes}
				onClose={confirmationOnClose}
			/>
		</div>
	);
}

export default StudentRequests;