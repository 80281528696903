import {
  Card,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  Button,
  TablePagination,
} from "@mui/material";
import config from "../../utils/config";
import { toast } from "react-toastify";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClearIcon from "@mui/icons-material/Clear";
import { Link, useNavigate } from "react-router-dom";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import EgmImportDialog from "../EgmImportDialog/EgmImportDialog";
import EgmExportDialog from "../EgmExportDialog/EgmExportDialog";
import { useState, useEffect } from "react";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";

const numRows = 10;

function Events(props) {
  let navigate = useNavigate();

  const [events, setEvents] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuEvent, setMenuEvent] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [confirmationOnYes, setConfirmationOnYes] = useState(null);
  const [confirmationOnClose, setConfirmationOnClose] = useState(null);
  const [egmImportOpen, setEgmImportOpen] = useState(false);
  const [egmExportOpen, setEgmExportOpen] = useState(false);
  const [egmEvent, setEgmEvent] = useState(null);
  const [egmOnClose, setEgmOnClose] = useState(null);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [lastPage, setLastPage] = useState(0);

  useEffect(() => {
    props.setTitle("Events");
  }, []); // eslint-disable-line

  useEffect(() => {
    setPage(0);
    setLastPage(0);
    fetchEvents(0);
  }, [props.organization]); // eslint-disable-line

  useEffect(() => {
    onSearchTextChanged();
  }, [searchText]); // eslint-disable-line

  const onSearchTextChanged = useDebouncedCallback(() => {
    setPage(0);
    setLastPage(0);
    fetchEvents(0);
  }, 500);

  const handlePageChange = (_, newPage) => {
    if (newPage > lastPage) {
      fetchEvents(newPage);
      setLastPage(newPage);
    }

    setPage(newPage);
  };

  const sendReminder = useDebouncedCallback(
    () => {
      if (menuEvent == null) return;

      axios
        .post(
          `${config.API_ENDPOINT}/events/${props.organization}/${menuEvent._id}/reminder`,
          { endpoint: config.WEB_ENDPOINT },
          { headers: { "x-access-token": localStorage.getItem("access-token") } }
        )
        .then(() => toast.success("Success!"))
        .catch((err) => {
          switch (err.response?.status) {
            case 401:
              localStorage.removeItem("access-token");
              window.location.replace("/login");
              break;
            default:
              toast.error("An unexpected error has occurred");
              break;
          }
        });
    },
    500,
    { leading: true }
  );

  const sendMail = () => {
    if (menuEvent == null) return;

    axios
      .get(`${config.API_ENDPOINT}/events/${props.organization}/${menuEvent._id}/emails`, {
        headers: { "x-access-token": localStorage.getItem("access-token") },
      })
      .then((res) => {
        if (res.data != null && res.data.length > 0) {
          navigate("/send-email", { state: { emails: res.data } });
        }
      })
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  };

  const fetchNumEvents = () => {
    let q = `${config.API_ENDPOINT}/events/${props.organization}/count`;

    if (searchText !== "") {
      q += `?search=${searchText}`;
    }

    axios
      .get(q)
      .then((res) => setTotalRows(res.data.count))
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  };

  const fetchEvents = useDebouncedCallback((page) => {
    fetchNumEvents();

    let q = `${config.API_ENDPOINT}/events/${props.organization}/all?page=${page}&numRows=${numRows}`;

    if (searchText !== "") {
      q += `&search=${searchText}`;
    }

    axios
      .get(q, { headers: { "x-access-token": localStorage.getItem("access-token") } })
      .then((res) => {
        if (page === 0) {
          setEvents(res.data);
        } else {
          setEvents([...events, ...res.data]);
        }
      })
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  }, 500);

  const showDeleteDialog = (eventId) => {
    setConfirmationMessage("Are you sure you want to delete this event?");
    setConfirmationOnClose(() => () => {
      setConfirmationOpen(false);
      setMenuAnchorEl(null);
    });
    setConfirmationOnYes(() => () => {
      setConfirmationOpen(false);
      setMenuAnchorEl(null);
      deleteEvent(eventId);
    });
    setConfirmationOpen(true);
  };

  const showEgmImportDialog = (eventId) => {
    setEgmEvent(eventId);
    setEgmOnClose(() => () => {
      setMenuAnchorEl(null);
      setEgmImportOpen(false);
    });
    setEgmImportOpen(true);
  };

  const showEgmExportDialog = (eventId) => {
    setEgmEvent(eventId);
    setEgmOnClose(() => () => {
      setMenuAnchorEl(null);
      setEgmExportOpen(false);
    });
    setEgmExportOpen(true);
  };

  const copyIframe = async () => {
    let text = `<iframe frameborder="0" scrolling="yes" src="${config.WEB_ENDPOINT}/iframes/events/${props.organization}" style=" min-height: 700px; overflow-y: scroll; width: 100%; "></iframe>`;
    await navigator.clipboard.writeText(text);
    toast.success("Copied!");
  };

  const deleteEvent = useDebouncedCallback(
    (eventId) => {
      axios
        .delete(`${config.API_ENDPOINT}/events/${props.organization}/${eventId}`, {
          headers: { "x-access-token": localStorage.getItem("access-token") },
        })
        .then((res) => {
          toast.success("Success!");
          setMenuAnchorEl(null);
          setPage(0);
          setLastPage(0);
          setConfirmationOpen(false);
          fetchEvents(0);
        })
        .catch((err) => {
          switch (err.response?.status) {
            case 401:
              localStorage.removeItem("access-token");
              window.location.replace("/login");
              break;
            case 404:
              toast.warn("You don't have enough permissions to delete this event");
              setConfirmationOpen(false);
              setMenuAnchorEl(null);
              break;
            case 409:
              toast.warn(
                "To delete a shared event, all organizations that joined it must delete first"
              );
              setConfirmationOpen(false);
              setMenuAnchorEl(null);
              break;
            default:
              toast.error("An unexpected error has occurred");
              break;
          }
        });
    },
    500,
    { leading: true }
  );

  const exportEvent = useDebouncedCallback(
    () => {
      if (menuEvent == null) return;

      let q = `${config.API_ENDPOINT}/events/${props.organization}/${menuEvent._id}/export`;

      if (menuEvent.shared) {
        q += "?shared=true";
      }

      axios
        .post(q, {}, { headers: { "x-access-token": localStorage.getItem("access-token") } })
        .then(() => {
          toast.success("You will receive an email with your file soon");
          setMenuAnchorEl(null);
        })
        .catch((err) => {
          switch (err.response?.status) {
            case 401:
              localStorage.removeItem("access-token");
              window.location.replace("/login");
              break;
            default:
              toast.error("An unexpected error has occurred");
              break;
          }
        });
    },
    500,
    { leading: true }
  );

  if (props.user.accessLevel > 3) return null;

  return (
    <div>
      <Card style={{ padding: 15 }}>
        <CardContent>
          <Button onClick={copyIframe} style={{ marginBottom: 20, float: "right" }}>
            Copy iframe
          </Button>
          <OutlinedInput
            required
            fullWidth
            name="search"
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search by name"
            endAdornment={
              <InputAdornment position="end">
                {searchText === "" ? null : (
                  <IconButton onClick={() => setSearchText("")}>
                    <ClearIcon />
                  </IconButton>
                )}
              </InputAdornment>
            }
            style={{ marginBottom: 50 }}
          />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Picture</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Seats Remaining</TableCell>
                  <TableCell>Active?</TableCell>
                  <TableCell>Settings</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.slice(page * numRows, page * numRows + numRows).map((event) => (
                  <TableRow key={event._id}>
                    <TableCell>
                      {event.imageURL ? (
                        <img
                          alt=""
                          height={50}
                          width={100}
                          style={{ objectFit: "cover" }}
                          src={`${config.IMG_ENDPOINT}/uploads/${event.imageURL}`}
                        />
                      ) : null}
                    </TableCell>
                    <TableCell>{event.name}</TableCell>
                    <TableCell>{event.createdBy}</TableCell>
                    <TableCell>
                      {event.shared ? `${event.seatsRemaining ?? ""} (Shared)` : null}
                      {!event.shared && event.seats != null ? event.seatsRemaining ?? "" : null}
                    </TableCell>
                    <TableCell>{event.active ? "Yes" : "No"}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(ev) => {
                          setMenuAnchorEl(ev.currentTarget);
                          setMenuEvent(event);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[numRows]}
              rowsPerPage={numRows}
              component="div"
              count={totalRows}
              page={page}
              onPageChange={handlePageChange}
            />
          </TableContainer>
          <Menu
            anchorEl={menuAnchorEl}
            open={menuAnchorEl != null}
            keepMounted
            onClose={() => setMenuAnchorEl(null)}
          >
            <Link
              to={`/edit-event/${menuEvent?._id}`}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <MenuItem>View/Edit</MenuItem>
            </Link>
            <Link
              to={`/event-purchases/${menuEvent?._id}`}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <MenuItem>View Purchases</MenuItem>
            </Link>
            {menuEvent?.wishlistEnabled && (
              <Link
                to={`/wishlist/${menuEvent?._id}`}
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                <MenuItem>View Wishlist</MenuItem>
              </Link>
            )}
            <Link
              to={`/clone-event/${menuEvent?._id}`}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <MenuItem>Clone</MenuItem>
            </Link>

            <MenuItem onClick={exportEvent}>Export</MenuItem>

            {menuEvent?.shared && [
              <MenuItem key="n1" onClick={sendReminder}>
                Send Reminder
              </MenuItem>,
              <MenuItem key="n2" onClick={sendMail}>
                Send Email To Participants
              </MenuItem>,
              <MenuItem key="n3" onClick={() => showEgmImportDialog(menuEvent?._id)}>
                Import from ERS
              </MenuItem>,
              <MenuItem key="n4" onClick={() => showEgmExportDialog(menuEvent?._id)}>
                Export to ERS
              </MenuItem>,
            ]}

            <MenuItem onClick={() => showDeleteDialog(menuEvent?._id)}>Delete</MenuItem>
          </Menu>
        </CardContent>
      </Card>
      <EgmImportDialog
        open={egmImportOpen}
        event={egmEvent}
        organization={props.organization}
        onClose={egmOnClose}
      />
      <EgmExportDialog
        open={egmExportOpen}
        event={egmEvent}
        organization={props.organization}
        onClose={egmOnClose}
      />
      <ConfirmationDialog
        open={confirmationOpen}
        message={confirmationMessage}
        onYes={confirmationOnYes}
        onClose={confirmationOnClose}
      />
    </div>
  );
}

export default Events;
