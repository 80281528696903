import { Box, Button, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import QrReader from './QrReader';
import QrCodeIcon from '@mui/icons-material/QrCode';
import axios from "axios";
import config from "../../utils/config";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

function CheckIn({ setTitle, organization }) {
    const navigate = useNavigate();

    const [readerOpen, setReaderOpen] = useState(false);
    const [purchase, setPurchase] = useState(null);

    useEffect(() => {
        setTitle("Check-in");
    }, []); // eslint-disable-line

    const handleScan = (result) => {
        let text = result.data;

        axios.post(`${config.API_ENDPOINT}/event-purchases/${organization}/${text}/checkin`, {}, { headers: { "x-access-token": localStorage.getItem("access-token") } })
            .then(res => {
                setPurchase(res.data);
                toast.success("Ticket checked-in successfully!");
            })
            .catch(err => {
                switch (err.response?.status) {
                    case 401:
                        localStorage.removeItem("access-token");
                        window.location.replace("/login");
                        break;
                    case 404:
                        toast.error("Invalid QR Code");
                        break;
                    case 409:
                        toast.warn("Ticket already checked-in!");
                        setPurchase(err.response.data);
                        break;
                    default:
                        toast.error("An unexpected error has occurred");
                        break;
                }
            });
    };

    const getContent = () => {
        if (purchase == null) {
            return <Typography>Open the scanner to start</Typography>
        }

        return <Box>
            <Typography>
                <strong>Event:</strong> {purchase.eventName}
            </Typography>
            <Typography>
                <strong>Name:</strong> {purchase.name}
            </Typography>
            {purchase.esnCard ? (
                <Typography>
                    <strong>ESNcard:</strong> {purchase.esnCard}
                </Typography>
            ) : null}
            <Typography>
                <strong>ID/Passport:</strong> {purchase.passport}
            </Typography>
            <Typography>
                <strong>Checked-in at: </strong> {moment(purchase.checkedInAt).format("DD/MM/YYYY HH:mm")}
            </Typography>
            <Typography>
                <strong>Checked-in by: </strong> {purchase.checkedInBy?.name}
            </Typography>
            <Button sx={{ mt: 3 }} onClick={() => navigate(`/event-purchase-details/${purchase._id}`)}>
                View Details
            </Button>
        </Box>;
    }

    return (
        <Container sx={{ textAlign: "center" }}>
            {getContent()}

            <Button sx={{ mt: 3 }} variant="contained" startIcon={<QrCodeIcon />} onClick={() => setReaderOpen(true)}>Open Scanner</Button>

            <QrReader open={readerOpen} onClose={() => setReaderOpen(false)} onResult={handleScan} />
        </Container>
    )
}

export default CheckIn;