import {
  CardContent,
  Card,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { stateFromHTML } from "draft-js-import-html";
import config from "../../utils/config";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import { stateToHTML } from "draft-js-export-html";
import { Editor } from "react-draft-wysiwyg";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";
import { useState, useEffect } from "react";
import { EditorState } from "draft-js";

const info = {
  1: ["Use %PASSWORD% to display the user's password."],
  2: [
    "Use %ESNCARD% to display the ESNcard",
    "Use %DISCOUNT% to display information about events with special discount enabled.",
  ],
  4: [
    "Use %EVENT% to display the name of the event.",
    "Use %CUSTOM% to display the content of the custom section.",
    "Use %EXTERNAL% to display the external payment information.",
    "Use %LINK% to display the link for paying online.",
  ],
  5: [
    "Use %EVENT% to display the name of the event.",
    "Use %CUSTOM% to display the content of the custom section.",
  ],
  7: [
    "Use %EVENT% to display the event name.",
    "Use %LINK% to display the link for paying online.",
  ],
  8: ["Use %EVENT% to display the event name."],
};

function EmailSettings(props) {
  const [emailType, setEmailType] = useState("");
  const [subject, setSubject] = useState("Default Subject");
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(stateFromHTML("<p>Default Content</p>"))
  );

  useEffect(() => {
    props.setTitle("Email Settings");
  }, []); // eslint-disable-line

  useEffect(() => {
    setEmailType("");
    setSubject("Default Subject");
    setEditorState(EditorState.createWithContent(stateFromHTML("<p>Default Content</p>")));
  }, [props.organization]); // eslint-disable-line

  useEffect(() => {
    fetchEmailTemplate();
  }, [emailType]); // eslint-disable-line

  const update = useDebouncedCallback(
    () => {
      let content = stateToHTML(editorState.getCurrentContent());

      if (subject === "" || content === "") {
        toast.warning("The subject and content of the email cannot be empty");
        return;
      }

      axios
        .post(
          `${config.API_ENDPOINT}/email-templates/template/${props.organization}/${emailType}`,
          { subject, content },
          { headers: { "x-access-token": localStorage.getItem("access-token") } }
        )
        .then(() => toast.success("Success!"))
        .catch((err) => {
          switch (err.response?.status) {
            case 401:
              localStorage.removeItem("access-token");
              window.location.replace("/login");
              break;
            default:
              toast.error("An unexpected error has occurred");
              break;
          }
        });
    },
    500,
    { leading: true }
  );

  const fetchEmailTemplate = () => {
    if (emailType === "") return;

    axios
      .get(`${config.API_ENDPOINT}/email-templates/template/${props.organization}/${emailType}`, {
        headers: { "x-access-token": localStorage.getItem("access-token") },
      })
      .then((res) => {
        setSubject(res.data.subject);
        setEditorState(EditorState.createWithContent(stateFromHTML(res.data.content)));
      })
      .catch((err) => {
        switch (err.response?.status) {
          case 404:
            setSubject("Default Subject");
            setEditorState(EditorState.createWithContent(stateFromHTML("<p>Default Content</p>")));
            break;
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  };

  if (props.user.accessLevel > 1) return null;

  return (
    <Card style={{ padding: 15 }}>
      <CardContent>
        <FormControl style={{ marginBottom: 20 }} fullWidth>
          <InputLabel id="emailtype-label">Email Type</InputLabel>
          <Select
            label="Email Type"
            labelId="emailtype-label"
            value={emailType}
            onChange={(e) => setEmailType(e.target.value)}
          >
            <MenuItem value={1}>New User</MenuItem>
            <MenuItem value={2}>New Student</MenuItem>
            <MenuItem value={3}>New ESNcard Request</MenuItem>
            <MenuItem value={6}>New ESNcard Request (PAID)</MenuItem>
            <MenuItem value={4}>New Event Purchase Request</MenuItem>
            <MenuItem value={5}>Event Ticket</MenuItem>
            <MenuItem value={7}>Event Purchase Reminder</MenuItem>
            <MenuItem value={8}>New Wishlist Entry</MenuItem>
          </Select>
        </FormControl>
        {emailType ? (
          <>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Subject"
              name="subject"
              autoFocus
              type="text"
              autoComplete="off"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              style={{ marginBottom: 20 }}
            />
            {info[emailType] ? (
              <>
                <Typography style={{ marginTop: 20 }}>
                  <strong>Variables:</strong>
                </Typography>
                {info[emailType].map((val) => (
                  <Typography key={val}>
                    <i>{val}</i>
                  </Typography>
                ))}
              </>
            ) : null}
            <Card>
              <CardContent sx={{ padding: 5 }}>
                <Editor
                  editorStyle={{ minHeight: 200 }}
                  editorState={editorState}
                  label="Content..."
                  onEditorStateChange={(s) => setEditorState(s)}
                />
              </CardContent>
            </Card>
            <Typography sx={{ mt: 3, mb: 3 }}>
              <strong>Preview</strong>
            </Typography>
            <Typography>Subject: {subject}</Typography>
            {parse(stateToHTML(editorState.getCurrentContent()))}
            <Button
              style={{ marginTop: 50, color: "white" }}
              variant="contained"
              color="primary"
              onClick={update}
            >
              UPDATE
            </Button>
          </>
        ) : null}
      </CardContent>
    </Card>
  );
}

export default EmailSettings;
