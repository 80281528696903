import {
	Dialog,
	DialogContent,
	DialogTitle,
	TextField,
	Button,
} from "@mui/material";
import moment from "moment";
import { toast } from "react-toastify";
import config from "../../utils/config";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useState } from "react";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";

function AddTransaction(props) {
	const [value, setValue] = useState(0);
	const [description, setDescription] = useState("");
	const [datetime, setDatetime] = useState(moment());

	const close = () => {
		setValue(0);
		setDescription("");
		setDatetime(moment());

		props.onClose();
	}

	const onConfirm = useDebouncedCallback(() => {
		if (
			!value ||
			!description ||
			description === ""
		) {
			return;
		}

		let transaction = {
			type: "manual",
			description: description,
			value: value,
			organization: props.organization,
			datetime: datetime.valueOf(),
			userId: props.user,
		};

		axios
			.post(`${config.API_ENDPOINT}/transactions/create`, transaction, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(() => window.location.reload())
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}, 500, { leading: true });

	return (
		<Dialog
			open={props.open}
			onClose={close}
			aria-labelledby="alert-dialog-title"
			maxWidth="lg"
		>
			<DialogTitle id="alert-dialog-title">Create Transaction</DialogTitle>
			<DialogContent>
				<DateTimePicker
					label="Date and Time"
					inputVariant="outlined"
					renderInput={(params) => <TextField fullWidth {...params} sx={{ mt: 3 }} />}
					required
					value={datetime}
					ampm={false}
					onChange={(date) => setDatetime(date)}
				/>
				<TextField
					variant="outlined"
					fullWidth
					label="Value"
					name="value"
					type="number"
					value={value}
					required
					onChange={(e) => setValue(e.target.value !== "" ? parseFloat(e.target.value) : null)}
					inputProps={{ step: "0.01" }}
					sx={{ mt: 3 }}
				/>
				<TextField
					variant="outlined"
					fullWidth
					label="Description"
					name="description"
					type="text"
					value={description}
					required
					onChange={(e) => setDescription(e.target.value)}
					sx={{ mt: 3 }}
				/>
				<Button
					variant="contained"
					color="primary"
					sx={{ mt: 3 }}
					onClick={onConfirm}
				>
					CONFIRM
				</Button>
			</DialogContent>
		</Dialog>
	);
}

export default AddTransaction;