import { CircularProgress, Container, Grid, Typography } from "@mui/material";
import config from "../../utils/config";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useSearchParams } from "react-router-dom";

function PurchaseInfo() {
  const { organizationId, type } = useParams();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [statusText, setStatusText] = useState("Getting payment information...");
  const [paymentId, setPaymentId] = useState(null);

  useEffect(() => {
    // Stripe query params
    const sessionId = searchParams.get("sessionId");

    // Redsys query params
    const signature = searchParams.get("Ds_Signature");
    const signatureVer = searchParams.get("Ds_SignatureVersion");
    const merchantParameters = searchParams.get("Ds_MerchantParameters");

    if (sessionId != null) {
      getStripePaymentInfo(organizationId, sessionId);
    } else if (signature != null && signatureVer != null && merchantParameters != null) {
      getRedsysPaymentInfo(organizationId, signature, signatureVer, merchantParameters);
    }
  }, []); //eslint-disable-line

  const getStripePaymentInfo = (organizationId, sessionId) => {
    setTimeout(() => {
      axios
        .get(`${config.API_ENDPOINT}/checkout/session/${organizationId}/${sessionId}`)
        .then((res) => {
          const session = res.data;

          if (session.paymentStatus === "paid") {
            if (type === "esncard") {
              setStatusText("Thank you for purchasing your ESNcard. Please check your inbox.");
              setLoading(false);
              setPaymentId(session.paymentIntent);
            } else if (type === "event") {
              setStatusText("Thank you for purchasing this event. Please check your inbox.");
              setLoading(false);
              setPaymentId(session.paymentIntent);
            }
          } else {
            setStatusText("An error occurred while processing your payment.");
            setLoading(false);
            setPaymentId(session.paymentIntent);
          }
        })
        .catch(() => {
          toast.error("An unexpected error has occurred");
        });
    }, 2000);
  };

  const getRedsysPaymentInfo = (organizationId, signature, signatureVer, merchantParameters) => {
    setTimeout(() => {
      axios
        .post(`${config.API_ENDPOINT}/checkout/redsys/details`, {
          organization: organizationId,
          payload: {
            Ds_Signature: signature,
            Ds_SignatureVersion: signatureVer,
            Ds_MerchantParameters: merchantParameters,
          },
        })
        .then((res) => {
          const details = res.data;

          if (!details.isError) {
            if (type === "esncard") {
              setStatusText("Thank you for purchasing your ESNcard. Please check your inbox.");
              setLoading(false);
              setPaymentId(details.orderId);
            } else if (type === "event") {
              setStatusText("Thank you for purchasing this event. Please check your inbox.");
              setLoading(false);
              setPaymentId(details.orderId);
            }
          } else {
            setStatusText("An error occurred while processing your payment.");
            setLoading(false);
            setPaymentId(details.orderId);
          }
        })
        .catch(() => {
          toast.error("An unexpected error has occurred");
        });
    }, 2000);
  };

  return (
    <Container sx={{ p: 5 }}>
      <Grid container spacing={0} direction="column" alignItems="center" justify="center">
        <Grid item xs={3}>
          {loading ? <CircularProgress /> : null}
        </Grid>
        <Grid item xs={3}>
          <Typography style={{ textAlign: "center" }}>{statusText}</Typography>
        </Grid>
        {paymentId == null ? null : (
          <Grid item xs={3}>
            <Typography style={{ fontSize: 10, marginTop: 10, textAlign: "center" }}>
              If you have any problem during this process, contact your ESN office and provide this
              code:
              <br />
              <i>{paymentId}</i>
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default PurchaseInfo;
