const esnCountryConfig = {
    portugal: {
        supportEmail: "orders@esnportugal.org",
        orgFirst: false,
    },
    spain: {
        supportEmail: "support@esn-spain.org",
        orgFirst: true,
    },
    test: {
        supportEmail: "test@eventupp.eu",
        orgFirst: true,
    }
};

export default esnCountryConfig;