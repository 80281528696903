import {
    Dialog,
    DialogTitle,
    TextField,
    DialogContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogActions,
    Button,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { toast } from "react-toastify";
import { Autocomplete, Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

const AddQuestion = (props) => {
    const [id, setId] = useState(uuidv4())
    const [question, setQuestion] = useState("");
    const [type, setType] = useState("");
    const [values, setValues] = useState([]);
    const [required, setRequired] = useState(true);

    useEffect(() => {
        if (props.editQuestion != null) {
            setId(props.editQuestion.id);
            setQuestion(props.editQuestion.question);
            setType(props.editQuestion.type);
            setValues(props.editQuestion.values ?? []);
            setRequired(props.editQuestion.required);
        }
    }, [props.editQuestion]);

    const resetState = () => {
        setId(uuidv4());
        setQuestion("");
        setType("");
        setValues([]);
        setRequired(true);
    }

    const close = () => {
        resetState();
        props.onClose();
    }

    const confirm = () => {
        if (question === "" || type === "") {
            toast.warning("You must fill the required fields");
            return;
        }

        if (type === "select" && values.length === 0) {
            toast.warning("The 'Selection' type requires at least one value");
            return;
        }

        let q = {
            id,
            question,
            type,
            required
        };

        if (type === "select" || type === "select-multi") {
            q.values = values;
        }

        resetState();

        props.onConfirm(q);
    }

    return (
        <Dialog
            open={props.open}
            onClose={close}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">Add Question</DialogTitle>
            <DialogContent>
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Question"
                    name="question"
                    type="text"
                    autoComplete="off"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    sx={{ mb: 2, mt: 2 }}
                />
                <FormControlLabel control={<Checkbox checked={required} onChange={e => setRequired(e.target.checked)} />} label="Required question" />
                <FormControl fullWidth sx={{ mb: 2 }} required>
                    <InputLabel id="type-label">Type</InputLabel>
                    <Select
                        labelId="type-label"
                        label="Type"
                        value={type}
                        onChange={(e) => {
                            setType(e.target.value);
                            setValues([]);
                        }}
                    >
                        <MenuItem value="select">Selection</MenuItem>
                        <MenuItem value="select-multi">Multiple Choice</MenuItem>
                        <MenuItem value="text">Text</MenuItem>
                        <MenuItem value="datetime">Date & Time</MenuItem>
                    </Select>
                </FormControl>
                {type === "select" || type === "select-multi" ? (
                    <Autocomplete
                        multiple
                        options={[]}
                        freeSolo
                        onChange={(_, values) => setValues(values)}
                        value={values}
                        renderTags={(value, getTagProps) =>
                            value.map((question, index) => (
                                <Chip
                                    variant="outlined"
                                    label={question}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="filled"
                                label="Selection options"
                            />
                        )}
                    />
                ) : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="primary">
                    Cancel
                </Button>
                <Button onClick={confirm} color="primary" autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddQuestion;