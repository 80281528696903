import config from "../../utils/config";
import {
  Typography,
  TextField,
  Button,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Box,
  Link,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { toast } from "react-toastify";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";
import countries from "../../utils/countries";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";
import { DateTimePicker } from "@mui/x-date-pickers";

function BuyEvent(props) {
  const [panel, setPanel] = useState(0);
  const [esnCard, setEsnCard] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [birthdate, setBirthdate] = useState(null);
  const [country, setCountry] = useState("");
  const [passport, setPassport] = useState("");
  const [phone, setPhone] = useState("");
  const [options, setOptions] = useState({});
  const [withDiscount, setWithDiscount] = useState(false);
  const [vat, setVat] = useState("");
  const [showVat, setShowVat] = useState(false);
  const [event, setEvent] = useState(null);
  let messagesEnd = null;

  useEffect(() => {
    fetchOrganization();
    loadDefaultOptions();
  }, []); //eslint-disable-line

  useEffect(() => {
    setEvent(props.event);
    setWithDiscount(false);
  }, [props.organization]); //eslint-disable-line

  useEffect(() => {
    if (panel !== 0) {
      scrollToBottom();
    }
  }, [panel]); //eslint-disable-line

  const loadDefaultOptions = () => {
    let result = {};

    for (let option of props.event.options) {
      if (option.type === "select-multi") {
        result[option.name] = [];
      } else {
        result[option.name] = "";
      }
    }

    setOptions(result);
  };

  const scrollToBottom = () => {
    messagesEnd?.scrollIntoView({ behavior: "smooth" });
  };

  const fetchOrganization = () => {
    axios
      .get(`${config.API_ENDPOINT}/organizations/${props.event.organization}`)
      .then((res) => {
        let organization = res.data;
        setShowVat(organization.showVAT);
      })
      .catch(() => {
        toast.error("An unexpected error has occurred");
      });
  };

  const fetchStudent = () => {
    axios
      .get(`${config.API_ENDPOINT}/students/esncard/${esnCard}`)
      .then((res) => {
        let student = res.data;

        if (moment(student.registerDate).add(1, "year").isBefore(moment())) {
          toast.error("Invalid ESNcard. (Expired)");
        } else {
          if (moment().subtract(24, "h").isBefore(moment(student.registerDate))) {
            setWithDiscount(true);
            props.showSpecial();
          }

          setName(student.name);
          setEmail(student.email);
          setBirthdate(moment(student.birthdate));
          setCountry(student.country);
          setPassport(student.passport);
          setPhone(student.phone);
          setVat("");
          setPanel(1);
        }
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          toast.warning("Invalid ESN card");
        } else {
          toast.error("An unexpected error has occurred");
        }
      });
  };

  const submitForWishlist = useDebouncedCallback(
    () => {
      axios
        .post(`${config.API_ENDPOINT}/events/${props.event._id}/wishlist`, {
          name,
          email,
          phone,
          birthdate: birthdate.valueOf(),
          country,
          passport,
          esnCard: esnCard !== "" ? esnCard : null,
          options: JSON.stringify(options),
          event: props.event._id,
          organization: props.event.organization,
          vat,
          shared: props.shared ? true : false,
          endpoint: config.WEB_ENDPOINT,
        })
        .then(() => {
          setPanel(3);
        })
        .catch((err) => {
          switch (err.response?.status) {
            case 409:
              toast.warning("You've already wishlisted for this event");
              break;
            case 406:
              toast.warning("Your ESNcard does not belong to this event's organization.");
              break;
            default:
              toast.error("An unexpected error has occurred");
              break;
          }
        });
    },
    500,
    { leading: true }
  );

  const submitForPay = useDebouncedCallback(
    () => {
      let purchase = {
        name,
        email,
        phone,
        birthdate: birthdate.valueOf(),
        country,
        passport,
        esnCard,
        options: JSON.stringify(options),
        event: props.event._id,
        organization: props.event.organization,
        vat: vat,
      };

      let url = `${config.API_ENDPOINT}/checkout/event`;

      if (props.shared) {
        url = `${config.API_ENDPOINT}/checkout/shared-event`;
      }

      axios
        .post(url, {
          organization: purchase.organization,
          cancel: `${config.WEB_ENDPOINT}/iframes/event-details/${purchase.organization}/${purchase.event}`,
          success: `${config.WEB_ENDPOINT}/iframes/purchase/event/${purchase.organization}`,
          metadata: {
            type: "event",
            data: purchase,
          },
        })
        .then(async (res) => {
          const result = res.data;

          if (result.paid) {
            setPanel(3);
            return;
          }

          switch (result.type) {
            case "stripe":
              const stripe = await loadStripe(result.publicKey);

              const redirect = await stripe.redirectToCheckout({
                sessionId: result.session,
              });

              if (redirect.error) {
                toast.error("Error opening the checkout");
                console.error(redirect.error);
              }
              break;
            case "redsys":
              // Create a hidden form element programatically and submit it
              const form = document.createElement("form");
              form.method = "POST";
              form.action = result.url;
              form.style = "display: none";

              for (let key in result.body) {
                const input = document.createElement("input");
                input.type = "hidden";
                input.name = key;
                input.value = result.body[key];
                form.appendChild(input);
              }

              document.body.appendChild(form);
              form.submit();
              break;
            default:
              toast.error("An unexpected error has occurred");
              break;
          }
        })
        .catch((err) => {
          switch (err.response?.status) {
            case 400:
              toast.warning("Invalid request. Please verify your data.");
              break;
            case 409:
              toast.warning("You've already bought this event");
              break;
            case 401:
              toast.warning("This organization does not support online payments");
              break;
            case 403:
              toast.warning("This event has sold out");
              break;
            case 406:
              toast.warning("Your ESNcard does not belong to this event's organization.");
              break;
            default:
              toast.error("An unexpected error has occurred");
              break;
          }
        });
    },
    500,
    { leading: true }
  );

  const submit = useDebouncedCallback(
    () => {
      axios
        .post(`${config.API_ENDPOINT}/events/${props.event._id}/purchase`, {
          name,
          email,
          phone,
          birthdate: birthdate.valueOf(),
          country,
          passport,
          esnCard: esnCard !== "" ? esnCard : null,
          options: JSON.stringify(options),
          event: props.event._id,
          organization: props.event.organization,
          vat,
          shared: props.shared ? true : false,
          endpoint: config.WEB_ENDPOINT,
        })
        .then((res) => {
          setPanel(3);
        })
        .catch((err) => {
          switch (err.response?.status) {
            case 409:
              toast.warning("You've already bought this event");
              break;
            case 403:
              toast.warning("This event has sold out");
              break;
            case 406:
              toast.warning("Your ESNcard does not match this event's organization.");
              break;
            default:
              toast.error("An unexpected error has occurred");
              break;
          }
        });
    },
    500,
    { leading: true }
  );

  const optionsFilled = () => {
    for (let option of event.options) {
      if (!option.required) continue;

      if (
        options[option.name] == null ||
        options[option.name] === "" ||
        (Array.isArray(options[option.name]) && options[option.name].length === 0)
      ) {
        return false;
      }
    }

    return true;
  };

  const calculatePrice = () => {
    let price = props.event.regularPrice;

    if (!props.event.esnCardRequired && esnCard !== "" && props.event.esnCardPrice != null) {
      price = props.event.esnCardPrice;
    }

    if (
      withDiscount &&
      props.event.esnCardBuyPrice != null &&
      props.event.esnCardBuyPrice < props.event.regularPrice
    ) {
      price = props.event.esnCardBuyPrice;
    }

    return price;
  };

  const getPanel = () => {
    switch (panel) {
      case 0:
        return (
          <div style={{ width: "100%" }}>
            <Typography style={{ marginTop: 50, marginBottom: 10 }}>
              Please enter your ESNcard
            </Typography>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="ESNcard"
              name="esnCard"
              type="text"
              autoComplete="off"
              value={esnCard}
              onChange={(e) => setEsnCard(e.target.value.trim())}
            />
            {!props.event.esnCardRequired || esnCard !== "" ? (
              <Button
                variant="contained"
                color="primary"
                style={{ color: "white", marginTop: 10 }}
                endIcon={<ArrowRightIcon />}
                onClick={() => {
                  if (esnCard !== "") {
                    fetchStudent();
                  } else {
                    setPanel(1);
                  }
                }}
              >
                {props.event.esnCardRequired || esnCard !== ""
                  ? "CONTINUE"
                  : "CONTINUE WITHOUT ESNCARD"}
              </Button>
            ) : null}
          </div>
        );
      case 1:
        return (
          <div>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Name"
              name="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ marginBottom: 20 }}
              disabled={esnCard !== ""}
            />
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Email"
              name="email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
              style={{ marginBottom: 20 }}
              disabled={esnCard !== ""}
            />
            <TextField
              variant="outlined"
              required
              fullWidth
              label="ID/Passport"
              name="passport"
              type="text"
              value={passport}
              onChange={(e) => setPassport(e.target.value.trim())}
              style={{ marginBottom: 20 }}
              disabled={esnCard !== ""}
            />
            <DatePicker
              label="Birthdate"
              variant="outlined"
              inputFormat="DD/MM/YYYY"
              value={birthdate}
              onChange={(date) => setBirthdate(date)}
              renderInput={(params) => <TextField {...params} required sx={{ mb: 2 }} fullWidth />}
              disabled={esnCard !== ""}
            />
            <FormControl
              fullWidth
              style={{ marginBottom: 20 }}
              variant="outlined"
              required
              disabled={esnCard !== ""}
            >
              <InputLabel style={{ backgroundColor: "#fff" }}>Country</InputLabel>
              <Select value={country} onChange={(e) => setCountry(e.target.value)}>
                {countries.map((country) => (
                  <MenuItem key={country.name} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <PhoneInput
              placeholder="Phone*"
              value={phone}
              onChange={(phone) => setPhone(`+${phone}`)}
              style={{ marginBottom: 20 }}
              disabled={esnCard !== ""}
            />
            {showVat ? (
              <div>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="VAT"
                  name="vat"
                  type="text"
                  value={vat}
                  onChange={(e) => setVat(e.target.value.trim())}
                />
                <Typography style={{ fontSize: 10, marginBottom: 20 }}>
                  You only need to insert a VAT number if you need an invoice. Invalid VAT numbers
                  will be ignored!
                </Typography>
              </div>
            ) : null}
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white", marginTop: 10 }}
              startIcon={<ArrowLeftIcon />}
              onClick={() => {
                props.hideSpecial();

                setWithDiscount(false);
                setPanel(0);
              }}
            >
              BACK
            </Button>
            {name !== "" &&
            email !== "" &&
            passport !== "" &&
            birthdate != null &&
            birthdate.isValid() &&
            country !== "" &&
            phone !== "" ? (
              <Button
                variant="contained"
                color="primary"
                style={{ color: "white", marginTop: 10, marginLeft: 10 }}
                endIcon={<ArrowRightIcon />}
                onClick={() => setPanel(2)}
              >
                CONTINUE
              </Button>
            ) : null}
          </div>
        );
      case 2:
        const filled = optionsFilled();
        return (
          <div>
            {props.event.options.length === 0 ? (
              <Typography>No extra options to select. You can continue.</Typography>
            ) : null}
            {props.event.options.map((option) => {
              switch (option.type) {
                case "select-multi":
                case "select":
                  return (
                    <FormControl
                      key={option.name}
                      fullWidth
                      sx={{ mb: 2 }}
                      variant="outlined"
                      required={option.required}
                    >
                      <InputLabel style={{ backgroundColor: "#fff" }}>{option.name}</InputLabel>
                      <Select
                        value={options[option.name]}
                        multiple={option.type === "select-multi"}
                        onChange={(e) => {
                          let opt = { ...options };
                          opt[option.name] = e.target.value;
                          setOptions(opt);
                        }}
                      >
                        {option.values.map((value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                case "text":
                  return (
                    <TextField
                      key={option.name}
                      variant="outlined"
                      required={option.required}
                      fullWidth
                      label={option.name}
                      name={option.name}
                      type="text"
                      value={options[option.name]}
                      onChange={(e) => {
                        let opt = { ...options };
                        opt[option.name] = e.target.value;
                        setOptions(opt);
                      }}
                      sx={{ mb: 2 }}
                    />
                  );
                case "datetime":
                  return (
                    <DateTimePicker
                      key={option.name}
                      variant="outlined"
                      required={option.required}
                      fullWidth
                      label={option.name}
                      name={option.name}
                      value={moment(options[option.name])}
                      onChange={(val) => {
                        let opt = { ...options };
                        opt[option.name] = moment(val).valueOf();
                        setOptions(opt);
                      }}
                      ampm={false}
                      renderInput={(params) => <TextField {...params} sx={{ mb: 2 }} fullWidth />}
                      sx={{ mb: 2 }}
                    />
                  );
                default:
                  return null;
              }
            })}

            <Button
              variant="contained"
              color="primary"
              style={{ color: "white", marginTop: 10 }}
              startIcon={<ArrowLeftIcon />}
              onClick={() => {
                loadDefaultOptions();
                setPanel(1);
              }}
            >
              BACK
            </Button>
            {filled && !props.wishlist && (
              <div style={{ display: "inline" }}>
                {calculatePrice() > 0 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ color: "white", marginTop: 10, marginLeft: 10 }}
                    endIcon={<ArrowRightIcon />}
                    onClick={submit}
                  >
                    PAY IN THE OFFICE
                  </Button>
                ) : null}

                <Button
                  variant="contained"
                  color="primary"
                  style={{ color: "white", marginTop: 10, marginLeft: 10 }}
                  endIcon={<ArrowRightIcon />}
                  onClick={submitForPay}
                >
                  PAY NOW
                </Button>

                {event.externalPayLink != null && event.externalPayLink !== "" && (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ color: "white", marginTop: 10, marginLeft: 10 }}
                    endIcon={<ArrowRightIcon />}
                    onClick={submit}
                  >
                    {event.externalPayLabel != null && event.externalPayLabel !== ""
                      ? event.externalPayLabel
                      : "PAY EXTERNALLY"}
                  </Button>
                )}
              </div>
            )}

            {filled && props.wishlist && (
              <Button
                variant="contained"
                color="primary"
                style={{ color: "white", marginTop: 10, marginLeft: 10 }}
                endIcon={<ArrowRightIcon />}
                onClick={submitForWishlist}
              >
                SUBMIT TO WISHLIST
              </Button>
            )}
          </div>
        );
      case 3:
        return (
          <Box>
            <Typography align="center">
              <strong>Thank you!</strong> Your {props.wishlist ? "wishlist" : "purchase"} request
              was submitted. Please check your inbox.
            </Typography>
            {event.externalPayLink != null && event.externalPayLink !== "" ? (
              <Typography align="center">
                You can pay for your ticket in the office or at{" "}
                <Link href={event.externalPayLink} target="_blank" rel="noreferrer">
                  {event.externalPayLink}
                </Link>
                .
              </Typography>
            ) : null}
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {getPanel()}
      <div
        style={{ float: "left", clear: "both" }}
        ref={(el) => {
          messagesEnd = el;
        }}
      ></div>
    </div>
  );
}

export default BuyEvent;
