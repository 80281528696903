import {
	FormControl,
	Select,
	InputLabel,
	MenuItem,
	Card,
	Typography,
	CardContent,
	CircularProgress,
	Grid,
} from "@mui/material";
import moment from "moment";
import { toast } from "react-toastify";
import config from "../../utils/config";
import { Pie } from "react-chartjs-2";
import 'chart.js/auto';
import { useEffect, useState } from "react";
import axios from "axios";

const emptyChartData = {
	labels: [],
	datasets: [
		{
			data: [],
			backgroundColor: [],
		},
	],
};

function Dashboard(props) {
	const [year, setYear] = useState(moment().year());
	const [stats, setStats] = useState({});
	const [studentsPerCountry, setStudentsPerCountry] = useState(emptyChartData);
	const [studentsPerUniversity, setStudentsPerUniversity] = useState(emptyChartData);
	const [studentsPerGender, setStudentsPerGender] = useState(emptyChartData);
	const [studentsPerProgram, setStudentsPerProgram] = useState(emptyChartData);
	const [studentsPerDuration, setStudentsPerDuration] = useState(emptyChartData);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		props.setTitle("Dashboard");
		let now = moment();
		let compareTime = moment(now.year() + "-08-01T00:00:00");
		let year = now.year();
		if (now.isBefore(compareTime)) year--;
		setYear(year);
	}, []); // eslint-disable-line

	useEffect(() => {
		setLoaded(false);
		fetchStats();
	}, [props.organization, year]); // eslint-disable-line

	useEffect(() => {
		generateCharts();
	}, [stats]); // eslint-disable-line

	const fetchStats = () => {
		let startDate = moment(`${year}-08-01T00:00:00`);
		let endDate = moment(`${year + 1}-08-01T00:00:00`);

		axios
			.get(`${config.API_ENDPOINT}/stats/${props.organization}?startDate=${startDate.valueOf()}&endDate=${endDate.valueOf()}`, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(res => {
				setStats(res.data);
				setLoaded(true);
			})
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred. Please verify your event key.");
						break;
				}
			});
	}

	const generateCharts = () => {
		let students = stats.students ?? [];

		let studentsPerCountry = {
			labels: [],
			datasets: [
				{
					data: [],
					backgroundColor: [],
				},
			],
		};

		let studentsPerUniversity = {
			labels: [],
			datasets: [
				{
					data: [],
					backgroundColor: [],
				},
			],
		};

		let studentsPerGender = {
			labels: [],
			datasets: [
				{
					data: [],
					backgroundColor: [],
				},
			],
		};

		let studentsPerProgram = {
			labels: [],
			datasets: [
				{
					data: [],
					backgroundColor: [],
				},
			],
		};

		let studentsPerDuration = {
			labels: [],
			datasets: [
				{
					data: [],
					backgroundColor: [],
				},
			],
		};

		for (let student of students) {
			let indexCountry = studentsPerCountry.labels.indexOf(student.country);
			let indexUniversity = studentsPerUniversity.labels.indexOf(
				student.university
			);
			let indexGender = studentsPerGender.labels.indexOf(student.gender);
			let indexProgram = studentsPerProgram.labels.indexOf(student.program);
			let indexDuration = studentsPerDuration.labels.indexOf(
				student.programDuration
			);

			if (indexCountry < 0) {
				studentsPerCountry.labels.push(student.country);
				studentsPerCountry.datasets[0].data.push(1);
				studentsPerCountry.datasets[0].backgroundColor.push(getRandomColor());
			} else {
				studentsPerCountry.datasets[0].data[indexCountry]++;
			}

			if (indexUniversity < 0) {
				studentsPerUniversity.labels.push(student.university);
				studentsPerUniversity.datasets[0].data.push(1);
				studentsPerUniversity.datasets[0].backgroundColor.push(
					getRandomColor()
				);
			} else {
				studentsPerUniversity.datasets[0].data[indexUniversity]++;
			}

			if (indexGender < 0) {
				studentsPerGender.labels.push(student.gender);
				studentsPerGender.datasets[0].data.push(1);
				studentsPerGender.datasets[0].backgroundColor.push(getRandomColor());
			} else {
				studentsPerGender.datasets[0].data[indexGender]++;
			}

			if (indexProgram < 0) {
				studentsPerProgram.labels.push(student.program);
				studentsPerProgram.datasets[0].data.push(1);
				studentsPerProgram.datasets[0].backgroundColor.push(getRandomColor());
			} else {
				studentsPerProgram.datasets[0].data[indexProgram]++;
			}

			if (indexDuration < 0) {
				studentsPerDuration.labels.push(student.programDuration);
				studentsPerDuration.datasets[0].data.push(1);
				studentsPerDuration.datasets[0].backgroundColor.push(getRandomColor());
			} else {
				studentsPerDuration.datasets[0].data[indexDuration]++;
			}
		};

		setStudentsPerCountry(studentsPerCountry);
		setStudentsPerUniversity(studentsPerUniversity);
		setStudentsPerGender(studentsPerGender);
		setStudentsPerProgram(studentsPerProgram);
		setStudentsPerDuration(studentsPerDuration);
	}

	const generateYearArray = () => {
		let arr = [];
		for (let y = 2020; y <= moment().year(); y++) {
			arr.push(y);
		}

		return arr;
	}

	return (
		<div>
			<FormControl
				fullWidth
				style={{ marginTop: 20, marginBottom: 20 }}
				required
			>
				<InputLabel id="school-year-label">School Year:</InputLabel>
				<Select
					label="School Year:"
					labelId="school-year-label"
					value={year}
					onChange={(e) => setYear(e.target.value)}
				>
					{generateYearArray().map((y) => (
						<MenuItem key={y.toString()} value={y}>
							{y}/{y + 1}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<p>The statistics are updated every 15 minutes.</p>
			<Grid container spacing={2} hidden={!loaded}>
				<Grid item xs={12} md={4}>
					<Card style={{ padding: 5 }}>
						<CardContent>
							<Typography style={{ fontWeight: "bold", fontSize: 20 }}>
								Registered Students
							</Typography>
							<Typography
								style={{ fontWeight: "bold", fontSize: 50 }}
								color="primary"
								align="center"
							>
								{stats.newStudents}
							</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} md={4}>
					<Card style={{ padding: 5 }}>
						<CardContent>
							<Typography style={{ fontWeight: "bold", fontSize: 20 }}>
								Active Students
							</Typography>
							<Typography
								style={{ fontWeight: "bold", fontSize: 50 }}
								color="primary"
								align="center"
							>
								{stats.validStudents}
							</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} md={4}>
					<Card style={{ padding: 5 }}>
						<CardContent>
							<Typography style={{ fontWeight: "bold", fontSize: 20 }}>
								Event Sales
							</Typography>
							<Typography
								style={{ fontWeight: "bold", fontSize: 50 }}
								color="primary"
								align="center"
							>
								{stats.sales}
							</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} md={4}>
					<Card style={{ padding: 5 }}>
						<CardContent>
							<Typography style={{ fontWeight: "bold", fontSize: 20 }}>
								ESNcard Requests Pending
							</Typography>
							<Typography
								style={{ fontWeight: "bold", fontSize: 50 }}
								color="primary"
								align="center"
							>
								{stats.pendingCards}
							</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} md={4}>
					<Card style={{ padding: 5 }}>
						<CardContent>
							<Typography style={{ fontWeight: "bold", fontSize: 20 }}>
								Event Purchases Pending
							</Typography>
							<Typography
								style={{ fontWeight: "bold", fontSize: 50 }}
								color="primary"
								align="center"
							>
								{stats.pendingPurchases}
							</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} md={6}>
					<Card
						style={{
							padding: 10,
						}}
					>
						<CardContent>
							<Typography
								align="center"
								style={{ fontWeight: "bold", fontSize: 20 }}
							>
								Registered Students / Country
							</Typography>
							<Pie data={studentsPerCountry} options={{ plugins: { legend: { display: false } } }} />
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} md={6}>
					<Card
						style={{
							padding: 10,
						}}
					>
						<CardContent>
							<Typography
								align="center"
								style={{ fontWeight: "bold", fontSize: 20 }}
							>
								Registered Students / University
							</Typography>
							<Pie data={studentsPerUniversity} options={{ plugins: { legend: { display: false } } }} />
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} md={6}>
					<Card
						style={{
							padding: 10,
						}}
					>
						<CardContent>
							<Typography
								align="center"
								style={{ fontWeight: "bold", fontSize: 20 }}
							>
								Registered Students / Gender
							</Typography>
							<Pie data={studentsPerGender} options={{ plugins: { legend: { display: false } } }} />
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} md={6}>
					<Card
						style={{
							padding: 10,
						}}
					>
						<CardContent>
							<Typography
								align="center"
								style={{ fontWeight: "bold", fontSize: 20 }}
							>
								Registered Students / Mobility Program
							</Typography>
							<Pie data={studentsPerProgram} options={{ plugins: { legend: { display: false } } }} />
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} md={6}>
					<Card
						style={{
							padding: 10,
						}}
					>
						<CardContent>
							<Typography
								align="center"
								style={{ fontWeight: "bold", fontSize: 20 }}
							>
								Registered Students / Program Duration
							</Typography>
							<Pie data={studentsPerDuration} options={{ plugins: { legend: { display: false } } }} />
						</CardContent>
					</Card>
				</Grid>
			</Grid>
			<div hidden={loaded}>
				<CircularProgress />
				<p>Loading statistics...</p>
			</div>
		</div>
	);
}

export default Dashboard;

function getRandomColor() {
	var letters = "0123456789ABCDEF".split("");
	var color = "#";
	for (var i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
}
