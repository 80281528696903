import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  OutlinedInput,
  Tab,
  Tabs,
} from "@mui/material";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import config from "../../utils/config";
import axios from "axios";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";

const numRows = 10;

const EventPicker = (props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedShared, setSelectedShared] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventsPage, setEventsPage] = useState(0);
  const [eventsCount, setEventsCount] = useState(0);
  const [sharedEvents, setSharedEvents] = useState([]);
  const [sharedEventsPage, setSharedEventsPage] = useState(0);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setEventsPage(0);
    setSharedEventsPage(0);
    fetchEvents(0);
    fetchEventCount();
    fetchSharedEvents(0);
  }, [props.organization]); // eslint-disable-line

  useEffect(() => {
    onSearchTextChanged();
  }, [searchText]); // eslint-disable-line

  const onSearchTextChanged = useDebouncedCallback(() => {
    setEventsPage(0);
    fetchEvents(0);
    fetchEventCount();
  }, 500);

  const fetchNextPage = (tab) => {
    if (tab === 0) {
      const page = eventsPage + 1;
      setEventsPage(page);
      fetchEvents(page);
    } else {
      const sharedPage = sharedEventsPage + 1;
      setSharedEventsPage(sharedPage);
      fetchSharedEvents(sharedPage);
    }
  };

  const fetchEvents = useDebouncedCallback((page) => {
    let q = `${config.API_ENDPOINT}/events/${props.organization}/all?page=${page}&numRows=${numRows}&shared=${props.shared}`;

    if (searchText !== "") {
      q += `&search=${searchText}`;
    }

    axios
      .get(q, { headers: { "x-access-token": localStorage.getItem("access-token") } })
      .then((res) => {
        if (page === 0) {
          setEvents(res.data);
        } else {
          setEvents([...events, ...res.data]);
        }
      })
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  }, 500);

  const fetchEventCount = () => {
    let q = `${config.API_ENDPOINT}/events/${props.organization}/count?shared=${props.shared}`;

    if (searchText !== "") {
      q += `&search=${searchText}`;
    }

    axios
      .get(q)
      .then((res) => setEventsCount(res.data.count))
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  };

  const fetchSharedEvents = useDebouncedCallback((page) => {
    let q = `${config.API_ENDPOINT}/events/shared/${props.organization}/all`;

    axios
      .get(q, { headers: { "x-access-token": localStorage.getItem("access-token") } })
      .then((res) => {
        if (page === 0) {
          setSharedEvents(res.data);
        } else {
          setSharedEvents([...events, ...res.data]);
        }
      })
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  }, 500);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Choose an Event</DialogTitle>
      <DialogContent>
        <Tabs value={tabIndex} onChange={(_, v) => setTabIndex(v)}>
          <Tab label="Regular" />
          <Tab label="Shared" />
        </Tabs>

        {tabIndex === 0 && (
          <div style={{ width: 500 }}>
            <OutlinedInput
              required
              fullWidth
              name="search"
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search by name"
              endAdornment={
                <InputAdornment position="end">
                  {searchText === "" ? null : (
                    <IconButton onClick={() => setSearchText("")}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
              sx={{ mt: 2, mb: 2 }}
            />
            <div style={{ height: 300, overflowY: "auto" }}>
              <List>
                {events.map((event) => (
                  <ListItemButton
                    key={event._id}
                    selected={selectedId === event._id}
                    onClick={() => {
                      setSelectedId(event._id);
                      setSelectedShared(false);
                    }}
                  >
                    {event.name}
                  </ListItemButton>
                ))}
              </List>
              {events.length < eventsCount && (
                <Button onClick={() => fetchNextPage(0)}>Load More</Button>
              )}
            </div>
          </div>
        )}
        {tabIndex === 1 && (
          <div style={{ width: 500 }}>
            <div style={{ height: 300, overflowY: "auto" }}>
              <List>
                {sharedEvents.map((event) => (
                  <ListItemButton
                    key={event._id}
                    selected={selectedId === event._id}
                    onClick={() => {
                      setSelectedId(event._id);
                      setSelectedShared(true);
                    }}
                  >
                    {event.parentEvent.name}
                  </ListItemButton>
                ))}
              </List>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => props.onConfirm(selectedId, selectedShared)}
          color="primary"
          disabled={selectedId === null}
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventPicker;
