const mobilityPrograms = [
	"Erasmus + Programme: study exchange",
	"Erasmus + Programme: traineeship/internship/apprenticeship",
	"Erasmus + Programme: Joint Masters",
	"Erasmus + Programme: European Solidarity Corps",
	"Mobility Programme-other: study exchange",
	"Mobility Programme-other: traineeship/internship/apprenticeship",
	"International full degree students",
	"Mobility Programme-other: volunteer (non-ESN)",
	"ESN Volunteer/Buddy/Alumni"
];

export default mobilityPrograms;