import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
  Box,
} from "@mui/material";
import config from "../../utils/config";
import { toast } from "react-toastify";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { useState, useEffect } from "react";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";
import AddIcon from "@mui/icons-material/Add";
import AddQuestion from "./AddQuestion";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

function OrgSettings(props) {
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [esnCardPrice, setEsnCardPrice] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [onlinePaymentService, setOnlinePaymentService] = useState("none");
  const [stripeSecret, setStripeSecret] = useState("");
  const [stripePublic, setStripePublic] = useState("");
  const [stripeWebhookSecret, setStripeWebhookSecret] = useState("");
  const [redsysMerchantCode, setRedsysMerchantCode] = useState("");
  const [redsysSecret, setRedsysSecret] = useState("");
  const [redsysTerminal, setRedsysTerminal] = useState("");
  const [redsysSandboxMode, setRedsysSandboxMode] = useState(false);
  const [vendusKey, setVendusKey] = useState("");
  const [vendusRegister, setVendusRegister] = useState(null);
  const [vendusPaymentMethod, setVendusPaymentMethod] = useState(null);
  const [registers, setRegisters] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [showVAT, setShowVAT] = useState(false);
  const [extraQuestions, setExtraQuestions] = useState([]);
  const [addingQuestion, setAddingQuestion] = useState(false);
  const [editQuestionIndex, setEditQuestionIndex] = useState(null);

  useEffect(() => {
    props.setTitle("Organization Settings");
  }, []); // eslint-disable-line

  useEffect(() => {
    fetchOrgInfo();
  }, [props.organization]); // eslint-disable-line

  useEffect(() => {
    if (vendusKey !== "") {
      fetchRegisters();
      fetchPaymentMethods();
    }
  }, [vendusKey]); // eslint-disable-line

  const fetchRegisters = useDebouncedCallback(() => {
    axios
      .get(`${config.API_ENDPOINT}/utils/vendus-registers?apiKey=${vendusKey}`, {
        headers: { "x-access-token": localStorage.getItem("access-token") },
      })
      .then((res) => setRegisters(res.data))
      .catch(() => toast.error("An unexpected error has occurred (VENDUS)"));
  }, 1000);

  const fetchPaymentMethods = useDebouncedCallback(() => {
    axios
      .get(`${config.API_ENDPOINT}/utils/vendus-paymentmethods?apiKey=${vendusKey}`, {
        headers: { "x-access-token": localStorage.getItem("access-token") },
      })
      .then((res) => setPaymentMethods(res.data))
      .catch(() => toast.error("An unexpected error has occurred (VENDUS)"));
  }, 1000);

  const update = useDebouncedCallback(
    () => {
      if (esnCardPrice == null || esnCardPrice < 0) {
        toast.error("Invalid ESNcard price");
        return;
      }

      if (vendusKey !== "" && !vendusRegister) {
        toast.error("Invalid Vendus register");
        return;
      }

      if (vendusKey !== "" && !vendusPaymentMethod) {
        toast.error("Invalid Vendus payment method");
        return;
      }

      if (onlinePaymentService === "stripe") {
        if (stripePublic === "" || stripeSecret === "" || stripeWebhookSecret === "") {
          toast.error("Invalid Stripe settings");
          return;
        }
      }

      if (onlinePaymentService === "redsys") {
        if (redsysMerchantCode === "" || redsysSecret === "" || redsysTerminal === "") {
          toast.error("Invalid Redsys settings");
          return;
        }
      }

      let org = {
        esnCardPrice,
        onlinePaymentService,
        stripePublic,
        stripeSecret,
        stripeWebhookSecret,
        redsysMerchantCode,
        redsysSecret,
        redsysTerminal,
        redsysSandboxMode,
        vendusKey,
        showVAT,
        customEsnCardQuestions: extraQuestions,
      };

      if (vendusRegister != null) {
        org.vendusRegister = vendusRegister;
      }

      if (vendusPaymentMethod != null) {
        org.vendusPaymentMethod = vendusPaymentMethod;
      }

      if (editorState != null) {
        org.termsConditions = stateToHTML(editorState.getCurrentContent());
      }

      axios
        .post(`${config.API_ENDPOINT}/organizations/${props.organization}`, org, {
          headers: { "x-access-token": localStorage.getItem("access-token") },
        })
        .then(() => toast.success("Success!"))
        .catch((err) => {
          switch (err.response?.status) {
            case 401:
              localStorage.removeItem("access-token");
              window.location.replace("/login");
              break;
            default:
              toast.error("An unexpected error has occurred");
              break;
          }
        });
    },
    500,
    { leading: true }
  );

  const fetchOrgInfo = () => {
    axios
      .get(`${config.API_ENDPOINT}/organizations/secret/${props.organization}`, {
        headers: { "x-access-token": localStorage.getItem("access-token") },
      })
      .then((res) => {
        let org = res.data;
        setName(org.name);
        setId(org._id);
        setEsnCardPrice(org.esnCardPrice);
        setEditorState(EditorState.createWithContent(stateFromHTML(org.termsConditions)));
        setOnlinePaymentService(org.onlinePaymentService);
        setStripeSecret(org.stripeSecret);
        setStripeWebhookSecret(org.stripeWebhookSecret);
        setStripePublic(org.stripePublic);
        setRedsysMerchantCode(org.redsysMerchantCode);
        setRedsysSecret(org.redsysSecret);
        setRedsysTerminal(org.redsysTerminal);
        setRedsysSandboxMode(org.redsysSandboxMode);
        setVendusKey(org.vendusKey);
        setVendusRegister(org.vendusRegister);
        setVendusPaymentMethod(org.vendusPaymentMethod);
        setShowVAT(org.showVAT);
        setExtraQuestions(org.customEsnCardQuestions ?? []);
      })
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  };

  const getPaymentSettings = () => {
    return (
      <Box>
        <FormControl fullWidth sx={{ mt: 3 }}>
          <InputLabel id="payment-service-label">Online Payment Service</InputLabel>
          <Select
            labelId="payment-service-label"
            label="Online Payment Service"
            value={onlinePaymentService}
            onChange={(e) => setOnlinePaymentService(e.target.value)}
          >
            <MenuItem value="none">None</MenuItem>
            <MenuItem value="stripe">Stripe</MenuItem>
            <MenuItem value="redsys">Redsys</MenuItem>
          </Select>
        </FormControl>
        {onlinePaymentService === "stripe" && (
          <>
            <TextField
              variant="outlined"
              fullWidth
              label="Stripe Public Key"
              name="stripePublic"
              type="text"
              value={stripePublic}
              required
              onChange={(e) => setStripePublic(e.target.value)}
              sx={{ mt: 3 }}
            />
            <TextField
              variant="outlined"
              fullWidth
              label="Stripe Secret Key"
              name="stripeSecret"
              type="text"
              value={stripeSecret}
              required
              onChange={(e) => setStripeSecret(e.target.value)}
              sx={{ mt: 3 }}
            />
            <TextField
              variant="outlined"
              fullWidth
              label="Stripe Webhook Secret"
              name="stripeWebhookSecret"
              type="text"
              value={stripeWebhookSecret}
              required
              onChange={(e) => setStripeWebhookSecret(e.target.value)}
              sx={{ mt: 3 }}
            />
          </>
        )}
        {onlinePaymentService === "redsys" && (
          <>
            <TextField
              variant="outlined"
              fullWidth
              label="Redsys Merchant Code"
              name="redsysMerchantCode"
              type="text"
              value={redsysMerchantCode}
              onChange={(e) => setRedsysMerchantCode(e.target.value)}
              sx={{ mt: 3 }}
            />
            <TextField
              variant="outlined"
              fullWidth
              label="Redsys Secret"
              name="redsysSecret"
              type="password"
              value={redsysSecret}
              onChange={(e) => setRedsysSecret(e.target.value)}
              sx={{ mt: 3 }}
            />
            <TextField
              variant="outlined"
              fullWidth
              label="Redsys Terminal Number"
              name="redsysTerminal"
              type="number"
              value={redsysTerminal}
              onChange={(e) => setRedsysTerminal(e.target.value)}
              sx={{ mt: 3 }}
            />
            <FormControlLabel
              sx={{ mt: 3 }}
              value="start"
              control={
                <Checkbox
                  color="primary"
                  checked={redsysSandboxMode}
                  onChange={(e) => setRedsysSandboxMode(e.target.checked)}
                />
              }
              label="Enable Redsys Sandbox Mode?"
              labelPlacement="start"
            />
          </>
        )}
      </Box>
    );
  };

  if (props.user.accessLevel > 1) return null;

  return (
    <Card style={{ padding: 15 }}>
      <CardContent>
        <Typography style={{ fontWeight: "bold", fontSize: 40 }}>{name}</Typography>
        <Typography>ID: {id}</Typography>
        <TextField
          variant="outlined"
          fullWidth
          label="ESNcard Price"
          name="esnCardPrice"
          type="number"
          value={esnCardPrice || ""}
          required
          onChange={(e) =>
            setEsnCardPrice(e.target.value !== "" ? parseFloat(e.target.value) : null)
          }
          inputProps={{ step: "0.01" }}
          sx={{ mt: 5 }}
        />
        <Card sx={{ mt: 3 }}>
          <CardContent sx={{ padding: 5 }}>
            <Editor
              editorStyle={{ minHeight: 200 }}
              editorState={editorState}
              label="Terms and Conditions..."
              onEditorStateChange={(s) => setEditorState(s)}
            />
          </CardContent>
        </Card>
        {getPaymentSettings()}
        <TextField
          variant="outlined"
          fullWidth
          label="Vendus Key"
          name="vendusKey"
          type="text"
          autoComplete="off"
          value={vendusKey}
          required
          onChange={(e) => {
            setVendusKey(e.target.value);
            setVendusRegister(null);
            setVendusPaymentMethod(null);
            setPaymentMethods([]);
            setRegisters([]);
          }}
          sx={{ mt: 3 }}
        />
        {vendusKey !== "" ? (
          <FormControl sx={{ mt: 3 }} fullWidth variant="outlined">
            <InputLabel style={{ backgroundColor: "#fff" }}>Vendus Register</InputLabel>
            <Select
              key={registers}
              value={vendusRegister}
              onChange={(e) => setVendusRegister(e.target.value)}
              onOpen={fetchRegisters}
            >
              {registers.map((register) => (
                <MenuItem value={register.id}>{register.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
        {vendusKey !== "" ? (
          <FormControl sx={{ mt: 3 }} fullWidth variant="outlined">
            <InputLabel style={{ backgroundColor: "#fff" }}>Vendus Payment Method</InputLabel>
            <Select
              key={paymentMethods}
              value={vendusPaymentMethod}
              onChange={(e) => setVendusPaymentMethod(e.target.value)}
              onOpen={fetchPaymentMethods}
            >
              {paymentMethods.map((paymentMethod) => (
                <MenuItem value={paymentMethod.id}>{paymentMethod.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
        <FormControlLabel
          sx={{ mt: 3 }}
          value="start"
          control={
            <Checkbox
              color="primary"
              checked={showVAT}
              onChange={(e) => setShowVAT(e.target.checked)}
            />
          }
          label="Allow to insert VAT?"
          labelPlacement="start"
        />
        <br />
        <Typography fontWeight="bold" mt={5}>
          Extra Student Registration Questions
        </Typography>
        <Typography display="inline" color="gray" mr={1} fontSize={14}>
          Add New
        </Typography>
        <IconButton onClick={() => setAddingQuestion(true)}>
          <AddIcon />
        </IconButton>
        <br />
        {extraQuestions.map((question, index) => (
          <div key={question.id}>
            <Typography style={{ display: "inline", marginRight: 5 }}>
              {question.question}
            </Typography>
            <IconButton onClick={() => setEditQuestionIndex(index)}>
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                let q = [...extraQuestions];
                q.splice(index, 1);
                setExtraQuestions(q);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
        <Button sx={{ mt: 4, color: "white" }} variant="contained" color="primary" onClick={update}>
          UPDATE
        </Button>
      </CardContent>

      <AddQuestion
        open={addingQuestion}
        onClose={() => setAddingQuestion(false)}
        onConfirm={(val) => {
          setExtraQuestions([...extraQuestions, val]);
          setAddingQuestion(false);
        }}
      />

      <AddQuestion
        open={editQuestionIndex != null}
        onClose={() => setEditQuestionIndex(null)}
        editQuestion={editQuestionIndex != null ? extraQuestions[editQuestionIndex] : null}
        onConfirm={(val) => {
          let newQuestions = [...extraQuestions];
          newQuestions[editQuestionIndex] = val;
          setExtraQuestions(newQuestions);
          setEditQuestionIndex(null);
        }}
      />
    </Card>
  );
}

export default OrgSettings;
