import {
	Card,
	CardContent,
	TableContainer,
	Table,
	TableHead,
	TableCell,
	TableBody,
	TableRow,
	Avatar,
	IconButton,
	Menu,
	MenuItem,
	OutlinedInput,
	InputAdornment,
} from "@mui/material";
import config from "../../utils/config";
import { toast } from "react-toastify";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClearIcon from "@mui/icons-material/Clear";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";

const Users = function (props) {
	const [users, setUsers] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [menuAnchorEl, setMenuAnchorEl] = useState(null);
	const [menuUser, setMenuUser] = useState(null);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [confirmationOnYes, setConfirmationOnYes] = useState(null);
	const [confirmationOnClose, setConfirmationOnClose] = useState(null);
	const [filteredUsers, setFilteredUsers] = useState([]);

	useEffect(() => {
		props.setTitle("Users");
	}, []); //eslint-disable-line

	useEffect(() => {
		fetchUsers();
	}, [props.organization]); //eslint-disable-line

	useEffect(() => {
		onSearchTextUsersChanged();
	}, [searchText, users]); //eslint-disable-line

	const onSearchTextUsersChanged = useDebouncedCallback(() => {
		if (searchText === "") {
			setFilteredUsers(users);
		} else {
			let filtered = [];

			for (let user of users) {
				if (RegExp(`${searchText}`, "i").test(user.name)) {
					filtered.push(user);
				}
			}

			setFilteredUsers(filtered);
		}
	}, 500);

	const fetchUsers = () => {
		axios
			.get(`${config.API_ENDPOINT}/users/${props.organization}/all`, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(res => {
				setUsers(res.data);
			})
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}

	const showDeleteDialog = (userId) => {
		setConfirmationMessage("Are you sure you want to delete this user?");

		setConfirmationOnClose(() => () => {
			setMenuAnchorEl(null);
			setConfirmationOpen(false);
		});

		setConfirmationOnYes(() => () => {
			deleteUser(userId);
			setMenuAnchorEl(null);
			setConfirmationMessage(false);
		});

		setConfirmationOpen(true);
	}

	const deleteUser = useDebouncedCallback((userId) => {
		axios
			.delete(`${config.API_ENDPOINT}/users/${props.organization}/${userId}`, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(() => {
				toast.success("Success!");
				setMenuAnchorEl(null);
				setConfirmationOpen(false);
				fetchUsers();
			})
			.catch((err) => {
				switch (err.response?.status) {
					case 404:
						toast.warn("You don't have enough permissions to delete this user");
						setMenuAnchorEl(null);
						setConfirmationOpen(false);
						break;
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}, 500, { leading: true });

	if (props.user.accessLevel > 2) return null;

	return (
		<div>
			<Card style={{ padding: 15 }}>
				<CardContent>
					<OutlinedInput
						required
						fullWidth
						name="search"
						type="text"
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						placeholder="Search by name"
						endAdornment={
							<InputAdornment position="end">
								{searchText === "" ? null : (
									<IconButton
										onClick={() => setSearchText("")}
									>
										<ClearIcon />
									</IconButton>
								)}
							</InputAdornment>
						}
						style={{ marginBottom: 50 }}
					/>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Photo</TableCell>
									<TableCell>Name</TableCell>
									<TableCell>Email</TableCell>
									<TableCell>Access Level</TableCell>
									<TableCell>Settings</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{filteredUsers.map((user) =>
									<TableRow key={user._id}>
										<TableCell>
											<Avatar
												src={user.avatarURL ? `${config.IMG_ENDPOINT}/uploads/${user.avatarURL}` : ""}
											/>
										</TableCell>
										<TableCell>{user.name}</TableCell>
										<TableCell>{user.email}</TableCell>
										<TableCell>{user.accessLevel}</TableCell>
										<TableCell>
											<IconButton
												onClick={(ev) => {
													setMenuAnchorEl(ev.currentTarget);
													setMenuUser(user._id);
												}}
											>
												<MoreVertIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<Menu
						anchorEl={menuAnchorEl}
						open={menuAnchorEl != null}
						keepMounted
						onClose={() => setMenuAnchorEl(null)}
					>
						<Link
							to={`/edit-user/${menuUser}`}
							style={{ color: "inherit", textDecoration: "inherit" }}
						>
							<MenuItem>View/Edit</MenuItem>
						</Link>
						<MenuItem
							onClick={() => showDeleteDialog(menuUser)}
						>
							Delete
						</MenuItem>
					</Menu>
				</CardContent>
			</Card>
			<ConfirmationDialog
				open={confirmationOpen}
				message={confirmationMessage}
				onYes={confirmationOnYes}
				onClose={confirmationOnClose}
			/>
		</div>
	);
}

export default Users;