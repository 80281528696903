import config from "../../utils/config";
import {
	Card,
	CardContent,
	Divider,
	Typography,
	Pagination,
} from "@mui/material";
import "./styles.css";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import axios from "axios";

const numRows = 10;

function EventsIframe() {
	const { organizationId } = useParams();

	const [sharedEvents, setSharedEvents] = useState([]);
	const [events, setEvents] = useState([]);
	const [page, setPage] = useState(0);
	const [totalRows, setTotalRows] = useState(0);
	const [lastPage, setLastPage] = useState(0);

	useEffect(() => {
		getEvents(0);
		getSharedEvents();
		getTotalEvents();
	}, [organizationId]); //eslint-disable-line

	const getSharedEvents = () => {
		axios
			.get(
				`${config.API_ENDPOINT}/events/shared/${organizationId}/all?active=true`
			)
			.then((res) => {
				setSharedEvents(res.data);
			})
			.catch(() => {
				toast.error("An unexpected error has occurred");
			});
	};

	const getEvents = (page) => {
		axios
			.get(
				`${config.API_ENDPOINT}/events/${organizationId}/all?active=true&shared=false&page=${page}&numRows=${numRows}`
			)
			.then((res) => {
				setEvents([...events, ...res.data]);
			})
			.catch(() => {
				toast.error("An unexpected error has occurred");
			});
	};

	const getTotalEvents = () => {
		axios
			.get(
				`${config.API_ENDPOINT}/events/${organizationId}/count?active=true&shared=false`
			)
			.then((res) => {
				setTotalRows(res.data.count);
			})
			.catch(() => {
				toast.error("An unexpected error has occurred");
			});
	};

	const sharedEventsUI = () => {
		if (sharedEvents.length === 0) return null;

		return (
			<div style={{ padding: 10 }}>
				{sharedEvents.map((event) => {
					let startDate = moment(event.parentEvent.datetime)
						.locale("en")
						.format("dddd, DD/MM/YYYY HH:mm");
					let endDate = event.parentEvent.datetimeEnd
						? moment(event.parentEvent.datetimeEnd)
							.locale("en")
							.format("dddd, DD/MM/YYYY HH:mm")
						: null;

					return (
						<Link
							to={`/iframes/event-details/shared/${event.organization}/${event._id}`}
						>
							<Card
								className="hoverPointer"
								style={{ paddingBottom: 20, marginTop: 20 }}
							>
								<CardContent>
									<div style={{ display: "inline-block" }}>
										{event.parentEvent.imageURL ? (
											<img
												alt=""
												style={{
													display: "inline-block",
													height: 100,
													width: 100,
													objectFit: "cover",
													borderRadius: 10,
												}}
												src={`${config.IMG_ENDPOINT}/uploads/${event.parentEvent.imageURL}`}
											/>
										) : null}
										<div style={{ display: "inline-block", marginLeft: 20 }}>
											<Typography
												style={{
													fontWeight: "bold",
													fontSize: 25,
													color: "#00aeef",
												}}
											>
												{event.parentEvent.name}
											</Typography>
											<Typography>
												<i>{event.parentEvent.location}</i>
											</Typography>
											<Typography style={{ marginTop: 10 }}>
												{event.parentEvent.briefDescription}
											</Typography>
										</div>
									</div>
									<div style={{ float: "right", textAlign: "right" }}>
										<Typography style={{ fontSize: 15 }}>
											{startDate}
										</Typography>
										{endDate ? (
											<Typography style={{ fontSize: 15 }}>
												to {endDate}
											</Typography>
										) : null}
										{event.parentEvent.esnCardRequired ? (
											<Typography style={{ marginTop: 10 }}>
												ESNcard holders only
											</Typography>
										) : null}
									</div>
								</CardContent>
							</Card>
						</Link>
					);
				})}
			</div>
		);
	};

	const eventsUI = () => {
		return (
			<div style={{ padding: 10 }}>
				{events.slice(page * numRows, (page + 1) * numRows).map((event) => {
					let startDate = moment(event.datetime)
						.locale("en")
						.format("dddd, DD/MM/YYYY HH:mm");
					let endDate = event.datetimeEnd
						? moment(event.datetimeEnd)
							.locale("en")
							.format("dddd, DD/MM/YYYY HH:mm")
						: null;

					return (
						<Link
							to={`/iframes/event-details/${event.organization}/${event._id}`}
						>
							<Card
								className="hoverPointer"
								style={{ paddingBottom: 20, marginTop: 20 }}
							>
								<CardContent>
									<div style={{ display: "inline-block" }}>
										{event.imageURL ? (
											<img
												alt=""
												style={{
													display: "inline-block",
													height: 100,
													width: 100,
													objectFit: "cover",
													borderRadius: 10,
												}}
												src={`${config.IMG_ENDPOINT}/uploads/${event.imageURL}`}
											/>
										) : null}
										<div style={{ display: "inline-block", marginLeft: 20 }}>
											<Typography
												style={{
													fontWeight: "bold",
													fontSize: 25,
													color: "#00aeef",
												}}
											>
												{event.name}
											</Typography>
											<Typography>
												<i>{event.location}</i>
											</Typography>
											<Typography style={{ marginTop: 10 }}>
												{event.briefDescription}
											</Typography>
										</div>
									</div>
									<div style={{ float: "right", textAlign: "right" }}>
										<Typography style={{ fontSize: 15 }}>
											{startDate}
										</Typography>
										{endDate ? (
											<Typography style={{ fontSize: 15 }}>
												to {endDate}
											</Typography>
										) : null}
										{event.esnCardRequired ? (
											<Typography style={{ marginTop: 10 }}>
												ESNcard holders only
											</Typography>
										) : null}
									</div>
								</CardContent>
							</Card>
						</Link>
					);
				})}
				<Pagination
					style={{ marginTop: 30, display: "flex", justifyContent: "center" }}
					count={Math.ceil(totalRows / numRows)}
					page={page + 1}
					onChange={(_, newPage) => {
						newPage--;
						if (newPage > lastPage) {
							getEvents(newPage);
							setLastPage(newPage);
						}

						setPage(newPage);
					}}
				/>
			</div>
		);
	};

	return (
		<div>
			{sharedEventsUI()}
			{sharedEvents.length > 0 ? <Divider /> : null}
			{eventsUI()}
		</div>
	);
}

export default EventsIframe;
