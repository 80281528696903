const { useRef, useEffect } = require("react");

const noop = () => { };

const useInterval = (callback, delay, immediate) => {
    const savedCallback = useRef(noop);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    });

    // Execute callback if immediate is set.
    useEffect(() => {
        if (!immediate || delay === null || delay === false) return;
        savedCallback.current();
    }, [delay, immediate]);

    // Set up the interval.
    useEffect(() => {
        if (delay === null || delay === false) return;
        const tick = () => savedCallback.current();
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [delay]);
};

export default useInterval;