import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import config from "../../utils/config";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import ClearIcon from "@mui/icons-material/Clear";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import { MoveDown } from "@mui/icons-material";
import EventPicker from "../EventPicker/EventPicker";

const numRows = 10;

const Wishlist = (props) => {
  const { eventId } = useParams();

  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchDate, setSearchDate] = useState(null);
  const [wishlistEntries, setWishlistEntries] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuEntry, setMenuEntry] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [confirmationOnYes, setConfirmationOnYes] = useState(null);
  const [confirmationOnClose, setConfirmationOnClose] = useState(null);
  const [eventPickerOpen, setEventPickerOpen] = useState(false);
  const [transferDialogOpen, setTransferDialogOpen] = useState(false);

  useEffect(() => {
    props.setTitle("View Wishlist");
  }, []); // eslint-disable-line

  useEffect(() => {
    setPage(0);
    setLastPage(0);
    if (eventId != null) fetchWishlistEntries(0);
  }, [props.organization, eventId]); // eslint-disable-line

  useEffect(() => {
    onSearchTextDateChanged();
  }, [searchText, searchDate]); // eslint-disable-line

  const onSearchTextDateChanged = useDebouncedCallback(() => {
    setPage(0);
    setLastPage(0);
    if (eventId != null) fetchWishlistEntries(0);
  }, 500);

  const handlePageChange = (_, newPage) => {
    if (newPage > lastPage) {
      fetchWishlistEntries(newPage);
      setLastPage(newPage);
    }

    setPage(newPage);
  };

  const fetchNumWishlistEntries = async () => {
    let q = `${config.API_ENDPOINT}/wishlist/${props.organization}/count?eventId=${eventId}`;

    if (searchText !== "") {
      q += `&search=${searchText}`;
    }

    if (searchDate) {
      q += `&searchDate=${searchDate.startOf("day").valueOf()}`;
    }

    axios
      .get(q, {
        headers: { "x-access-token": localStorage.getItem("access-token") },
      })
      .then((res) => setTotalRows(res.data.count))
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  };

  const fetchWishlistEntries = useDebouncedCallback((page) => {
    fetchNumWishlistEntries();

    let q = `${config.API_ENDPOINT}/wishlist/${props.organization}/all?page=${page}&numRows=${numRows}&eventId=${eventId}`;

    if (searchText !== "") {
      q += `&search=${searchText}`;
    }

    if (searchDate) {
      q += `&searchDate=${searchDate.startOf("day").valueOf()}`;
    }

    axios
      .get(q, {
        headers: { "x-access-token": localStorage.getItem("access-token") },
      })
      .then((res) => {
        if (page === 0) {
          setWishlistEntries(res.data);
        } else {
          setWishlistEntries([...wishlistEntries, ...res.data]);
        }
      })
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }
      });
  }, 500);

  const showDeleteDialog = (entryId) => {
    setConfirmationMessage("Are you sure you want to delete this entry?");
    setConfirmationOnClose(() => () => {
      setConfirmationOpen(false);
      setMenuAnchorEl(null);
    });
    setConfirmationOnYes(() => () => {
      setConfirmationOpen(false);
      setMenuAnchorEl(null);
      deleteEntry(entryId);
    });
    setConfirmationOpen(true);
  };

  const deleteEntry = useDebouncedCallback(
    (entryId) => {
      axios
        .delete(`${config.API_ENDPOINT}/wishlist/${props.organization}/${entryId}`, {
          headers: { "x-access-token": localStorage.getItem("access-token") },
        })
        .then(() => {
          toast.success("Success!");
          setMenuAnchorEl(null);
          setPage(0);
          setLastPage(0);
          setConfirmationOpen(false);
          fetchWishlistEntries(0);
        })
        .catch((err) => {
          switch (err.response?.status) {
            case 401:
              toast.warn("You don't have enough permissions to delete this entry");
              setConfirmationOpen(false);
              setMenuAnchorEl(null);
              break;
            default:
              toast.error("An unexpected error has occurred");
              break;
          }
        });
    },
    500,
    { leading: true }
  );

  const transferEntries = (id, shared) => {
    setTransferDialogOpen(true);
    axios
      .post(
        `${config.API_ENDPOINT}/wishlist/${props.organization}/transfer`,
        { fromEvent: eventId, toEvent: id, toShared: shared, endpoint: config.WEB_ENDPOINT },
        {
          headers: { "x-access-token": localStorage.getItem("access-token") },
        }
      )
      .then((res) => {
        const transferred = res.data.transferred;
        const total = res.data.total;

        if (transferred === total) {
          toast.success("All entries have been transferred successfully");
        } else {
          toast.warn(`${transferred} out of ${total} entries have been transferred`);
        }

        onSearchTextDateChanged(); // Reload
        setTransferDialogOpen(false);
      })
      .catch((err) => {
        switch (err.response?.status) {
          case 401:
            localStorage.removeItem("access-token");
            window.location.replace("/login");
            break;
          case 400:
            toast.error("There are no available seats in the destination event");
            break;
          default:
            toast.error("An unexpected error has occurred");
            break;
        }

        setTransferDialogOpen(false);
      });
  };

  return (
    <div>
      <Card sx={{ p: 3 }}>
        <CardContent>
          <DatePicker
            label="Search by date"
            inputVariant="outlined"
            required
            inputFormat="DD/MM/YYYY"
            value={searchDate}
            onChange={(date) => setSearchDate(date)}
            renderInput={(params) => <TextField {...params} sx={{ mb: 3 }} />}
          />
          {searchDate && (
            <IconButton style={{ marginLeft: 10 }} onClick={() => setSearchDate(null)}>
              <ClearIcon />
            </IconButton>
          )}
          <OutlinedInput
            required
            fullWidth
            name="search"
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search by Name/Passport/ESNcard"
            endAdornment={
              <InputAdornment position="end">
                {searchText === "" ? null : (
                  <IconButton onClick={() => setSearchText("")}>
                    <ClearIcon />
                  </IconButton>
                )}
              </InputAdornment>
            }
            style={{ marginBottom: 50 }}
          />
          <Button
            variant="contained"
            startIcon={<MoveDown />}
            sx={{ float: "right", mb: 3 }}
            onClick={() => setEventPickerOpen(true)}
          >
            Transfer
          </Button>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Event</TableCell>
                  <TableCell>Date/Time</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>VAT</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>ESNcard</TableCell>
                  <TableCell>Settings</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wishlistEntries.slice(page * numRows, page * numRows + numRows).map((entry) => {
                  let eventName = entry.shared ? entry.event?.parentEvent.name : entry.event?.name;

                  return (
                    <TableRow key={entry._id}>
                      <TableCell>{eventName ?? "(Event deleted)"}</TableCell>
                      <TableCell>{moment(entry.datetime).format("HH:mm DD/MM/YYYY")}</TableCell>
                      <TableCell>{entry.name}</TableCell>
                      <TableCell>{entry.email}</TableCell>
                      <TableCell>{entry.vat}</TableCell>
                      <TableCell>{entry.phone}</TableCell>
                      <TableCell>{entry.country}</TableCell>
                      <TableCell>{entry.esnCard}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={(ev) => {
                            setMenuEntry(entry);
                            setMenuAnchorEl(ev.currentTarget);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[numRows]}
              rowsPerPage={numRows}
              component="div"
              count={totalRows}
              page={page}
              onPageChange={handlePageChange}
            />
            <Menu
              anchorEl={menuAnchorEl}
              open={menuAnchorEl != null}
              keepMounted
              onClose={() => setMenuAnchorEl(null)}
            >
              <MenuItem onClick={() => showDeleteDialog(menuEntry?._id)}>Delete</MenuItem>
            </Menu>
          </TableContainer>
        </CardContent>
      </Card>
      <ConfirmationDialog
        open={confirmationOpen}
        message={confirmationMessage}
        onYes={confirmationOnYes}
        onClose={confirmationOnClose}
      />
      <Dialog open={transferDialogOpen}>
        <DialogContent>
          <p style={{ textAlign: "center" }}>
            Transferring... This may take a while depending on the size of the event, available
            seats and number of wishlist entries.
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        </DialogContent>
      </Dialog>
      <EventPicker
        open={eventPickerOpen}
        onClose={() => setEventPickerOpen(false)}
        onConfirm={(id, shared) => {
          transferEntries(id, shared);
          setEventPickerOpen(false);
        }}
        organization={props.organization}
        shared={false}
      />
    </div>
  );
};

export default Wishlist;
