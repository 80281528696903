import {
	Card,
	CardContent,
	Typography,
	Avatar,
	TextField,
	Button,
} from "@mui/material";
import config from "../../utils/config";
import { toast } from "react-toastify";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";

function TransactionDetails(props) {

	const navigate = useNavigate();
	const { transactionId } = useParams();

	const [transaction, setTransaction] = useState({});
	const [user, setUser] = useState({});
	const [student, setStudent] = useState({});
	const [eventPurchase, setEventPurchase] = useState({});
	const [relationTransaction, setRelationTransaction] = useState({});
	const [newValue, setNewValue] = useState(null);
	const [changeComment, setChangeComment] = useState("");
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [confirmationOnYes, setConfirmationOnYes] = useState(null);
	const [confirmationOnClose, setConfirmationOnClose] = useState(null);

	useEffect(() => {
		props.setTitle("Transaction Details");
	}, []); // eslint-disable-line

	useEffect(() => {
		fetchTransaction();
	}, [transactionId]); // eslint-disable-line

	const showDeleteDialog = (transaction) => {
		setConfirmationMessage("Are you sure you want to delete this transaction?");
		setConfirmationOnClose(() => () => setConfirmationOpen(false));
		setConfirmationOnYes(() => () => {
			deleteTransaction(transaction);
			setConfirmationOpen(false);
		});
		setConfirmationOpen(true);
	}

	const deleteTransaction = useDebouncedCallback(() => {
		axios
			.delete(`${config.API_ENDPOINT}/transactions/${props.organization}/${transactionId}`, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(() => {
				setConfirmationOpen(false);
				window.location.replace("/transactions");
			})
			.catch(err => {
				switch (err.response?.status) {
					case 401:
					case 404:
						toast.info("You have no permissions to delete this transaction");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}, 500, { leading: true });

	const getTypeName = (type) => {
		switch (type) {
			case "manual":
				return "Manual";
			case "esncard":
				return "ESNcard";
			case "event":
				return "Event";
			case "reimbursement":
				return "Reimbursement";
			default:
				return "";
		}
	};

	const fetchOriginalTransaction = (relation) => {
		let q = `${config.API_ENDPOINT}/transactions/${props.organization}/search?relationId=${relation}`;

		axios
			.get(q, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(res => setRelationTransaction(res.data))
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					case 404:
						// ignore
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	};

	const fetchTransaction = () => {
		let q = `${config.API_ENDPOINT}/transactions/${props.organization}/${transactionId}`;

		axios
			.get(q, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(res => {
				let transaction = res.data;

				setTransaction(transaction);
				setNewValue(transaction.value);

				if (transaction.user) {
					fetchUser(transaction.user);
				}

				if (transaction.relationId) {
					switch (transaction.type) {
						case "esncard":
							fetchStudent(transaction.relationId);
							break;
						case "reimbursement":
							fetchOriginalTransaction(transaction.relationId);
							break;
						case "event":
							fetchPurchase(transaction.relationId);
							break;
						default:
							return;
					}
				}
			})
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					case 404:
						// ignore
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}

	const fetchUser = (userId) => {
		axios
			.get(`${config.API_ENDPOINT}/users/${props.organization}/${userId}`, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(res => setUser(res.data))
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}

	const fetchPurchase = (id) => {
		axios
			.get(`${config.API_ENDPOINT}/event-purchases/${props.organization}/${id}`, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(res => setEventPurchase(res.data))
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}

	const fetchStudent = (studentId) => {
		axios
			.get(`${config.API_ENDPOINT}/students/${props.organization}/${studentId}`, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(res => setStudent(res.data))
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}

	const changeValue = useDebouncedCallback(() => {
		if (
			transaction.value === newValue ||
			!newValue ||
			changeComment === ""
		) {
			toast.warning("You must fill all the required fields. Value must also be different than the original one.");
			return;
		}

		axios
			.post(
				`${config.API_ENDPOINT}/transactions/${props.organization}/${transactionId}`,
				{ value: newValue, changeComment },
				{ headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(() => {
				toast.success("Success!");
				fetchTransaction();
			})
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}, 500, { leading: true });

	const reimburse = useDebouncedCallback(() => {
		axios
			.post(
				`${config.API_ENDPOINT}/transactions/${props.organization}/${transactionId}/reimburse`,
				{},
				{ headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(() => {
				toast.success("Success!");
				navigate("/transactions");
			})
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}, 500, { leading: true });

	const getRelationTitle = () => {
		switch (transaction.type) {
			case "esncard":
				return "Student: ";
			case "event":
				return "Event Purchase: ";
			case "reimbursement":
				return "Reimbursed Transaction: ";
			default:
				return "";
		}
	}

	const getRelationContent = () => {
		switch (transaction.type) {
			case "esncard":
				return (
					<p>
						{student._id ? (
							<div>
								<Link
									to={`/edit-student/${student._id}`}
									style={{ color: "black" }}
								>
									{student.name}
								</Link>{" "}
								({student.email})
							</div>
						) : (
							"<DELETED>"
						)}
					</p>
				);
			case "reimbursement":
				return (
					<p>
						{relationTransaction._id ? (
							<div>
								<Link
									to={
										`/transaction-details/${relationTransaction._id}`
									}
									style={{ color: "black" }}
								>
									{relationTransaction.description}
								</Link>{" "}
								(
								{moment(relationTransaction.datetime).format(
									"DD/MM/YYYY HH:mm"
								)}
								)
							</div>
						) : (
							"<DELETED>"
						)}
					</p>
				);
			case "event":
				return (
					<p>
						{eventPurchase._id ? (
							<Link
								to={`/event-purchase-details/${eventPurchase._id}`}
								style={{ color: "black" }}
							>
								View Purchase
							</Link>
						) : (
							"<DELETED>"
						)}
					</p>
				);
			default:
				return "";
		}
	}

	if (props.user.accessLevel > 2) return null;

	return (
		<div>
			<Card style={{ padding: 15 }}>
				<CardContent>
					<Typography style={{ fontWeight: "bold", fontSize: 40 }}>
						{transaction.description}
					</Typography>
					<Typography style={{ fontSize: 20, marginBottom: 50 }}>
						{moment(transaction.datetime).format(
							"DD/MM/YYYY HH:mm"
						)}
					</Typography>
					<Typography style={{ marginBottom: 20 }}>
						<strong>Transaction Type: </strong>
						{getTypeName(transaction.type)}
					</Typography>
					{transaction.value ? (
						<Typography style={{ marginBottom: 20 }}>
							<strong>Value: </strong>
							<span
								style={
									transaction.value > 0
										? { color: "green" }
										: { color: "red" }
								}
							>
								{transaction.value > 0
									? `+${transaction.value.toFixed(2)}€`
									: `${transaction.value.toFixed(2)}€`}
							</span>
						</Typography>
					) : null}
					{transaction.user ? (
						<div style={{ marginBottom: 20 }}>
							<strong>Sponsor:</strong>
							<div
								style={{
									marginTop: 5,
									display: "flex",
									alignItems: "center",
								}}
							>
								<Avatar
									src={user.avatarURL != null ? `${config.IMG_ENDPOINT}/uploads/${user.avatarURL}` : null}
									style={{ marginRight: 10 }}
								/>
								<p>
									{user.name} ({user.email})
								</p>
							</div>
						</div>
					) : null}
					{transaction.changeComment ? (
						<div style={{ marginBottom: 20 }}>
							<strong>Sponsor Comment:</strong>
							<div
								style={{
									marginTop: 5,
									display: "flex",
									alignItems: "center",
								}}
							>
								<p>{transaction.changeComment}</p>
							</div>
						</div>
					) : null}
					{transaction.relationId ? (
						<div style={{ marginBottom: 20 }}>
							<strong>{getRelationTitle()}</strong>
							{getRelationContent()}
						</div>
					) : null}
					{transaction.invoiceName ? (
						<div style={{ marginBottom: 20 }}>
							<strong>Invoice:</strong>
							<p>{transaction.invoiceName}</p>
						</div>
					) : null}
					<Typography
						style={{ marginTop: 50, marginBottom: 20, fontWeight: "bold" }}
					>
						Settings:
					</Typography>
					{transaction.invoiceName ? (
						<Typography
							style={{ marginTop: 5, color: "red", fontWeight: "bold" }}
						>
							WARNING! This transaction has a linked invoice. If you change its value or delete it,
							you must manually take the required billing actions on your Vendus portal.
						</Typography>
					) : null}
					<Card
						hidden={props.user.accessLevel > 1}
						style={{ marginBottom: 30 }}
					>
						<CardContent>
							<Typography style={{ fontWeight: "bold" }}>
								Change Value
							</Typography>
							<TextField
								variant="outlined"
								fullWidth
								label="Transaction Value"
								name="value"
								type="number"
								inputProps={{ step: "0.01" }}
								autoComplete="off"
								value={newValue ?? ""}
								onChange={(e) => setNewValue(e.target.value !== "" ? parseFloat(e.target.value) : null)}
								style={{ marginTop: 20, marginBottom: 10 }}
							/>
							<TextField
								variant="outlined"
								fullWidth
								label="Sponsor Comment"
								name="value"
								type="text"
								required
								autoComplete="off"
								value={changeComment}
								onChange={(e) => setChangeComment(e.target.value)}
								style={{ marginBottom: 5 }}
							/>
							<Typography style={{ marginBottom: 5, fontSize: 10 }}>
								<strong>
									By changing the transaction's value, you will become its sponsor.
								</strong>
							</Typography>
							<Button variant="contained" onClick={changeValue}>
								CONFIRM
							</Button>
							<br />
						</CardContent>
					</Card>
					{(transaction.type === "esncard" || transaction.type === "event") && transaction.value > 0 ? (
						<Button variant="contained" onClick={reimburse}>
							CREATE REIMBURSEMENT
						</Button>
					) : null}
					<br />
					<Button
						variant="contained"
						onClick={showDeleteDialog}
						style={{ marginTop: 20 }}
					>
						DELETE TRANSACTION
					</Button>
				</CardContent>
			</Card>
			<ConfirmationDialog
				open={confirmationOpen}
				message={confirmationMessage}
				onYes={confirmationOnYes}
				onClose={confirmationOnClose}
			/>
		</div>
	);
}

export default TransactionDetails;
