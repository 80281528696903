import React, { useRef, useState } from 'react';
import { Badge, IconButton, List, ListItem, Popover, Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import useInterval from "../../hooks/use-interval";
import axios from "axios";
import config from '../../utils/config';
import { toast } from 'react-toastify';
import Notification from './Notification';
import NotificationDialog from './NotificationDialog';
import headers from '../../utils/headers';

function NotificationIcon() {
    const [open, setOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [detailed, setDetailed] = useState(null);

    useInterval(() => {
        axios
            .get(`${config.API_ENDPOINT}/notifications/mine?limit=10`, headers())
            .then((res) => {
                setNotifications(res.data);
            })
            .catch(() => toast.error("An unexpected error has occurred"))
    }, 60000, true);

    const buttonRef = useRef();

    return (
        <div>
            <IconButton sx={{ color: "white" }} ref={buttonRef} onClick={() => setOpen(!open)}>
                <Badge badgeContent={notifications.filter(n => !n.read).length} color="secondary">
                    <NotificationsIcon />
                </Badge>
            </IconButton>

            <Popover
                open={open}
                anchorEl={buttonRef.current}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <List sx={{ maxHeight: 800 }}>
                    {notifications.length === 0 ? <Typography sx={{ p: 2 }}>There are no notifications for you to see right now :)</Typography> : null}
                    {notifications.map((notification, index) =>
                        <ListItem key={index} sx={{ p: 0, pb: 1, pt: 1 }}>
                            <Notification notification={notification} onClick={() => setDetailed(notification)} />
                        </ListItem>
                    )}
                </List>
            </Popover>

            <NotificationDialog notification={detailed} open={detailed != null} onClose={() => setDetailed(null)} />
        </div>
    )
}

export default NotificationIcon