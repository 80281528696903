import React, { useState } from "react";
import HelpDialog from "./HelpDialog";
import { HelpCenter } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const HelpIcon = ({ organization }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <IconButton sx={{ color: "white" }} onClick={() => setOpen(!open)}>
        <HelpCenter />
      </IconButton>

      <HelpDialog open={open} onClose={() => setOpen(false)} organization={organization} />
    </div>
  );
};

export default HelpIcon;
