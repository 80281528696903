import React from "react";
import Login from "./components/Login/Login";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Main from "./components/Main/Main";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import EventsIframe from "./iframes/Events/EventsIframe";
import EventDetails from "./iframes/Events/EventDetails";
import StudentRequest from "./iframes/StudentRequest/StudentRequest";
import TermsConditions from "./iframes/TermsConditions/TermsConditions";
import PurchaseInfo from "./iframes/Purchase/PurchaseInfo";
import PayPurchase from "./components/PayPurchase/PayPurchase";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#D1512D",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/iframes/events/:organizationId" element={<EventsIframe />} />
            <Route
              exact
              path="/iframes/event-details/shared/:organizationId/:eventId"
              element={<EventDetails shared={true} />}
            />
            <Route
              exact
              path="/iframes/event-details/shared/:organizationId/:eventId/internal"
              element={<EventDetails shared={true} internal={true} />}
            />
            <Route
              exact
              path="/iframes/event-details/:organizationId/:eventId"
              element={<EventDetails />}
            />
            <Route
              exact
              path="/iframes/event-details/:organizationId/:eventId/internal"
              element={<EventDetails internal={true} />}
            />
            <Route exact path="/iframes/student-request/:esnCountry" element={<StudentRequest />} />
            <Route
              exact
              path="/iframes/purchase/:type/:organizationId"
              element={<PurchaseInfo />}
            />
            <Route
              exact
              path="/purchase/:organizationId/:purchaseId/pay"
              element={<PayPurchase />}
            />
            <Route
              exact
              path="/purchase/:organizationId/:purchaseId/cancel"
              element={<PayPurchase cancel={true} />}
            />
            <Route exact path="/terms-conditions/:organizationId" element={<TermsConditions />} />
            <Route path="*" element={<Main />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
