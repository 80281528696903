import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import config from "../../utils/config";
import { useDebouncedCallback } from "use-debounce";
import { useState } from "react";

function EgmExportDialog(props) {
	const [eventKey, setEventKey] = useState("");
	const [loading, setLoading] = useState(false);

	const close = () => {
		setEventKey("");
		props.onClose();
	}

	const exportData = useDebouncedCallback(() => {
		if (eventKey === "") {
			toast.warning("Event Key cannot be empty.");
			return;
		}

		setLoading(true);

		axios
			.post(`${config.API_ENDPOINT}/events/${props.organization}/${props.event}/egm-export`, { egmToken: eventKey }, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then((res) => {
				setLoading(false);
				if (res.status === 200) {
					toast.success("Success!");
				} else if (res.status === 206) {
					toast.warning(
						"Not all spots were updated successfully. Contact an administrator."
					);
				}

				close()
			})
			.catch(err => {
				setLoading(false);
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred. Please verify your event key.");
						break;
				}
			});
	}, 500, { leading: true });

	return (
		<Dialog
			open={props.open}
			onClose={close}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Export to ERS</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Export paid participants to ERS. This operation may take up to 3 minutes, depending on the number of participants.
				</DialogContentText>
				<TextField
					variant="outlined"
					required
					fullWidth
					label="Event Key"
					name="egmEventKey"
					type="password"
					autoComplete="off"
					value={eventKey}
					onChange={(e) => setEventKey(e.target.value)}
					style={{ marginBottom: 20 }}
				/>
			</DialogContent>
			{loading ? (
				<CircularProgress />
			) : (
				<DialogActions>
					<Button onClick={exportData} color="primary" autoFocus>
						Export
					</Button>
				</DialogActions>
			)
			}
		</Dialog >
	);
}

export default EgmExportDialog;