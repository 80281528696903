import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import config from "../../utils/config";
import { useDebouncedCallback } from "use-debounce";

const HelpDialog = ({ open, onClose, organization }) => {
  const [type, setType] = useState("Suggestion");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const close = () => {
    setType("Suggestion");
    setTitle("");
    setMessage("");
    onClose();
  };

  const send = useDebouncedCallback(
    () => {
      if (title === "" || message === "") {
        toast.error("Please fill out all fields.");
        return;
      }

      axios
        .post(
          `${config.API_ENDPOINT}/support/ticket`,
          { organization, type, title, message },
          { headers: { "x-access-token": localStorage.getItem("access-token") } }
        )
        .then(() => {
          toast.success("Ticket sent!");
          close();
        })
        .catch((err) => {
          switch (err.response?.status) {
            case 401:
              localStorage.removeItem("access-token");
              window.location.replace("/login");
              break;
            default:
              toast.error("An unexpected error has occurred");
              break;
          }
        });
    },
    1000,
    { leading: true }
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Send a ticket</DialogTitle>
      <DialogContent>
        <Box sx={{ p: 1, minWidth: 250 }}>
          <FormControl fullWidth sx={{ mb: 2 }} required>
            <InputLabel id="type-label">Type</InputLabel>
            <Select
              labelId="type-label"
              label="Type"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <MenuItem value="Suggestion">Suggestion</MenuItem>
              <MenuItem value="Bug">Bug</MenuItem>
              <MenuItem value="FeatureRequest">FeatureRequest</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Title"
            autoComplete="off"
            onChange={(e) => setTitle(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Message"
            autoComplete="off"
            onChange={(e) => setMessage(e.target.value)}
            multiline
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={send} color="primary">
          Send
        </Button>
        <Button onClick={close} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HelpDialog;
