import { TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { toast } from "react-toastify";
import config from "../../utils/config";

const costPerStudent = 0.01;

const emptyChartData = {
	labels: [],
	datasets: [
		{
			data: [],
			backgroundColor: [],
		},
	],
};

function Finance(props) {
	const [timestamp, setTimestamp] = useState(moment());
	const [totalCost, setTotalCost] = useState(0);
	const [activeStudentData, setActiveStudentData] = useState(emptyChartData);

	useEffect(() => {
		props.setTitle("Finance");
	}, []); //eslint-disable-line

	useEffect(() => {
		axios.get(`${config.API_ENDPOINT}/metrics/monthly/${props.organization}?metricName=active-students&timestamp=${timestamp.valueOf()}`, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(res => {
				let max = 0;

				let data = {
					labels: [],
					datasets: [
						{
							label: "Number of Active Students",
							data: []
						},
					],
				};
				for (let metric of res.data) {
					if (metric.value > max) max = metric.value;
					data.datasets[0].data.push(metric.value);
					data.labels.push(moment(metric.timestamp).format("DD/MM HH:mm"))
				}
				setTotalCost(max * costPerStudent);
				setActiveStudentData(data);
			})
			.catch((err) => {
				toast.error("An unexpected error has occurred");
			});
	}, [timestamp, props.organization]); //eslint-disable-line

	if (props.user.accessLevel > 1) return null;

	return <div>
		<DatePicker
			views={['year', 'month']}
			label="Month"
			onChange={ts => setTimestamp(ts)}
			value={timestamp}
			renderInput={params => <TextField {...params} sx={{ mt: 2, mb: 2 }} />}
		/>
		<Typography sx={{ fontSize: 32, fontWeight: "bold", mb: 3 }}>{timestamp.isSameOrAfter(moment().startOf("month")) ? "Expected Cost" : "Cost"}: {totalCost.toFixed(2)}€</Typography>
		<Line data={activeStudentData} />
	</div>;
}

export default Finance;