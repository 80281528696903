import config from "../../utils/config";
import { Typography, Card, CardContent, Button, Grid } from "@mui/material";
import moment from "moment";
import parse from "html-react-parser";
import BuyEvent from "./BuyEvent";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

function EventDetails(props) {
  const { organizationId, eventId } = useParams();

  const [event, setEvent] = useState({});
  const [buying, setBuying] = useState(false);
  const [wishlisting, setWishlisting] = useState(false);
  const [specialPrice, setSpecialPrice] = useState(null);

  useEffect(() => {
    fetchEvent();
  }, []); //eslint-disable-line

  const fetchEvent = () => {
    let url = `${config.API_ENDPOINT}/events/${organizationId}/${eventId}`;

    if (props.shared) {
      url = `${config.API_ENDPOINT}/events/shared/${organizationId}/${eventId}`;
    }

    axios
      .get(url)
      .then((res) => {
        let event = res.data;

        if (props.shared) {
          let sharedEvent = event;
          event = sharedEvent.parentEvent;
          event.organization = sharedEvent.organization;
          event._id = sharedEvent._id;
          event.shared = false;
          event.seatsRemaining = sharedEvent.seatsRemaining;
          if (event.regularPrice != null) event.regularPrice += sharedEvent.extraPrice;
          if (event.esnCardPrice != null) event.esnCardPrice += sharedEvent.extraEsnCardPrice;
          if (event.esnCardBuyPrice != null)
            event.esnCardBuyPrice += sharedEvent.extraDiscountPrice;
        }

        setEvent(event);
      })
      .catch(() => {
        toast.error("An unexpected error has occurred");
      });
  };

  const showSpecialPrice = () => {
    if (event.esnCardBuyPrice != null && event.esnCardBuyPrice < event.regularPrice) {
      toast.info("Price Updated!");
      setSpecialPrice(event.esnCardBuyPrice);
    }
  };

  const hideSpecialPrice = () => {
    if (specialPrice != null) {
      toast.info("Price Updated!");
      setSpecialPrice(null);
    }
  };

  if ((!props.internal && !event.active) || event.shared) {
    return <div></div>;
  }

  let startDate = event.datetime
    ? moment(event.datetime).locale("en").format("dddd, DD/MM/YYYY HH:mm")
    : null;

  let endDate = event.datetimeEnd
    ? moment(event.datetimeEnd).locale("en").format("dddd, DD/MM/YYYY HH:mm")
    : null;

  let limitDate = event.datetimeLimit
    ? moment(event.datetimeLimit).locale("en").format("dddd, DD/MM/YYYY HH:mm")
    : null;

  const limitPassed = limitDate && event.datetimeLimit.valueOf() < moment().valueOf();

  return (
    <div style={{ padding: 20 }}>
      {event.imageURL ? (
        <img
          alt=""
          height={200}
          width="100%"
          style={{ objectFit: "cover" }}
          src={`${config.IMG_ENDPOINT}/uploads/${event.imageURL}`}
        />
      ) : null}
      <Typography
        style={{
          fontWeight: "bold",
          fontSize: 35,
          color: "#00aeef",
          marginTop: 20,
          marginBottom: 15,
          textAlign: "center",
        }}
      >
        {event.name}
      </Typography>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Typography>
            <strong>Location: </strong>
            {event.location}
          </Typography>
          <Typography style={{ marginTop: 10 }}>
            <strong>Starts at: </strong>
            {startDate}
          </Typography>
          <Typography hidden={!endDate}>
            <strong>Ends at: </strong>
            {endDate}
          </Typography>
          <br />
          {event.description ? parse(event.description) : null}
        </Grid>
        <Grid item xs={12} md={4}>
          <Card style={{ backgroundColor: "#3b2f74" }}>
            <CardContent>
              <Typography align="center" style={{ color: "white" }}>
                {specialPrice ? "New ESNcard Special Price" : "Price"}
              </Typography>
              <Typography align="center" style={{ marginBottom: 10, fontSize: 30, color: "white" }}>
                <strong>
                  {specialPrice != null ? specialPrice.toFixed(2) : event.regularPrice?.toFixed(2)}{" "}
                  €
                </strong>
              </Typography>
            </CardContent>
          </Card>

          {!event.esnCardRequired && event.esnCardPrice != null && specialPrice == null ? (
            <Card style={{ backgroundColor: "#00aeef", marginTop: 10 }}>
              <CardContent>
                <Typography align="center" style={{ color: "white" }}>
                  Price for ESNcard holders
                </Typography>
                <Typography
                  align="center"
                  style={{ marginBottom: 10, fontSize: 30, color: "white" }}
                >
                  <strong>{event.esnCardPrice.toFixed(2)} €</strong>
                </Typography>
              </CardContent>
            </Card>
          ) : null}

          {event.esnCardRequired ? (
            <Typography align="center" style={{ marginTop: 10 }}>
              <strong>Only for ESNcard holders</strong>
            </Typography>
          ) : null}

          {limitDate ? (
            <Typography align="center" style={{ marginTop: 10 }}>
              <strong>Buy until: </strong> <br /> {limitDate}
            </Typography>
          ) : null}

          {event.seats != null ? (
            <Typography align="center" style={{ marginTop: 10 }}>
              <strong>Seats Remaining: </strong> <br /> {event.seatsRemaining}
            </Typography>
          ) : null}

          {event.name &&
            !buying &&
            (event.seatsRemaining == null || event.seatsRemaining > 0) &&
            !limitPassed && (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ color: "white", marginTop: 10 }}
                onClick={() => setBuying(true)}
              >
                PURCHASE
              </Button>
            )}

          {event.name &&
            event.wishlistEnabled &&
            !buying &&
            (event.seatsRemaining === 0 || limitPassed) && (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ color: "white", marginTop: 10 }}
                onClick={() => {
                  setBuying(true);
                  setWishlisting(true);
                }}
              >
                WISHLIST
              </Button>
            )}
        </Grid>
      </Grid>
      <div style={{ paddingTop: 20 }}>
        {buying && (
          <BuyEvent
            event={event}
            wishlist={wishlisting}
            shared={props.shared}
            showSpecial={showSpecialPrice}
            hideSpecial={hideSpecialPrice}
          />
        )}
      </div>
    </div>
  );
}

export default EventDetails;
