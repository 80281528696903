/**
 * Validates if the given fields are not null, false or empty
 * @param {Array} fields Fields to validate
 * @returns True if valid, false if invalid
 */
function validateSimple(fields) {
	for (let field of fields) {
		if (field == null || field === "") {
			return false;
		}
	}
	return true;
};

const fieldValidator = { validateSimple };
export default fieldValidator;