import React, { useCallback, useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';
import { Box, Dialog, DialogContent } from '@mui/material';

function QrReader({ onResult, open, onClose }) {
    const [noCamera, setNoCamera] = useState(false);
    let scanner = useRef();

    const container = useCallback(element => {
        if (element == null) return;

        scanner.current = new QrScanner(element, handleScan, {});
        scanner.current.start();
    }, []); // eslint-disable-line

    const handleClose = () => {
        scanner.current.stop();
        scanner.current.destroy();
        onClose();
    }

    const handleScan = (data) => {
        handleClose();
        onResult(data);
    }

    useEffect(() => {
        const checkNoCamera = async () => {
            let hasCamera = await QrScanner.hasCamera();
            setNoCamera(!hasCamera);
        };

        checkNoCamera();
    });

    if (noCamera) {
        return <Box>
            The browser was unable to find a camera.
        </Box>;
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
                <Box>
                    <video width="100%" ref={container} />
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default QrReader;