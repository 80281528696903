import {
	Card,
	CardContent,
	Grid,
	TextField,
	Select,
	FormControl,
	InputLabel,
	MenuItem,
	Typography,
	IconButton,
	Avatar,
	Button,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import config from "../../utils/config";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";
import ChangePasswordDialog from "../ChangePasswordDialog/ChangePasswordDialog";
import { useState, useEffect } from "react";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";
import { useParams } from "react-router-dom";
import { validateEmail } from "../../utils/email-validator";

function AddUser(props) {
	const { editUserId } = useParams();

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [accessLevel, setAccessLevel] = useState("");
	const [file, setFile] = useState(null);
	const [imageInputValue, setImageInputValue] = useState("");
	const [initialPicture, setInitialPicture] = useState(null);
	const [changingPassword, setChangingPassword] = useState(false);
	const [organization, setOrganization] = useState([]);

	useEffect(() => {
		if (editUserId) {
			props.setTitle("Edit User");
		} else {
			props.setTitle("Add User");
		}
	}, [editUserId]); //eslint-disable-line

	useEffect(() => {
		if (editUserId) {
			fetchUserInfo();
		} else {
			setName("");
			setEmail("");
			setAccessLevel("");
			setFile(null);
			setInitialPicture(null);
			setChangingPassword(false);
			setOrganization([]);
		}
	}, [editUserId]); //eslint-disable-line

	const fetchUserInfo = () => {
		axios
			.get(`${config.API_ENDPOINT}/users/${props.organization}/${editUserId}`, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(res => {
				setName(res.data.name);
				setEmail(res.data.email);
				setAccessLevel(parseInt(res.data.accessLevel));
				setInitialPicture(res.data.avatarURL ? `${config.IMG_ENDPOINT}/uploads/${res.data.avatarURL}` : null);
				setOrganization(res.data.organization);
			})
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}

	const submit = useDebouncedCallback(() => {
		if (
			name === "" ||
			email === "" ||
			accessLevel === "" ||
			(organization.length === 0 && accessLevel !== 0)
		) {
			toast.warning("You must fill all the required fields");
			return;
		}

		if (!validateEmail(email)) {
			toast.warning("Invalid email");
			return;
		}

		let data = new FormData();
		data.append("name", name);
		data.append("email", email);
		data.append("accessLevel", accessLevel);
		if (accessLevel !== 0) {
			data.append("organization", JSON.stringify(organization));
		}

		if (file != null) {
			data.append("image", file);
		}

		let q = `${config.API_ENDPOINT}/users/create`;
		if (editUserId != null) {
			q = `${config.API_ENDPOINT}/users/${props.organization}/${editUserId}`;

			if (initialPicture == null && file == null) {
				data.append("deleteAvatar", true);
			}
		}

		axios
			.post(q, data, {
				headers: {
					"Content-Type": "multipart/form-data",
					"x-access-token": localStorage.getItem("access-token"),
				}
			})
			.then(() => {
				if (!editUserId) {
					setName("");
					setEmail("");
					setAccessLevel("");
					setFile(null);
					setOrganization([]);
				}
				toast.success("Success!");
			})
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					case 409:
						toast.warning("This email is in use");
						break;
					case 413:
						toast.warning("The image you sent is too big.");
						break;
					case 404:
						toast.warning("You cannot edit this user");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}, 500, { leading: true });

	const resetPassword = useDebouncedCallback(() => {
		axios
			.get(`${config.API_ENDPOINT}/users/${props.organization}/${editUserId}/reset-password`, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(() => toast.success("Success! The user will receive an email soon."))
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}, 500, { leading: true });

	const accessLevelSelectorUI = () => {
		if (editUserId != null && accessLevel != null && accessLevel < props.user.accessLevel) {
			return <Typography>Access Level: {accessLevel}</Typography>
		}

		let availableLevels = [];
		for (
			let i = props.user.accessLevel + 1;
			i <= config.MAX_ACCESS_LEVEL;
			i++
		) {
			availableLevels.push(i);
		}

		return (
			<FormControl fullWidth style={{ marginTop: 20 }} required>
				{editUserId ? null : <InputLabel id="access-level-label">Access Level</InputLabel>}
				<Select
					label="Access Level"
					labelId="access-level-label"
					value={accessLevel}
					onChange={(e) => setAccessLevel(e.target.value)}
				>
					{availableLevels.map((level) => (
						<MenuItem key={level} value={level}>{level}</MenuItem>
					))}
				</Select>
			</FormControl>
		);
	}

	const previewUI = () => {
		return (
			<Grid item xs={12} md={6}>
				<Typography>
					<strong>Preview</strong>
				</Typography>
				<Avatar
					style={{ height: 100, width: 100, marginTop: 20 }}
					src={
						file != null
							? URL.createObjectURL(file)
							: initialPicture
					}
				></Avatar>
				<Typography style={{ marginTop: 20 }}>
					<strong>Name: </strong> {name}
				</Typography>
				<Typography style={{ marginTop: 20 }}>
					<strong>Email: </strong> {email}
				</Typography>
				<Typography style={{ marginTop: 20 }}>
					<strong>Access Level: </strong> {accessLevel}
				</Typography>
				<Button
					style={{ marginTop: 50, color: "white" }}
					variant="contained"
					color="primary"
					onClick={submit}
				>
					{editUserId ? "UPDATE" : "CREATE USER"}
				</Button>
				{editUserId ? (
					<Button
						style={{ marginTop: 50, marginLeft: 10, color: "white" }}
						variant="contained"
						color="primary"
						onClick={resetPassword}
					>
						RESET PASSWORD
					</Button>
				) : null}
			</Grid>
		);
	}

	const handleClearPhoto = () => {
		setFile(null);
		setInitialPicture(null);
		setImageInputValue("");
	}

	const addFormUI = () => {
		return (
			<Grid item xs={12} md={6}>
				<TextField
					variant="outlined"
					required
					fullWidth
					label="Email"
					name="email"
					autoFocus
					type="email"
					autoComplete="off"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					style={{ marginBottom: 20 }}
				/>
				<TextField
					variant="outlined"
					required
					fullWidth
					label="Name"
					name="name"
					type="text"
					autoComplete="off"
					value={name}
					onChange={(e) => setName(e.target.value)}
					style={{ marginBottom: 50 }}
				/>

				<input
					accept="image/jpg, image/jpeg, image/png"
					id="icon-button-file"
					type="file"
					hidden
					value={imageInputValue}
					onChange={(e) => {
						setFile(e.target.files[0]);
						setImageInputValue(e.target.value);
					}}
				/>
				<label htmlFor="icon-button-file">
					Profile Picture
					<IconButton
						color="primary"
						aria-label="upload picture"
						component="span"
					>
						<PhotoCamera />
					</IconButton>
				</label>

				{file || initialPicture ? (
					<IconButton onClick={handleClearPhoto}>
						<ClearIcon />
					</IconButton>
				) : null}

				{props.user._id !== editUserId ? (
					<>
						<Typography style={{ marginTop: 20 }}>
							<strong>Organization (min. 1):</strong>
						</Typography>
						{props.organizations.map((org) => (
							<div key={org._id}>
								<FormControlLabel
									style={{ marginBottom: 5 }}
									value="start"
									control={
										<Checkbox
											color="primary"
											checked={organization.includes(org._id)}
											onChange={(e) => {
												let r = [...organization];
												if (e.target.checked) {
													r.push(org._id);
												} else {
													r.splice(r.indexOf(org._id), 1);
												}

												setOrganization(r);
											}}
										/>
									}
									label={org.name}
									labelPlacement="end"
								/>
								<br />
							</div>
						))}
					</>
				) : null}

				{props.user._id !== editUserId
					? accessLevelSelectorUI()
					: null}
				{props.user._id !== editUserId ? (
					<div>
						<Typography style={{ fontWeight: "bold", marginTop: 50 }}>
							Access Levels:
						</Typography>
						<p>0 - Administrator (Full access)</p>
						<p>1 - Organization Administrator (Full access to the organization)</p>
						<p>
							2 - Organization Manager (Statistics and level 3 permissions)
						</p>
						<p>3 - Events and Students Manager</p>
						<p>4 - Box Office Manager (Accept payments and add students)</p>
					</div>
				) : null}
				<br />
				{props.user._id === editUserId ? (
					<Button
						style={{ marginTop: 20 }}
						variant="contained"
						onClick={() => setChangingPassword(true)}
					>
						CHANGE PASSWORD
					</Button>
				) : null}
			</Grid>
		);
	}

	if (props.user.accessLevel > 1 && (editUserId == null || editUserId !== props.user._id)) return null;

	return (
		<div>
			<Card style={{ padding: 15 }}>
				<CardContent>
					<Grid container spacing={10}>
						{addFormUI()}
						{previewUI()}
					</Grid>
				</CardContent>
			</Card>

			<ChangePasswordDialog
				open={changingPassword}
				onClose={() => setChangingPassword(false)}
			/>
		</div>
	);
}

export default AddUser;