import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    DialogContentText,
    DialogActions,
    Typography,
} from "@mui/material";
import moment from "moment";
import parse from "html-react-parser";
import axios from "axios";
import { useEffect } from "react";
import config from "../../utils/config";
import headers from "../../utils/headers";

function NotificationDialog({ open, onClose, notification }) {
    useEffect(() => {
        if (notification == null || notification.read) return;

        axios
            .post(`${config.API_ENDPOINT}/notifications/${notification._id}/read`, {}, headers())
            .then(() => notification.read = true)
            .catch(() => {
                // ignore 
            });

    }, [notification]);

    if (notification == null) return null;

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>{notification.title}</DialogTitle>
            <DialogContent>
                <Typography sx={{ fontSize: 12, mb: 2 }} color="text.secondary">{moment(notification.datetime).format("DD/MM/YYYY HH:mm")}</Typography>
                <DialogContentText>
                    {parse(notification.message)}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default NotificationDialog;