import {
	Button,
	Card,
	CardContent,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import config from "../../utils/config";
import parse from "html-react-parser";
import moment from "moment";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";
import { useParams } from "react-router-dom";

function AddSharedEvent(props) {
	const { editEventId } = useParams();

	const [eventList, setEventList] = useState([]);
	const [sharedEvent, setSharedEvent] = useState("");
	const [extraPrice, setExtraPrice] = useState(0);
	const [extraEsnCardPrice, setExtraEsnCardPrice] = useState(0);
	const [extraDiscountPrice, setExtraDiscountPrice] = useState(0);

	useEffect(() => {
		if (editEventId) {
			props.setTitle("Edit Shared Event");
		} else {
			props.setTitle("Join Shared Event");
		}
	}, [editEventId]); //eslint-disable-line

	useEffect(() => {
		fetchSharedEventList();

		if (editEventId) {
			fetchEventInfo();
		} else {
			resetState();
		}
	}, [props.organization, editEventId]); //eslint-disable-line

	const resetState = () => {
		setSharedEvent("");
		setExtraPrice(0);
		setExtraEsnCardPrice(0);
		setExtraDiscountPrice(0);
	};

	const fetchEventInfo = () => {
		axios
			.get(`${config.API_ENDPOINT}/events/shared/${props.organization}/${editEventId}`, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(res => {
				let event = res.data;
				setSharedEvent(event.parentEvent);
				setExtraPrice(event.extraPrice);
				setExtraEsnCardPrice(event.extraEsnCardPrice);
				setExtraDiscountPrice(event.extraDiscountPrice);
			})
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}

	const fetchSharedEventList = () => {
		axios
			.get(`${config.API_ENDPOINT}/events/shared/all`, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(res => setEventList(res.data))
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}

	const submit = useDebouncedCallback(() => {
		if (sharedEvent === "") {
			toast.warning("You must choose a shared event to join");
			return;
		}

		let submission = {};
		let url = "";

		if (editEventId) {
			submission = {
				extraPrice,
				extraEsnCardPrice,
				extraDiscountPrice,
			};

			url = `${config.API_ENDPOINT}/events/shared/${props.organization}/${editEventId}`;
		} else {
			submission = {
				organization: props.organization,
				parentEvent: sharedEvent._id,
				extraPrice,
				extraEsnCardPrice,
				extraDiscountPrice,
			};

			url = `${config.API_ENDPOINT}/events/shared/create`;
		}

		axios
			.post(url, submission, { headers: { "x-access-token": localStorage.getItem("access-token") } })
			.then(() => {
				toast.success("Success!");
				if (editEventId == null) {
					resetState();
				}
			})
			.catch(err => {
				switch (err.response?.status) {
					case 401:
						localStorage.removeItem("access-token");
						window.location.replace("/login");
						break;
					case 409:
						toast.warning("Your organization has already joined this shared event.");
						break;
					default:
						toast.error("An unexpected error has occurred");
						break;
				}
			});
	}, 500, { leading: true });

	const copyIframe = async () => {
		let text = `<iframe frameborder="0" scrolling="yes" src="${config.WEB_ENDPOINT}/iframes/event-details/shared/${props.organization}/${editEventId}" style=" min-height: 700px; overflow-y: scroll; width: 100%; "></iframe>`;
		await navigator.clipboard.writeText(text);
		toast.success("Copied!");
	}

	const formUI = () => {
		return (
			<Grid item xs={12} md={6}>
				{editEventId ? null : (
					<FormControl
						style={{ marginBottom: 20 }}
						fullWidth
						variant="outlined"
					>
						<InputLabel style={{ backgroundColor: "#fff" }}>
							Shared Event
						</InputLabel>
						<Select
							key={eventList}
							value={sharedEvent}
							onChange={(e) => {
								setSharedEvent(e.target.value);
								setExtraPrice(0);
								setExtraEsnCardPrice(0);
								setExtraDiscountPrice(0);
							}}
						>
							{eventList.map((event) => (
								<MenuItem key={event._id} value={event}>{event.name}</MenuItem>
							))}
						</Select>
					</FormControl>
				)}

				{sharedEvent !== "" ? (
					<>
						<TextField
							variant="outlined"
							fullWidth
							label="Extra Price"
							name="extraPrice"
							type="number"
							value={extraPrice}
							required
							onChange={(e) => setExtraPrice(e.target.value !== "" ? parseFloat(e.target.value) : null)}
							inputProps={{ step: "0.01" }}
							style={{ marginBottom: 20 }}
						/>
						{sharedEvent.esnCardRequired ? null : (
							<TextField
								variant="outlined"
								fullWidth
								label="Extra Price with ESNcard"
								name="extraEsnCardPrice"
								type="number"
								value={extraEsnCardPrice}
								required
								onChange={(e) => setExtraEsnCardPrice(e.target.value !== "" ? parseFloat(e.target.value) : null)}
								inputProps={{ step: "0.01" }}
								style={{ marginBottom: 20 }}
							/>
						)}
						{sharedEvent.esnCardBuyPrice ? (
							<TextField
								variant="outlined"
								fullWidth
								label="Extra Price with Discount"
								name="extraDiscountPrice"
								type="number"
								value={extraDiscountPrice}
								required
								onChange={(e) => setExtraDiscountPrice(e.target.value !== "" ? parseFloat(e.target.value) : null)}
								inputProps={{ step: "0.01" }}
								style={{ marginBottom: 20 }}
							/>
						) : null}
					</>
				) : null}
			</Grid>
		);
	}

	const previewUI = () => {
		return (
			<Grid item xs={12} md={6}>
				{editEventId ? (
					<div>
						<Button
							onClick={copyIframe}
							style={{ marginBottom: 5, float: "right" }}
						>
							Copy iframe
						</Button>
						<Button
							onClick={() => {
								window.open(
									`${config.WEB_ENDPOINT}/iframes/event-details/shared/${props.organization}/${editEventId}/internal`,
									"_blank"
								);
							}}
							style={{ marginBottom: 20, float: "right" }}
						>
							Open Internal Sale
						</Button>
					</div>
				) : null}
				<Typography>
					<strong>Preview</strong>
				</Typography>
				{sharedEvent !== "" ? (
					<>
						<Typography
							style={{ marginTop: 40, textAlign: "center", fontSize: 35 }}
						>
							{sharedEvent.name}
						</Typography>
						<Typography style={{ marginTop: 30 }}>
							<strong>
								{sharedEvent.datetimeEnd ? "From: " : "Date: "}
							</strong>
							{sharedEvent.datetime
								? moment(sharedEvent.datetime).format(
									"dddd, DD/MM/YYYY HH:mm"
								)
								: ""}
						</Typography>
						{sharedEvent.datetimeEnd ? (
							<Typography style={{ marginTop: 20 }}>
								<strong>To: </strong>
								{moment(sharedEvent.datetimeEnd).format(
									"dddd, DD/MM/YYYY HH:mm"
								)}
							</Typography>
						) : null}
						<Typography style={{ marginTop: 20 }}>
							<strong>Location: </strong>
							{sharedEvent.location}
						</Typography>
						{parse(sharedEvent.description)}
						<Typography
							sx={{ mt: 3, textAlign: "end", fontSize: 30 }}
						>
							{(
								sharedEvent.regularPrice +
								(extraPrice ?? 0)
							).toFixed(2) + "€"}
						</Typography>
						<br />
						<Typography sx={{ mb: 1, textAlign: "end" }}>
							{!sharedEvent.esnCardRequired &&
								sharedEvent.esnCardPrice != null
								? (
									sharedEvent.esnCardPrice +
									(extraEsnCardPrice ?? 0)
								).toFixed(2) + "€ with ESNcard"
								: "Only for students with ESNcard"}
						</Typography>
						{sharedEvent.esnCardBuyPrice ? (
							<Typography sx={{ mb: 1, textAlign: "end" }}>
								{(
									sharedEvent.esnCardBuyPrice +
									(extraDiscountPrice ?? 0)
								).toFixed(2) + "€ with discount"}
							</Typography>
						) : null}
						<Card sx={{ mb: 1 }}>
							<CardContent>
								<Typography>
									<strong>Custom Ticket Email Section:</strong>
								</Typography>
								{parse(sharedEvent.customEmailInfo)}
							</CardContent>
						</Card>
						<Card>
							<CardContent>
								<Typography>
									<strong>Custom Purchase Request Email Section:</strong>
								</Typography>
								{parse(sharedEvent.customPurchaseEmailInfo)}
							</CardContent>
						</Card>
					</>
				) : null}
				<Button
					style={{ marginTop: 50, color: "white" }}
					variant="contained"
					color="primary"
					onClick={submit}
				>
					{editEventId ? "UPDATE" : "SUBMIT"}
				</Button>
			</Grid>
		);
	}

	if (props.user.accessLevel > 3) return null;

	return (
		<Card style={{ padding: 15 }}>
			<CardContent>
				<Grid container spacing={10}>
					{formUI()}
					{previewUI()}
				</Grid>
			</CardContent>
		</Card>
	);
}

export default AddSharedEvent;
